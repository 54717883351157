const scansReducer = (state = {}, action) => {
    switch(action.type) {
        case 'scans/update':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default scansReducer;