import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import API from '../../api';

import AddIconImage from '../../shared/svg/plus.svg';
import CompletedIconImage from '../../shared/svg/check.svg';

import ImageCard from '../../components/ImageCard';

import './index.scss';
import ListPage from '../../layouts/ListPage';

function RecipesMenu() {
  const retailer = useSelector(state => state.retailer);

  const profileCompletionChecks = [
      {
        title: 'Recipe Library',
        description: `You have <strong>${retailer?.recipes?.filter(recipe => recipe.recipe_id).length || 0}</strong> recipes in your collection.`,
        completed: !!retailer?.recipes?.filter(recipe => recipe.recipe_id).length,
        uri: '/recipe-book'
      },
      {
        title: 'Cooking Methods',
        description: `You have <strong>${retailer?.cooking_methods?.length || 0}</strong> cooking methods in your collection.`,
        completed: !!retailer?.cooking_methods?.length,
        uri: '/cooking-methods'
      },
      {
        title: 'Beef Cuts',
        description: `You have <strong>${retailer?.beef_products?.filter(beefProduct => beefProduct.beef_product_id).length || 0}</strong> beef cuts in your collection.`,
        completed: !!retailer?.beef_products?.filter(beefProduct => beefProduct.beef_product_id).length,
        uri: '/beef-cuts'
      },
  ];

  return (
    <ListPage
      title={'Recipe Management'}
      description={'With the Gateway, shopping for cuts of beef has never been easier. Simply look for the QR code on packages of beef, scan the code, select the cut of beef and enjoy. You’ll find recipes, beef cut information, nutrition and more. Click here to learn more about this program.'}
      breadcrumbsLinks={[
        {
          url: '/',
          content: 'Content Library',
        },
      ]}
    >
      <Box className="check-icon" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {profileCompletionChecks.map((check) => (
            <Grid
              key={check.title}
              item
              xs={6}
              md={3}
              lg={2}
            >
              <Link to={check.uri}>
                <ImageCard
                  item={{
                    title: check.title,
                    description: check.description,
                    url: check.completed ? CompletedIconImage : AddIconImage
                  }}
                  icon={true}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default RecipesMenu;