import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';

import useSWR from 'swr';
import { videoToolkitFetcher } from '../../common/utils/fetcher';
import Videos from '../../components/shared/Videos';
import ImageContainer from '../../components/shared/ImageContainer';

function SmartVideoRenderView() {
  useRefreshToken();
  const params = useParams();
  const retailer = useSelector(state => state.retailer);

  const id = params.id;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const { data, isLoading } = useSWR({
    url: `/Batch_Renders/${id}`,
    fields: '*,render_brand.logo,render_brand.name,render_project.*',
  }, videoToolkitFetcher);

  let sidebarFields = [];
  let fields = [];

  if (data) {
    fields = [
      {
        key: 'Name',
        value: data.project,
      },
      {
        key: 'Status',
        value: data.status,
      },
      ...(
        data.status === 'completed' ? [
        {
          key: 'Video',
          value: data.renderURL,
          type: 'video',
          children: (
            <Videos
              videos={[
                {
                  id: data.id,
                  sourceUrl: data.renderURL,
                  videoType: 'aws',
                }
              ]}
            />
          )
        },
        ] :
        []
      ),
      {
        key: 'Original Video',
        value: data?.render_project?.name,
        type: 'video',
        children: (
          <Videos
            videos={[
              {
                id: data.render_project.id,
                sourceUrl: `${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${data.render_project.base_video}`,
                videoType: 'aws',
              }
            ]}
          />
        )
      },
    ];

    sidebarFields = [
      {
        key: 'Brand',
        children: (
          <ImageContainer
            url={`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${data?.render_brand?.logo}`}
            alt={data.name}
            sx={{
              height: '20rem',
            }}
          />
        )
      },
    ];
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        breadcrumbsLinks={[
          {
            url: '/smart-videos',
            content: 'Smart Videos',
          },
          {
            url: `/smart-videos/renders`,
            content: 'Smart Video Renders',
          }
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default SmartVideoRenderView