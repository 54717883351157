import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateUser } from "../../actions/User";

import API from "../../api";

export const useRefreshToken = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const refreshToken = async () => {
            try {
                // make request
                // dispatch action to update token
                const authResponse = await API.refreshToken();
                dispatch(updateUser(authResponse));
            } catch (error) {
                // do nothing
                // try to correct error in next attempt
            }
        }

        refreshToken();
    }, []);
}