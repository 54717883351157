import { Fragment, useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// utils
import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import { chooseInputComponent } from '../../common/utils/components';
import { getTranslation } from '../../common/utils/translate';
import constants from '../../common/utils/constants';
import { buildTranslationPayload, buildRelationshipPayload } from '../../common/utils/payloads';

import api from '../../api';

// components
// TODO lazy load these
import notify from '../../components/Notification/helper';
import Nutrition from '../../components/Nutrition';
import Loader from '../../components/Loader';
import {FormControl, MenuItem, Select, TextField} from "@mui/material";
import store from "../../app/store";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Input from "@mui/material/Input";
import InputUpload from "../../components/InputUpload";
import API from "../../api";
import {updateRetailer} from "../../actions/Retailer";

function Retailers() {
    // TODO rename this to userRefreshAccessToken
    useRefreshToken();
    const dispatch = useDispatch();
    const [retailerInfo, setRetailer] = useState(null);
    const retailer = useSelector(state => state.retailer);
    const user = useSelector(state => state.user);
    const languages = [{"code":"de-DE"},{"code":"en-US"},{"code":"es-ES"},{"code":"fr-FR"},{"code":"it-IT"},{"code":"pt-BR"},{"code":"ru-RU"}];
    const [retailerPayload, setRetailerPayload] = useState({ translations: retailer.translations, image: '' });
    const [loading, setLoading] = useState(false);

    const getToken = () => {
        return store.getState().user.access_token;
    }
    const  token = getToken();

    const getRefreshToken = () => {
        return store.getState().user.refresh_token;
    }

    const fontOptions = [
        'Oswald',
        'Roboto',
        'Montserrat',
        'Outfit',
        'Quicksand',
        'Merriweather',
    ];

    const statusOptions = [
        'published',
        'draft',
        'archived'
    ];

    const colorOptions = [];

    useEffect(() => {
        fetch('https://staging.beef.tanglemedia.net/items/retailer/e3ed1f25-15ef-46ea-9144-d6ea04917a45',{ headers: {"Authorization" : `Bearer ${token}`} })
            .then(response => {
                return response.json()
            })
            .then(data => {
                setRetailer(data.data)
                console.log('retailerInfo',data)
                console.log('bodyFont',data.data.body_font)

            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);


    const formSections = [
        {
            title: '',
            fields: [
                {
                    label: 'Label',
                    name: 'retailer_name',
                    type: 'translation',
                    options: [],
                }
            ],
        },
        {
            fields: [
                {
                    label: 'Primary Brand Color',
                    name: 'brand_color',
                    type: 'color',
                    options: colorOptions,
                },
                {
                    label: 'Secondary Brand Color ',
                    name: 'brand_color_2',
                    type: 'color',
                    options: colorOptions,
                },{
                    label: 'Alternate Brand Color ',
                    name: 'brand_color_2',
                    type: 'color',
                    options: colorOptions,
                },
            ]
        },
        {
            title: 'Visual Settings',
            fields: [
                {
                    label: 'Logo',
                    name: 'image',
                    type: 'translation',
                    options: [],
                },
            ]
        },
        {
            fields: [
                {
                    label: 'Primary Text Color',
                    name: 'primary_text_color',
                    type: 'color',
                    options: colorOptions,
                },
                {
                    label: 'Icon Color',
                    name: 'icon_color',
                    type: 'color',
                    options: colorOptions,
                },
                {
                    label:'Background Color',
                    name: 'background_color',
                    type: 'color',
                    options: colorOptions,
                },

            ]
        },
        {
            fields: [
                {
                    label: 'Header Font',
                    name: 'subdomain',
                    type: 'url',
                    options: fontOptions,
                },
                {
                    label: 'Body Font',
                    name: 'subdomain',
                    type: 'url',
                    options: fontOptions,
                },
                {
                    label: 'Button Font',
                    name: 'website_url',
                    type: 'url',
                    options: fontOptions,
                }
            ]
        },
        {
            title: 'SEO Text',
            fields: [
                {
                    label: 'Title',
                    name: 'title',
                    type: 'translation',
                    options: [],
                },
                {
                    label: 'Description',
                    name: 'description',
                    type: 'translation',
                    options: [],
                },
                {
                    label: 'Keywords',
                    name: 'description',
                    type: 'translation',
                    options: [],
                }
            ]
        },
    ]

    const makeUpdateRetailerRequest = async () => {
        try {
            setLoading(true);

            const updateRetailerResponse = await API.updateRetailer(retailerPayload);
            notify('success', 'Retailer updated');

            dispatch(updateRetailer(updateRetailerResponse));
        } catch (error) {
            console.log('error: ', error);
            notify('error', 'Something went wrong');
        } finally {
            setLoading(false);
        }
    }

    // TODO consider making this a util function
    const handleInputChange = (value, field, languageCode) => {
        let keyValuePair = {};
        keyValuePair[field] = value;

        if (languageCode) {
            keyValuePair.languages_code = languageCode;

            // locate translation object if exists
            const translationObjects = retailerPayload.translations.filter(translation => translation.languages_code === languageCode);

            if (translationObjects.length) {
                retailerPayload.translations = retailerPayload.translations.map(translation => {
                    if (translation.languages_code === languageCode) {
                        translation = {
                            ...translation,
                            ...keyValuePair
                        }
                    }

                    return translation;
                });

                keyValuePair = {};
            } else {
                keyValuePair = {
                    translations: [
                        ...retailerPayload.translations,
                        keyValuePair
                    ]
                };
            }
        }

        setRetailerPayload({
            ...retailerPayload,
            ...keyValuePair
        })
    };

    const getFieldValue = (field, languageCode) => {
        if (languageCode) {
            let translatedFieldValue = '';

            const translations = retailerPayload.translations.filter(translation => translation.languages_code === languageCode);

            translatedFieldValue = translations.length ?
                (translations[0][field] || '') :
                translatedFieldValue;

            return translatedFieldValue;
        } else {
            return retailerPayload[field] || retailer[field];
        }
    };

    // TODO figure out a smarter way of handling this
    if (loading) {
        return <Loader />;
    }

    return (
        <Box sx={{ flexGrow: 1, my: 3 }}>
            <Typography className='form-title' variant="h2" style={{color:"red"}}>{'Brand Settings'}</Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    {formSections.map((section) => (
                        <Box key={section.title}>
                            {
                                section.title &&
                                <Typography
                                    className='section-title'
                                    sx={{
                                        mt: 5,
                                        mb: 1
                                    }}
                                    variant="h2"
                                >
                                    {section.title}
                                </Typography>
                            }

                            <Grid container spacing={2}>
                                {section.fields.map(field => (
                                    <Fragment key={field.name}>
                                        {
                                            chooseInputComponent(field)
                                        }
                                        {/* <Grid key={field.name} item xs={['color', 'font', 'dropdown'].includes(field?.type) ? 4 : 12}>
                                            <Stack>
                                                <Typography className="form-label" htmlFor="input-login-email" variant='label'>{field.label}</Typography>
                                                {['translation'].includes(field?.type) &&
                                                    <Accordion>
                                                        <AccordionSummary
                                                            sx={{ my: 0, '& .MuiAccordionSummary-content': { my: 0 }, '& .MuiAccordionSummary-content.Mui-expanded': { my: 0 } }}
                                                            className='form-input'
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            {getFieldValue(field.name)} &nbsp;
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {languages.map(language => (
                                                                <Stack key={language.code}>
                                                                    <Typography className="form-label" htmlFor={`input-my-brand-${field.name}-${language.code}`} variant='label'>{language.code}</Typography>
                                                                    <Input
                                                                        id={`input-my-brand-${field.name}-${language.code}`}
                                                                        inputProps={{
                                                                            className: 'form-input'
                                                                        }}
                                                                        sx={{
                                                                            color: '#262626 !important',
                                                                            py: 0,
                                                                            width: '100%'
                                                                        }}
                                                                        value={getFieldValue(field.name, language.code)}
                                                                        onChange={(event) => { handleInputChange(event.target.value, field.name, language.code) }}
                                                                        placeholder=""
                                                                        variant="standard"
                                                                    />
                                                                </Stack>
                                                            ))}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                }

                                                {!['translation', 'image'].includes(field?.type) && (
                                                    field?.options?.length ?
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            inputProps={{
                                                                className: 'form-input'
                                                            }}
                                                            sx={{ borderRadius: 0, '& .MuiSelect-select': { borderRadius: 0 } }}
                                                            value={getFieldValue(field.name)}
                                                            onChange={(event) => { handleInputChange(event.target.value, field.name) }}
                                                        >
                                                            {field.options.map(option => (
                                                                <MenuItem key={option} value={option} sx={{ textTransform: 'uppercase' }}>{option}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        :
                                                        <Input
                                                            id={`input-my-brand-${field.name}`}
                                                            inputProps={{
                                                                className: 'form-input'
                                                            }}
                                                            sx={{
                                                                color: '#262626 !important',
                                                                py: 0,
                                                                width: '100%'
                                                            }}
                                                            value={getFieldValue(field.name)}
                                                            onChange={(event) => { handleInputChange(event.target.value, field.name) }}
                                                            placeholder=""
                                                            variant="standard"
                                                        />
                                                )
                                                }

                                                {!['translation'].includes(field?.type) && field.type === 'image' &&
                                                    <InputUpload
                                                        field={field.name}
                                                        uploadHandler={(field, uploadedFileId) => { handleInputChange(uploadedFileId, field) }}
                                                    />
                                                }
                                            </Stack>
                                        </Grid> */}
                                    </Fragment>
                                ))}
                            </Grid>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={7} >
                    <Button
                        disabled={loading}
                        className='form-button'
                        sx={{ width: '40%', my: 5 }}
                        size="large"
                        style={{backgroundColor:"red"}}
                        variant="contained"
                        onClick={makeUpdateRetailerRequest}
                    >Edit My Brand</Button>
                </Grid>
            </Grid>
        </Box>

    )
}

export default Retailers