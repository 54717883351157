import React, { useEffect, useState } from "react";
import { FilterAlt, LocalDining } from "@mui/icons-material";
import useSWR from "swr";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import SelectProductInput from "../BarcodeFilter/SelectProductInput";

const Filter = ({
  filterOnClick,
  handleFilterClear,
  selectedProducts,
  setSelectedProducts,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [products, setProducts] = useState([]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: productsData } = useSWR({
    url: `beef_product`,
    fields: ["id", "translations.name"],
    limit: -1,
  });

  useEffect(() => {
    if (productsData) {
      setProducts([
        {
          id: 0,
          translations: [
            {
              name: "All",
            },
          ],
        },
        {
          id: 1,
          translations: [
            {
              name: "None",
            },
          ],
        },
        ...productsData,
      ]);
    }
  }, [productsData]);

  return (
    <>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          color={selectedProducts?.length > 0 ? "primary" : "default"}
          aria-label="filter"
          size="large"
          aria-controls={open ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <FilterAlt fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* Beef Product */}
        <Box sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing="4px"
            sx={{ mb: "4px" }}
          >
            <ListItemIcon sx={{ minWidth: "auto" }}>
              <LocalDining fontSize="small" />
            </ListItemIcon>
            <Typography
              sx={{
                fontWeight: 300,
                color: "#090909",
                fontSize: "0.8rem",
              }}
            >
              BEEF PRODUCT
            </Typography>
          </Stack>

          <SelectProductInput
            products={products}
            setSelectedProducts={setSelectedProducts}
            selectedProducts={selectedProducts}
          />
        </Box>
        <Divider />

        {/* Clear & Filter Buttons */}
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing="4px"
          sx={{ mb: "4px", px: 2, pt: "4px" }}
        >
          <Button
            sx={{
              borderRadius: 0,
              backgroundColor: "#C31E39",
              fontFamily: "Oswald",
              height: "48px",
              textTransform: "uppercase",
              ":hover": {
                bgcolor: "#C31E39",
              },
            }}
            variant="contained"
            onClick={() => {
              handleFilterClear();
              handleClose();
            }}
          >
            Clear
          </Button>
          <Button
            sx={{
              borderRadius: 0,
              backgroundColor: "rgb(191, 215, 48)",
              fontFamily: "Oswald",
              height: "48px",
              textTransform: "uppercase",
              ":hover": {
                bgcolor: "rgb(191, 215, 48)",
              },
            }}
            variant="contained"
            onClick={() => {
              filterOnClick();
              handleClose();
            }}
          >
            Filter
          </Button>
        </Stack>
      </Menu>
    </>
  );
};

export default Filter;
