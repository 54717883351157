import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Stack, Typography } from '@mui/material';
import ImageListItem from '@mui/material/ImageListItem';

import BackgroundImage from '../../shared/image/placeholder.jpg';

import './index.scss';
import Wysiwyg from '../shared/Wysiwyg';
import { Link } from 'react-router-dom';

function ImageCard({ item={}, url, topHeading = null, footerAction = null }) {

  const retailer = useSelector(state => state.retailer);
  
  const placeholderImage = retailer.placeholder_image ?
  `https://staging.beef.tanglemedia.net/assets/${retailer.placeholder_image}`
  : BackgroundImage;
  
  const imageTransformationKey = item?.url?.includes('key') ? '' : '?key=brand-portal-image-card-square';

  const [imageSource, setImageSource] = useState(`${item.url || placeholderImage}${imageTransformationKey}`);

  const CardContainer = ({ children }) => (
    url ? (
      <Link to={url}>
        {children}
      </Link>
    ) : <>{children}</>
  )

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
        position: 'relative',
        pb: footerAction ? '56px' : 'inherit',
      }}
    >
      <CardContainer>
        <ImageListItem key={item.url}
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          {topHeading &&
            <Stack sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 300, color: '#090909', fontSize: '16px', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden' }}>{topHeading}</Typography>
            </Stack>
          }

          <Box
            sx={{
              position: 'relative',
              width: '100%',
              pb: '100%',
              background: '#E9EEF1'
            }}
          >
            <img
              className='card-image'
              src={imageSource}
              srcSet={imageSource}
              alt={item.title}
              loading="lazy"
              onError={(event) => { setImageSource(placeholderImage) }}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          </Box>

          {!!item.title && 
            <Box sx={{ my: 1 }}>
              <Typography sx={{
                fontWeight: 'bold',
                color: '#090909',
                fontSize: '0.9rem',
                textTransform: 'capitalize',
              }}>
                <Wysiwyg
                  truncateAfterLine={2}
                >
                  {item.title}
                </Wysiwyg>
              </Typography>
            </Box>
          }

          {!!item.description && 
            <Box sx={{ my: 0 }}>
              <Typography sx={{
                fontWeight: 300,
                color: '#090909',
                fontSize: '0.8rem'
              }}>
                <Wysiwyg
                  truncateAfterLine={2}
                >
                  {item.description}
                </Wysiwyg>
              </Typography>
            </Box>
          }
        </ImageListItem>
      </CardContainer>

      {
        footerAction &&
          <Box
            sx={{
              position: 'absolute',
              bottom: '16px',
              left: '16px',
              right: '16px',
            }}
          >
            {footerAction}
          </Box>
      }
    </Box>
  );
}

export default ImageCard