import { Fragment, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import API from '../../api';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import { chooseInputComponent } from '../../common/utils/components';
import api from '../../api';
import { videoToolkitFetcher } from '../../common/utils/fetcher';
import { Container } from '@mui/material';
import Wizard from '../../components/shared/Wizard';

function SmartVideosBatchCreate() {
  useRefreshToken();
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;

  let projectsOnCurrentBatch = [];
  let brandsOnCurrentBatch = [];
  let batchName = '';

  const { data, isLoading } = useSWR({
    url: id ? `/queue/${id}` : null,
    fields: '*,project.project_ID,brand.brand_id',
  }, videoToolkitFetcher);

  if (data) {
    batchName = data.name;

    projectsOnCurrentBatch = data?.project?.map(item => ({
      id: item.project_ID,
      url: `project/${item.project_ID}`,
      responseHandler: response => ({
        url: `project/${response.ID}`,
        title: response.name,
      }),
    })) || [];

    brandsOnCurrentBatch = data?.brand?.map(item => ({
      id: item.brand_id,
      url: `brand/${item.brand_id}`,
      responseHandler: response => ({
        url: `brand/${response.id}`,
        title: response.name,
      }),
    })) || [];
  }

  const retailer = useSelector((state) => state.retailer);

  const [loading, setLoading] = useState(false);
  const [projectsToRun, setProjectsToRun] = useState([]);
  const [brandsToRun, setBrandsToRun] = useState([]);
  const [name, setName] = useState('');

  const handleCloseProjectsDialog = (selectedRowIds = []) => {
    const items = selectedRowIds.map(id => ({
      id,
      url: `project/${id}`,
      responseHandler: response => ({
        url: `project/${response.ID}`,
        title: response.name,
      }),
    }));

    setProjectsToRun(items);
  };

  const handleCloseBrandsDialog = (selectedRowIds = []) => {
    const items = selectedRowIds.map(id => ({
      id,
      url: `brand/${id}`,
      responseHandler: response => ({
        url: `brand/${response.id}`,
        title: response.name,
      }),
    }));

    setBrandsToRun(items);
  };

  const projectsPayload = {
    sort: '-created',
    meta: '*',
  };

  const brandsPayload = {
    sort: '-created',
    meta: '*',
  };

  const projectColumns = [
    {
      key: 'ID',
      label: 'ID',
      align: 'left',
    },
    {
      key: 'name',
      label: 'Name',
      align: 'left',
    },
  ];

  const brandColumns = [
    {
      key: 'id',
      label: 'ID',
      align: 'left',
    },
    {
      key: 'name',
      label: 'Name',
      align: 'left',
    },
  ];

  const formSections = [
    {
      title: 'Details',
      fields: [
        {
          label: 'name',
          description: 'A name to identify this batch',
          name: 'name',
          type: 'text',
          value: name || batchName,
          handleInputChange: (field, name) => {
            console.log('field', field);
            console.log('name', name);
            setName(name);
          }
        },
      ]
    },
    {
      title: 'Projects',
      fields: [
        {
          label: 'Projects',
          description: 'Projects to run in this batch',
          name: 'project',
          type: 'm2m',
          value: projectsToRun,
          handleInputChange: handleCloseProjectsDialog,
          config: {
            items: projectsToRun?.length ? projectsToRun : projectsOnCurrentBatch,
            title: 'Select Projects',
            url: 'project',
            payload: projectsPayload,
            columns: projectColumns,
            idField: 'ID',
          }
        },
      ]
    },
    {
      title: 'Brands',
      fields: [
        {
          label: 'Brands',
          description: 'Brands to run in this batch',
          name: 'brand',
          type: 'm2m',
          value: brandsToRun,
          handleInputChange: handleCloseBrandsDialog,
          config: {
            items: brandsToRun?.length ? brandsToRun : brandsOnCurrentBatch,
            title: 'Select Brands',
            url: 'brand',
            payload: brandsPayload,
            columns: brandColumns,
            idField: 'id',
          }
        },
      ]
    },
  ]

  const createBatch = async (status = 'queued') => {
    // TODO validate the form
    // build the payload
    const selectedProjectsToRun = projectsToRun?.length ? projectsToRun : projectsOnCurrentBatch;
    const selectedBrandsToRun = brandsToRun?.length ? brandsToRun : brandsOnCurrentBatch;

    const payload = {
      status,
      name: name || batchName,
      project: {
        create: selectedProjectsToRun.map(project => ({
          queue_id: '+',
          project_ID: { ID: project.id },
        })),
        update: [],
        delete: [],
      },
      brand: {
        create: selectedBrandsToRun.map(brand => ({
          queue_id: '+',
          brand_id: { id: brand.id },
        })),
        update: [],
        delete: [],
      },
    };

    try {
      await api.postToVideoToolkit('queue', payload);
      notify('success', 'Running Batch');
      navigate('/smart-videos/batches');
    } catch (error) {
      error?.map((err) => notify('error', err.message));
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ViewPage
      title={`Create Smart Videos Batch`}
      breadcrumbsLinks={[
        {
          url: '/smart-videos',
          content: 'Smart Videos'
        },
        {
          url: '/smart-videos/batches',
          content: 'Smart Video Batches'
        }
      ]}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Wizard
          pages={formSections}
          finalStageAction={(
            <>
              <Button
                disabled={loading}
                size="large"
                onClick={() => createBatch('queued')}
              >
                Run Batch
              </Button>

              <Button
                disabled={loading}
                size="large"
                onClick={() => createBatch('draft')}
              >
                Save Draft
              </Button>
            </>
          )}
        />
      </Box>
    </ViewPage>
  )
}

export default SmartVideosBatchCreate;