export const updateUser = (payload) => {
    return {
        type: 'user/update',
        payload
    }
};

export const logoutUser = () => {
    return {
        type: 'user/logout'
    }
};