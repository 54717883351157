const retailerReducer = (state = {}, action) => {
    switch(action.type) {
        case 'retailer/update':
            return {
                ...state,
                ...action.payload
            };

        case 'retailer/reset':
            return {
                ...state,
                ...{
                    body_font: "Oswald",
                    brand_color: "#C31E39",
                    brand_color_2: "#AAAAAA",
                    button_background_color: "#C31E39",
                    button_font: "Oswald",
                    description: "Canada: The world’s perfect place for raising beef. Join the Conversation, exciting things are happening at Canada Beef",
                    header_font: "Oswald",
                    icon_color: "#ffffff",
                    id: null,
                    ip_address: "XXX.XXX.XXX.XXX",
                    favicon: {
                        id: "71b390d6-2ff7-45b7-b011-6936b51a9e71"
                    },
                    logo: "71b390d6-2ff7-45b7-b011-6936b51a9e71",
                    meta: "Canada Beef | Leading Supplier",
                    retailer_name: "Canada Beef",
                    scripts: [],
                    site_background_color: "#ffffff",
                    subdomain: "stagingcanadabeef.netlify.app",
                    website_url: "canadabeef.ca",
                    title: "We put the best of Canada in our Beef - Canadian Beef | Canada Beef",
                    translations: [],
                }
            };
        default:
            return state;
    } 
};

export default retailerReducer;