import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GridOn, TableChart } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import BarcodeView from "../BarcodeView";
import BarcodeFilter from "../BarcodeFilter";
import "./index.scss";

const BarcodeDiagnosticsReport = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);
  const retailerId = retailer?.id;
  const [checked, setChecked] = useState(false);
  const [view, setView] = useState("table");
  const [filterObject, setFilterObject] = useState(
    showFullData
      ? {}
      : {
          owner: {
            id: {
              _eq: retailerId,
            },
          },
        }
  );
  const [page, setPage] = useState(0);

  const handleFilterClear = () => {
    setPage(0);
    setFilterObject(
      showFullData
        ? {}
        : {
            owner: {
              id: {
                _eq: retailerId,
              },
            },
          }
    );
  };

  useEffect(() => {
    if (checked) {
      setFilterObject((filterObject) => ({
        ...filterObject,
        last_verified: {
          _nnull: true,
        },
      }));
    } else {
      setFilterObject((filterObject) => {
        delete filterObject?.last_verified;
        return {
          ...filterObject,
        };
      });
    }
  }, [checked]);

  useEffect(() => {
    if (!showFullData) {
      setFilterObject({
        ...filterObject,
        owner: {
          id: {
            _eq: retailerId,
          },
        },
      });
    } else {
      setFilterObject((filterObject) => {
        delete filterObject?.owner;
        return {
          ...filterObject,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFullData]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Barcode Diagnostics
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ my: 1 }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            Filter on whether or not the codes are verified, and which are most
            stale.
          </Typography>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing="2px"
            sx={{ my: 1 }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
              <Typography variant="p">Show verified codes only</Typography>
            </label>

            {/* Filter */}
            <BarcodeFilter
              handleFilterClear={handleFilterClear}
              setFilterObject={setFilterObject}
              setPage={setPage}
              showFullData={showFullData}
            />

            <Tooltip title={view === "table" ? "Card View" : "Table View"}>
              <IconButton
                onClick={() => {
                  setView(view === "table" ? "card" : "table");
                }}
                aria-label="view"
                size="large"
              >
                {view === "table" ? (
                  <GridOn fontSize="inherit" />
                ) : (
                  <TableChart fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>

      <BarcodeView
        view={view}
        setPage={setPage}
        page={page}
        filterObject={filterObject}
      />
    </Box>
  );
};

export default BarcodeDiagnosticsReport;
