import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useSWR from "swr";

import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import ListPage from '../../layouts/ListPage';
import LazyLoad from 'react-lazyload';

import { videoToolkitFetcher } from "../../common/utils/fetcher";
import { useCheckVideoToolsEnabled } from '../../common/hooks/useCheckVideoToolsEnabled';

const SmartVideoBrands = () => {
  const videoToolsEnabled = useCheckVideoToolsEnabled();
  const retailer = useSelector(state => state.retailer);

  const [batchesMeta, setBatchesMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useSWR({
    url: 'brand',
    sort: 'sort',
    ...(!videoToolsEnabled ? {
      filter: {
        _and: [
          {
            id: {
              _eq: retailer.smart_videos_brand,
            }
          }
        ]
      }
    } : {}),
  }, videoToolkitFetcher);

  let items = [];

  if (data) {
    items = data;
  }

  const summaryValues = [
    {
        label: 'Total',
        value: batchesMeta?.total_count,
    },
  ];

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async () => {
    try {
      const fetchBatchesResponse = await API.fetchFromVideoToolkit('brand', {
        sort: '-created',
      });
      setBatchesMeta(fetchBatchesResponse.meta);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ListPage
      title='Smart Video Brands'
      summaries={summaryValues}
      searchStorageKey={'smart-videos-brands'}
      searchOnly={['BRANDS']}
      breadcrumbsLinks={[
        {
          url: '/smart-videos',
          content: 'Smart Videos',
        }
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          {items?.map((item) => (
            <Grid
              key={item.id}
              item
              xs={12}
              md={3}
              lg={2}
            >
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <LazyLoad
                  height='100%'
                >
                  <ImageCard
                    url={`${item.id}`}
                    item={{
                      title: item.name,
                      url: item.logo ? `${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${item.logo}` : ''
                    }} 
                    footerAction={null}
                  />
                </LazyLoad>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default SmartVideoBrands