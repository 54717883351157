import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { arrayOf, number, shape, string } from 'prop-types';

import { Bar } from 'react-chartjs-2';

import styles from './index.module.scss';
import { Box, ImageListItem, Typography } from '@mui/material';
import Wysiwyg from '../Wysiwyg';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
        },
    },
};

function ChartContainer({ title, description, footerAction, labels = [], datasets = []}) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
            },
        },
    };

    const data = {
        labels,
        datasets,
    };

    return (
        <Box
            sx={{
                width: '100%',
                p: 2,
                boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
                position: 'relative',
                pb: footerAction ? '46px' : 'inherit',
            }}
        >
            <ImageListItem
                sx={{
                    width: '100%',
                }}
            >
                <Box
                    className={styles.chartContainer}
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        background: '#EFEEEE',
                    }}
                >
                    <Bar options={options} data={data} />
                </Box>

                {!!title && 
                    <Box sx={{ my: 1 }}>
                        <Typography sx={{
                            fontWeight: 'bold',
                            color: '#090909',
                            fontSize: '0.9rem',
                            textTransform: 'capitalize',
                        }}>
                            <Wysiwyg
                                truncateAfterLine={2}
                            >
                                {title}
                            </Wysiwyg>
                        </Typography>
                    </Box>
                }

                {!!description && 
                    <Box sx={{ my: 0 }}>
                        <Typography sx={{
                            fontWeight: 300,
                            color: '#090909',
                            fontSize: '0.8rem'
                        }}>
                            <Wysiwyg
                                truncateAfterLine={2}
                            >
                                {description}
                            </Wysiwyg>
                        </Typography>
                    </Box>
                }
            </ImageListItem>

            {
                footerAction &&
                <Box
                    sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '16px',
                    right: '16px',
                    }}
                >
                    {footerAction}
                </Box>
            }
        </Box>
    )
}

// ChartContainer.propTypes = {
//     title: string.isRequired(),
//     description: string,
//     labels: arrayOf(string),
//     labels: arrayOf(
//         shape({
//             label: string.isRequired(),
//             data: arrayOf(number).isRequired(),
//             backgroundColor: string.isRequired(),
//         })
//     ),
// }

export default ChartContainer