import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useSelector } from "react-redux";
import { EditOutlined } from "@mui/icons-material";
import { useParams, useSearchParams } from "react-router-dom";
import { Alert, Box, Container, Grid, Typography } from "@mui/material";

import API from "../../api";
import Loader from "../../components/Loader";
import ViewPage from "../../layouts/ViewPage";
import { getAccessToken } from "../../utils/user";
import LabelAndDisplay from "../../components/LabelAndDisplay";
import EditBarcode from "../../components/Barcodes/EditBarcode";
import ImageContainer from "../../components/shared/ImageContainer";
import "./index.scss";

const View = () => {
  const [searchParams] = useSearchParams();
  const retailer = useSelector((state) => state.retailer);
  const user = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [showForm, setShowForm] = useState(
    searchParams.get("edit") ? true : false
  );
  const [editFormLoading, setEditFormLoading] = useState(false);
  const [stores, setStores] = useState(null);
  const [products, setProducts] = useState(null);
  const [retailers, setRetailers] = useState(null);
  const [fields, setFields] = useState([]);
  const [sidebarFields, setSidebarFields] = useState([]);
  const [data, setData] = useState({});
  const retailerId = retailer?.id;
  const isAdminUser = API.isAdmin(user);
  const barcodeId = useParams().barcodeId;
  const [formData, setFormData] = useState({
    code: "",
    owner: "",
    beef_product_id: "",
    raw_description: "",
    retailer: "",
    product_image: null,
    data_source: "",
    price_data: "",
    label_text: "",
  });

  const { data: barcodeData, isLoading } = useSWR({
    url: `barcode/${barcodeId}`,
    fields:
      "*,beef_product_id.*.*,owner.*,owner.translations.*,product_image.*, user_created.*,barcode_image.*,scanned_at.store_name,scanned_at.id,last_verified_by.first_name,last_verified_by.last_name",
  });

  useEffect(() => {
    if (barcodeData) {
      setData(barcodeData);
      setTitle(barcodeData?.code);
      setFormData({
        code: barcodeData?.code,
        data_source: barcodeData?.data_source,
        price_data: barcodeData?.price_data,
        label_text: barcodeData?.label_text,
        raw_retailer: barcodeData?.raw_retailer,
        raw_description: barcodeData?.raw_description,
        product_image: barcodeData?.product_image
          ? `${process.env.REACT_APP_API_URL}/assets/${barcodeData?.product_image?.id}`
          : null,
        scanned_at: {
          value: barcodeData?.scanned_at?.id,
          label: barcodeData?.scanned_at?.store_name,
        },
        beef_product_id: {
          value: barcodeData?.beef_product_id?.id,
          label: barcodeData?.beef_product_id?.translations[0]?.name,
        },
        owner: {
          value: barcodeData?.owner?.id,
          label: barcodeData?.owner?.translations[0]?.retailer_name,
        },
      });
    }
  }, [barcodeData]);
  useEffect(() => {
    if (data) {
      setFields([
        {
          key: "PRODUCT",
          value: data?.beef_product_id?.translations[0]?.name,
        },
        {
          key: "RETAILER",
          value: data?.raw_retailer,
        },
        {
          key: "OWNER",
          value: data?.owner?.translations[0]?.title,
        },
        {
          key: "LAST VERIFIED",
          value: `${
            data?.last_verified
              ? `${new Date(data?.last_verified)?.toDateString()} - `
              : ""
          }${data?.last_verified_by?.first_name ?? ""} ${
            data?.last_verified_by?.last_name ?? ""
          }`,
        },
        {
          key: "NEEDS REVIEW",
          value: data?.needs_review ? "Yes" : "No",
        },
        {
          key: "PRICE",
          value: data?.price_data,
        },
        {
          key: "SCANNED AT",
          value: data?.scanned_at?.store_name,
        },
        {
          key: "LABEL TEXT",
          value: data?.label_text,
        },
        {
          key: "SOURCE",
          value: data?.data_source,
        },
        {
          key: "SCANNED",
          value: new Date(data?.date_created)?.toDateString(),
        },
        {
          key: "SCANNED BY",
          value: `${data?.user_created?.first_name} ${data?.user_created?.last_name}`,
        },
        {
          key: "DESCRIPTION",
          value: data?.raw_description,
        },
      ]);
      setSidebarFields([
        {
          key: "BARCODE IMAGE",
          children: data?.barcode_image?.filename_disk ? (
            <ImageContainer
              url={`${process.env.REACT_APP_API_URL}/assets/${
                data?.barcode_image?.filename_disk
              }?access_token=${getAccessToken()}&key=`}
              alt="Barcode"
              sx={{
                height: "20rem",
              }}
            />
          ) : (
            <Alert severity="info">Barcode image not available.</Alert>
          ),
        },
        {
          key: "PRODCUT IMAGE",
          children: data?.product_image?.filename_disk ? (
            <ImageContainer
              url={`${process.env.REACT_APP_API_URL}/assets/${
                data?.product_image?.filename_disk
              }?access_token=${getAccessToken()}&key=`}
              alt="Product"
              sx={{
                height: "20rem",
              }}
            />
          ) : (
            <Alert severity="info">Product image not available.</Alert>
          ),
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    const getProducts = async () => {
      setEditFormLoading(true);
      const listProduct = await API.getListProducts({}, [
        "id",
        "translations.name",
      ]);
      setProducts(listProduct?.data);
      setEditFormLoading(false);
    };
    const getRetailers = async () => {
      setEditFormLoading(true);
      const listRetailer = await API.getListRetailer();
      setRetailers(listRetailer);
      setEditFormLoading(false);
    };
    const getStores = async () => {
      setEditFormLoading(true);
      const listStores = await API.getStoresList();
      setStores(listStores);
      setEditFormLoading(false);
    };

    getProducts();
    getRetailers();
    getStores();
  }, []);

  return (
    <ViewPage
      title={title}
      breadcrumbsLinks={[
        {
          url: "/analytics",
          content: "Analytics",
        },
      ]}
    >
      <>
        {isLoading || !data ? (
          <Loader />
        ) : (
          <>
            {isAdminUser || data?.owner?.id === retailerId ? (
              <>
                <Container
                  fixed
                  disableGutters
                  sx={{
                    p: 0,
                    mb: 3,
                  }}
                >
                  {showForm ? (
                    <EditBarcode
                      setShowForm={setShowForm}
                      barcodeValue={barcodeData?.code}
                      setSavedData={setData}
                      formData={formData}
                      setFormData={setFormData}
                      savedData={data}
                      retailers={retailers}
                      products={products}
                      stores={stores}
                      editFormLoading={editFormLoading}
                    />
                  ) : (
                    <Box sx={{ flexGrow: 1, mb: 3 }}>
                      <Box
                        onClick={() => {
                          setShowForm(true);
                        }}
                        sx={{ float: "right", cursor: "pointer" }}
                      >
                        <EditOutlined sx={{ fontSize: 32 }} />
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={7} lg={8}>
                          <Box sx={{ flexGrow: 1, mb: 3 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {fields.map((field) => (
                                  <LabelAndDisplay
                                    key={field.key}
                                    label={field.key}
                                    value={field.value}
                                  >
                                    {field.children}
                                  </LabelAndDisplay>
                                ))}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={5} lg={4}>
                          <Box sx={{ flexGrow: 1, mb: 3 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {sidebarFields.map((field) => (
                                  <LabelAndDisplay
                                    direction="column"
                                    key={field.key}
                                    label={field.key}
                                    value={field.value}
                                  >
                                    {field.children}
                                  </LabelAndDisplay>
                                ))}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Container>
              </>
            ) : (
              <Box className="header-strip">
                <Typography className="title" variant="h1">
                  Access to this barcode is unauthorized.
                </Typography>
              </Box>
            )}
          </>
        )}
      </>
    </ViewPage>
  );
};

export default View;
