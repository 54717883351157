import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import API from '../api';

import InputUpload from '../components/InputUpload';

import { updateRetailer } from '../actions/Retailer';
import { useRefreshToken } from '../common/hooks/useRefreshToken';
import Loader from '../components/Loader';
import notify from '../components/Notification/helper';
import ViewPage from '../layouts/ViewPage';
import { chooseInputComponent } from '../common/utils/components';
import { getTranslation } from '../common/utils/translate';

function MyBrand() {
  const dispatch = useDispatch();
  const retailer = useSelector(state => state.retailer);
  const [englishTranslation, setEnglishTranslation] = useState(getTranslation(retailer, { key: 'languages_code', code: 'en-US' }));

  useRefreshToken();

  const [retailerPayload, setRetailerPayload] = useState(retailer);

  const [loading, setLoading] = useState(false);

  const handleInputChange = (field, value) => {
    let keyValuePair = {};
    keyValuePair[field] = value;

    setRetailerPayload({
      ...retailerPayload,
      ...keyValuePair
    })
  };

  const handleTranslationInputChange = (translation, field, value) => {
    let keyValuePair = {};
    keyValuePair[field] = value;

    setEnglishTranslation({
      ...translation,
      ...keyValuePair
    });
  };

  useEffect(() => {
    console.log('englishTranslation: ', englishTranslation);
  }, [englishTranslation])

  const fontOptions = [
    'Oswald',
    'Roboto',
    'Montserrat',
    'Outfit',
    'Quicksand',
    'Merriweather',
  ];

  const formSections = [
    {
      title: 'Brand Settings',
      fields: [
        {
          label: 'Name',
          name: 'retailer_name',
          description: 'The text to identify a brand.',
          type: 'translation',
          value: getTranslation(retailer, { key: 'languages_code', code: 'en-US' }).retailer_name,
          options: [],
          handleInputChange: (field, value) => {
            handleTranslationInputChange(englishTranslation, field, value);
          }
        },
        {
          label: 'Primary Brand Color',
          description: 'A bold color constrasting white',
          name: 'brand_color',
          type: 'colour',
          value: retailerPayload.brand_color,
          handleInputChange: handleInputChange,
        },
        {
          label: 'Secondary Brand Color',
          description: 'A secondary brand color constrasting white',
          name: 'brand_color_2',
          type: 'colour',
          value: retailerPayload.brand_color_2,
          handleInputChange: handleInputChange,
        },
      ]
    },
    {
      title: 'Visual Settings',
      fields: [
        {
          name: 'logo',
          label: 'Logo',
          description: 'THIN LEGIBLE FROM FONTS.GOOGLE.COM APPEAR IN THIS LIST. TO REQUEST ADDITIONAL FONTS, CONTACT US',
          type: 'image',
          value: retailerPayload.logo,
          handleInputChange: handleInputChange,
        },
        {
          label: 'Button Color',
          description: 'Color to be used as the button background color.',
          name: 'button_background_color',
          type: 'colour',
          value: retailerPayload.button_background_color,
          handleInputChange: handleInputChange,
        },
        {
          label: 'Icon Color',
          description: 'Color to be used for all icons.',
          name: 'icon_color',
          type: 'colour',
          value: retailerPayload.icon_color,
          handleInputChange: handleInputChange,
        },
        {
          label: 'Background Color',
          description: 'Color to be used as the gateway background.',
          name: 'site_background_color',
          type: 'colour',
          value: retailerPayload.site_background_color,
          handleInputChange: handleInputChange,
        },
        {
          label: 'Header Font',
          description: 'HEAVYWEIGHT HEADINGS FONTS FROM A PRE-APPROVED LIST. FONT PREVIEWS AT FONTS.GOOGLE.COM. TO USE YOUR BRAND FONT, CONTACT US.',
          name: 'header_font',
          type: 'font',
          value: retailerPayload.header_font,
          handleInputChange: handleInputChange,
          options: fontOptions,
        },
        {
          label: 'Body Font',
          description: 'PRE-APPROVED FONTS FROM FONTS.GOOGLE.COM APPEAR IN THIS LIST. TO REQUEST ADDITIONAL FONTS, CONTACT US',
          name: 'body_font',
          type: 'font',
          value: retailerPayload.body_font,
          handleInputChange: handleInputChange,
          options:  fontOptions,
        },
        {
          label: 'Button Font',
          description: 'THIN LEGIBLE FROM FONTS.GOOGLE.COM APPEAR IN THIS LIST. TO REQUEST ADDITIONAL FONTS, CONTACT US',
          name: 'button_font',
          type: 'font',
          value: retailerPayload.button_font,
          handleInputChange: handleInputChange,
          options:  fontOptions,
        },
      ]
    },
    {
      title: 'SEO Text',
      fields: [
        {
          label: 'Title Tag',
          name: 'title',
          description: 'ADD TEXT TO THE TITLE TAG.  THE TITLE WILL BEGIN WITH  “CANADIAN BEEF INFORMATION GATEWAY"',
          type: 'translation',
          value: getTranslation(retailer, { key: 'languages_code', code: 'en-US' }).title,
          options: [],
          handleInputChange: (field, value) => {
            handleTranslationInputChange(englishTranslation, field, value);
          }
        },
        {
          label: 'Description',
          name: 'description',
          description: 'ADD TEXT TO THE DESCRIPTION TAG.  THE TITLE WILL BEGIN WITH  “CANADIAN BEEF INFORMATION GATEWAY”',
          type: 'translation',
          value: getTranslation(retailer, { key: 'languages_code', code: 'en-US' }).description,
          options: [],
          handleInputChange: (field, value) => {
            handleTranslationInputChange(englishTranslation, field, value);
          }
        }
      ]
    },
  ]

  const makeUpdateRetailerRequest = async () => {
    try {
      setLoading(true);

      let payload = {
        ...retailerPayload,
      };

      if (englishTranslation?.id) {
        // build translation payload
        const translations = { create: [], update: [], delete: [] };
        translations.update.push(englishTranslation)

        // override retailerPayload.translations
        payload.translations = translations;
      }

      const updateRetailerResponse = await API.updateRetailer(payload);
      notify('success', 'Retailer updated');

      dispatch(updateRetailer(updateRetailerResponse));
    } catch (error) {
      console.log('error: ', error);
      notify('error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  const getFieldValue = (field, languageCode) => {
    if (languageCode) {
      let translatedFieldValue = '';

      const translations = retailerPayload.translations.filter(translation => translation.languages_code === languageCode);

      translatedFieldValue = translations.length ?
        (translations[0][field] || '') :
        translatedFieldValue;
      
      return translatedFieldValue;
    } else {
      return retailerPayload[field] || retailer[field];
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ViewPage
      title={`Branding`}
      breadcrumbsLinks={[
        {
          url: '/',
          content: 'Dashboard'
        },
        {
          url: '/',
          content: 'Branding & Settings'
        }
      ]}
    >
      <Box 
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={7}>
            {formSections.map((section) => (
              <Box key={section.title}>
                {
                  section.title &&
                  <Typography
                    className='form-section-title'
                    sx={{
                      mt: 5,
                      mb: 1,
                      color: retailer.brand_color || '#000000',
                    }}
                    variant="h2"
                  >
                    {section.title}
                  </Typography>
                }

                <Grid container spacing={2}>
                  {section.fields.map(field => (
                    <Fragment key={field.name}>
                      <Grid
                        key={field.name}
                        item
                        {
                          ...(['colour'].includes(field?.type) ? {
                              xs: 12,
                              md: 4,
                            } : {
                              xs: 12,
                            }
                          )
                        }
                      >
                        { chooseInputComponent(field) }
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} md={7} >
            <Button
              disabled={loading}
              className='form-button'
              sx={{ width: '40%', my: 5 }}
              size="large"
              variant="contained"
              onClick={makeUpdateRetailerRequest}
            >
              {loading ? 'Updating... ' : 'Update' }
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ViewPage>
  )
}

export default MyBrand