import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import './Logo.scss';

const Logo = () => {
  const retailer = useSelector(state => state.retailer);
  
  return (
    <Box
      className="logo_info"
      sx={{}}
    >
      <img
        src={`https://staging.beef.tanglemedia.net/assets/${retailer.logo}?key=system-medium-contain`}
        alt="img"
        className="logo_img"
        style={{zIndex: 1, position: "relative"}}
      />
    </Box>
  );
};

export default Logo;
