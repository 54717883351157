import { useState } from 'react';
import { useSelector } from 'react-redux';

import useSWR from 'swr';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';

import SideBar from '../../components/SideBar';
import Logo from '../../components/Logo';
import CanadaBeefLogo from '../../shared/svg/canada-beef-logo.svg';
import { IconButton, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import UserMenu from '../../components/shared/UserMenu';
import { useCheckRetailerSet } from '../../common/hooks/useCheckRetailerSet';

function Authenticated(props) {
    const retailerSet = useCheckRetailerSet();
    console.log('retailerSet', retailerSet);    

    const { window, children } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const user = useSelector(state => state.user);

    // check if user is logged in
    const { data: defaults } = useSWR({
        url: 'defaults',
    });

    let drawerWidth = 0;

    if (defaults) {
        drawerWidth = 336;
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box>
            <Box sx={{ width: '100%', position: 'relative' }}>
                <Box sx={{ position: 'absolute', left: 0, right: 0, height: '75%', background: 'rgba(65, 65, 65, 0.07)' }}></Box>
                <Stack
                    sx={{ py: 2, px: 2, background: 'transparent' }}
                    direction="row"
                    spacing={1}
                    alignItems={'center'}
                >
                    <Logo />
                </Stack>
            </Box>
            {user?.access_token &&
                <Box sx={{ px: 2 }}>
                    <SideBar />
                </Box>
            }
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    // TODO extract to a component
    if (!retailerSet) {
        return (
            <Stack
                justifyContent={'center'}
                sx={{
                    height: '100vh',
                }}
            >
                <img
                    src={CanadaBeefLogo}
                    alt="img"
                    className="logo_img"
                    style={{ zIndex: 1, position: "relative" }}
                />
                
                <Typography
                    sx={{
                        textAlign: 'center',
                        mt: 1
                    }}
                >
                    Your account is not associated to a Canada Beef Partner and therefore can not use this application.
                </Typography>

                <Typography
                    sx={{
                        textAlign: 'center',
                        mt: 1
                    }}
                >
                    <a href='/login'>
                        Back to login
                    </a>
                </Typography>
            </Stack>
        )
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            
            <Box
                component="nav"
                sx={{
                    width: {
                        sm: drawerWidth,
                    },
                    flexShrink: {
                        sm: 0
                    }
                }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {
                            xs: 'block',
                            md: 'none'
                        },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: 250
                        },
                    }}
                >
                    {drawer}
                </Drawer>

                <Drawer
                    variant="permanent"
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block'
                        },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: {
                        xs: '100%',
                        md: `calc(100% - ${drawerWidth}px)`
                    }
                }}
            >

                {
                    !!!defaults ?
                    <Stack
                        justifyContent={'center'}
                        sx={{
                            height: '100vh',
                        }}
                    >
                        <img
                            src={CanadaBeefLogo}
                            alt="img"
                            className="logo_img"
                            style={{ zIndex: 1, position: "relative" }}
                        />
                    </Stack> :
                    <>
                        <Box
                            sx={{
                                background: 'rgba(216, 216, 216, 0.24)',
                                px: {
                                    xs: 1,
                                    sm: 2,
                                    md: 3,
                                },
                                py: 2,
                            }}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                            >
                                <IconButton
                                    aria-label="open drawer"
                                    // edge="end"
                                    onClick={handleDrawerToggle}
                                    sx={{
                                        ...(mobileOpen && { display: 'none' }),
                                        color: '#000',
                                        p: 0,
                                        display: {
                                            xs: 'block',
                                            md: 'none',
                                        }
                                    }}
                                >
                                    <Menu fontSize="inherit" />
                                </IconButton>

                                <UserMenu
                                    sx={{
                                        ml: 'auto',
                                    }}
                                />
                            </Stack>
                        </Box>
                        
                        <Box
                            sx={{
                                px: {
                                    xs: 1,
                                    sm: 2,
                                    md: 3,
                                },
                                py: 4
                            }}
                        >
                            {children}
                        </Box>
                    </>
                }
            </Box>
        </Box>
    );
}

export default Authenticated;
