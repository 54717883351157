import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

import './index.scss';

const RecipeSummary = ({ summaryValues = [] }) => {
    return (
        <>
            { summaryValues.map(total => (
                <Box className='section-title' sx={{ my: 2 }} key={total.label}>
                    <Grid container>
                        <Grid item xs={8} md={6} lg={3}>
                            <Box
                                sx={{
                                    borderLeft: '4px solid rgb(51, 51, 51)',
                                    pl: 1
                                }}
                            >
                                <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 300 }}>{total.label}</Typography>
                            </Box>
                        </Grid>
                        <Grid>
                            <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 200 }}>{total.value}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </>
    )
}

export default RecipeSummary