import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

import BasicTable from "../../shared/Table";

const MultipleBarcodesProducts = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);
  const [products, setProducts] = useState([]);
  const retailerId = retailer?.id;
  const headers = [
    { key: "name", name: "Product Name", disabled: true },
    { key: "barcodes", name: "Barcodes", disabled: true },
    showFullData && { key: "retailer", name: "Retailer", disabled: true },
  ];
  const productsPayload = {
    fields: [
      "*",
      "translations.*",
      "barcode.*",
      "barcode.owner.id",
      "barcode.owner.translations.*",
      "barcode.beef_product_id.id",
      "barcode.beef_product_id.translations.*",
    ],
    limit: -1,
  };

  const { data: productData, isLoading } = useSWR({
    url: `beef_product?meta=*&filter[_and][0][_and][0][count(barcode)][_gt]=1${
      showFullData
        ? ""
        : `&filter[_and][0][_and][1][retailers][retailer_id][id][_eq]=${retailerId}&filter[_and][0][_and][2][barcode][owner][id][_in][0]=${retailerId}`
    }`,
    ...productsPayload,
  });

  useEffect(() => {
    if (productData) {
      // All retailers multiple barcode data
      if (showFullData) {
        // Create an object to store barcodes based on owner
        const barcodeOwners = {};

        // Iterate through the data array and organize barcodes by owner
        productData?.data.forEach(({ barcode }) => {
          if (barcode && barcode.length > 1) {
            barcode.forEach((barcode) => {
              const {
                owner: { id: ownerId },
                beef_product_id: { id: beefProductId },
              } = barcode;

              if (ownerId) {
                const key = `${ownerId}---${beefProductId}`;

                // If the owner is already in the object, push the barcode; otherwise, initialize with an array
                barcodeOwners[key] = barcodeOwners[key] || [];
                barcodeOwners[key].push(barcode);
              }
            });
          }
        });

        // Create an array of items where there is more than one barcode with the same owner
        const resultArray = Object.entries(barcodeOwners)
          .filter(([_, barcodes]) => barcodes.length > 1)
          .map(([owner, barcodes]) => ({
            owner,
            barcodes,
          }));

        setProducts(
          resultArray
            ?.map(({ barcodes }) => ({
              name:
                barcodes[0]?.beef_product_id?.translations[0]?.name ??
                "(Not Provided)",
              barcodes: barcodes?.map(({ code, id }) => ({
                name: code,
                id: id,
              })),
              retailer:
                barcodes[0]?.owner?.translations[0]?.retailer_name ??
                "(Not Provided)",
            }))
            ?.sort((a, b) => a.name.localeCompare(b.name))
        );
      } else {
        // Single retailer multiple barcode data
        setProducts(
          productData?.data
            ?.map(({ translations, barcode }) => ({
              name: translations[0]?.name ?? "(Not Provided)",
              barcodes: barcode
                ?.filter(({ owner }) => owner?.id === retailerId)
                ?.map(({ code, id }) => ({ name: code, id: id })),
            }))
            ?.filter(({ barcodes }) => barcodes?.length > 1)
            ?.sort((a, b) => a.name.localeCompare(b.name))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Products With Multiple Barcodes
          </Typography>
        </Box>

        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            List of products with more than one barcode which associates to it.
          </Typography>
        </Box>
      </Box>

      <BasicTable
        headers={headers}
        isLoading={isLoading}
        hidePagination={true}
        items={products}
      />
    </Box>
  );
};

export default MultipleBarcodesProducts;
