import { useEffect, useState } from 'react';

import useSWR from "swr";

import { Box, Chip, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import ListPage from '../../layouts/ListPage';
import LazyLoad from 'react-lazyload';

import AddIconImage from '../../shared/svg/plus.svg';

import { videoToolkitFetcher } from "../../common/utils/fetcher";
import { useCheckVideoToolsEnabled } from '../../common/hooks/useCheckVideoToolsEnabled';
import FullPageMessage from '../../components/shared/FullPageMessage';
import { Add, PlayCircle } from '@mui/icons-material';

const SmartVideoProjectActions = ({ url }) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
    >
      {
        !!url &&
        <Chip
          icon={<PlayCircle />}
          label="Play Video"
          color="success"
          variant="outlined"
          component="a"
          target="_blank"
          href={url}
          sx={{
            cursor: 'pointer',
          }}
        />
      }
    </Stack>
  )
}

const SmartVideoProjects = () => {
  const videoToolsEnabled = useCheckVideoToolsEnabled();
  
  const [batchesMeta, setBatchesMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useSWR({
    url: 'project',
    sort: '-id',
  }, videoToolkitFetcher);

  let items = [];

  if (data) {
    items = data;
  }

  const summaryValues = [
    {
        label: 'Total',
        value: batchesMeta?.total_count,
    },
  ];

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async () => {
    try {
      const fetchBatchesResponse = await API.fetchFromVideoToolkit('project', {
        sort: '-user_created',
      });
      setBatchesMeta(fetchBatchesResponse.meta);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  if (!videoToolsEnabled) {
    return (
      <FullPageMessage />
    )
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ListPage
      title='Smart Video Assets'
      summaries={summaryValues}
      searchStorageKey={'smart-videos-assets'}
      searchOnly={['ASSETS']}
      breadcrumbsLinks={[
        {
          url: '/smart-videos',
          content: 'Smart Videos',
        }
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
          >
            <Box
              sx={{
                height: '100%',
              }}
            >
              <ImageCard
                url={`create`}
                item={{
                  title: 'Create New Project',
                  url: AddIconImage
                }}
              />
            </Box>
          </Grid>
          {items?.map((item) => (
            <Grid
              key={item.ID}
              item
              xs={12}
              md={3}
              lg={2}
            >
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <LazyLoad
                  height='100%'
                >
                  <ImageCard
                    url={`${item.ID}`}
                    item={{
                      title: item.name,
                      url: item.logo ? `${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${item.image}` : ''
                    }} 
                    footerAction={(
                      <SmartVideoProjectActions
                        url={`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${item.base_video}`}
                      />
                    )}
                  />
                </LazyLoad>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default SmartVideoProjects