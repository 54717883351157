import { combineReducers } from "redux";

import scansReducer from './scans';
import retailerReducer from './retailer';
import userReducer from "./user";
import filtersReducer from "./filters";
import searchReducer from "./search";
import dataReducer from "./data";
import recipesReducer from "./recipesReducer";
import beefProductBrandsReducer from "./beefProductBrandsReducer";
import cookingMethodsReducer from "./cookingMethodsReducer";
import beefOverridesReducer from "./beefOverridesReducer";
import beefCutsReducer from "./beefCutsReducers";
import videosReducer from "./videosReducers";
import featuredProductsReducer from "./featuredProductsReducer";
import qrCodesReducer from "./qrCodesReducer";

const rootReducer = combineReducers({
    beefCuts: beefCutsReducer,
    beefOverrides: beefOverridesReducer,
    beefProductBrands: beefProductBrandsReducer,
    cookingMethods: cookingMethodsReducer,
    data: dataReducer,
    featuredProducts: featuredProductsReducer,
    filters: filtersReducer,
    qrCodes: qrCodesReducer,
    retailer: retailerReducer,
    scans: scansReducer,
    search: searchReducer,
    user: userReducer,
    recipes: recipesReducer,
    videos: videosReducer,
});

export default rootReducer;