import { createStore } from 'redux';

import rootReducer from '../reducers';

const localStorageKey = 'retailerPortalState';

const persistedState = localStorage.getItem(localStorageKey) 
  ? JSON.parse(localStorage.getItem(localStorageKey))
  : {
    retailer: null,
    scans: {},
    user: {
      access_token: '',
      first_name: 'Welcome',
      last_name: '',
    }
  };

const store = createStore(
  rootReducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

document?.body?.style?.setProperty('--brand-color', store.getState()?.retailer?.brand_color);
document?.body?.style?.setProperty('--brand-color-2', store.getState()?.retailer?.brand_color_2);
document?.body?.style?.setProperty('--button-background-color', store.getState()?.retailer?.button_background_color);
document?.body?.style?.setProperty('--icon-color', store.getState()?.retailer?.icon_color);
document?.body?.style?.setProperty('--site-background-color', store.getState()?.retailer?.site_background_color);

document?.body?.style?.setProperty('--body-font',  store.getState()?.retailer?.body_font);
document?.body?.style?.setProperty('--header-font',  store.getState()?.retailer?.header_font);
document?.body?.style?.setProperty('--button-font',  store.getState()?.retailer?.button_font);

store.subscribe(()=>{
    localStorage.setItem(localStorageKey, JSON.stringify(store.getState()));

    document?.body?.style?.setProperty('--brand-color', store.getState()?.retailer?.brand_color);
    document?.body?.style?.setProperty('--brand-color-2', store.getState()?.retailer?.brand_color_2);
    document?.body?.style?.setProperty('--button-background-color', store.getState()?.retailer?.button_background_color);
    document?.body?.style?.setProperty('--icon-color', store.getState()?.retailer?.icon_color);
    document?.body?.style?.setProperty('--site-background-color', store.getState()?.retailer?.site_background_color);

    document?.body?.style?.setProperty('--body-font',  store.getState()?.retailer?.body_font);
    document?.body?.style?.setProperty('--header-font',  store.getState()?.retailer?.header_font);
    document?.body?.style?.setProperty('--button-font',  store.getState()?.retailer?.button_font);
});

export default store;