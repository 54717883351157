import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CanadaBeefLogo from '../../../shared/svg/canada-beef-logo.svg';
import { Link } from 'react-router-dom';

const FullPageMessage = () => {
    return (
        <Stack
            justifyContent={'center'}
            sx={{
                height: '100vh',
            }}
        >
            <img
                src={CanadaBeefLogo}
                alt="img"
                className="logo_img"
                style={{ zIndex: 1, position: "relative" }}
            />

            <Typography
                sx={{
                    textAlign: 'center',
                    mt: 1
                }}
            >
                Your account is not authorised to perform this operation.
            </Typography>

            <Typography
                sx={{
                    textAlign: 'center',
                    mt: 1
                }}
            >
                <Link
                    to={'/smart-videos'}
                >
                    Back to menu
                </Link>
            </Typography>
        </Stack>
    )
}

export default FullPageMessage