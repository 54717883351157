import { useState } from 'react';

import useSWR from 'swr';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import { getTranslation } from '../../common/utils/translate';
import ListPage from '../../layouts/ListPage';
import useCountInJunction from '../../common/hooks/useCountInJunction';

const Collections = () => {
  const filter = {
    _and: [
      {
        type: {
          _neq: 'CUISINE'
        }
      },
      {
        status: {
          _eq: 'published'
        }
      },
    ]
  };

  const payload = {
    fields: [
      '*',
      'translations.*',
    ],
    filter,
    limit: -1,
  };

  let { data: cuisine } = useSWR({
    url: 'collections',
    ...payload,
  });

  if (cuisine) {
    cuisine = cuisine.map((item) => {
      const translatedItem = getTranslation(item, { key: 'languages_code', code: 'en-US' });
      const image = translatedItem.image ? `${process.env.REACT_APP_API_URL}/assets/${translatedItem.image}` : null;

      return {
        title: translatedItem?.title,
        description: translatedItem?.content,
        image,
        url: `/collections/${item.id}`,
      };
    });
  }

  const [loading, setLoading] = useState(false);

  const cuisineCount = useCountInJunction(
    'collections',
    {
      aggregate: {
        count: '*'
      },
      filter,
    },
    loading,
  );

  const summaryValues = [
    {
      label: 'Total',
      value: cuisineCount || '-'
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <ListPage
      title='Collections'
      summaries={summaryValues}
      searchStorageKey={'cuisine'}
      handleSearchComplete={() => { }}
      searchOnly={['COLLECTIONS']}
      breadcrumbsLinks={[
        {
          url: '/collections-menu',
          content: 'Collections & Cuisine'
        }
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          {cuisine?.map(item => (
            <Grid
              item
              key={item.id}
              xs={12}
              md={3}
              lg={2}
            >
              <ImageCard
                url={item.url}
                item={{
                  title: item.title,
                  description: item.description,
                  url: item.image,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default Collections