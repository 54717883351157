import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import API from '../../api';

import AddIconImage from '../../shared/svg/plus.svg';
import CompletedIconImage from '../../shared/svg/check.svg';

import ImageCard from '../../components/ImageCard';

import './index.scss';
import ListPage from '../../layouts/ListPage';

function SmartVideosMenu() {
  const user = useSelector(state => state.user);

  const profileCompletionChecks = [
    {
      title: 'Renders',
      description: ``,
      completed: false,
      uri: 'renders'
    },
    ...(user.enable_video_tools ? [
      {
        title: 'Batches',
        description: ``,
        completed: false,
        uri: 'batches'
      },
      {
        title: 'Projects',
          description: ``,
            completed: false,
              uri: 'projects'
      },
      {
        title: 'Templates',
          description: ``,
            completed: false,
              uri: 'templates/create'
      },
    ] : []),
    {
      title: 'Brands',
      description: ``,
      completed: false,
      uri: 'brands'
    },
  ];

  return (
    <ListPage
      title={'Smart Video Management'}
      description={'With the Gateway, shopping for cuts of beef has never been easier. Simply look for the QR code on packages of beef, scan the code, select the cut of beef and enjoy. You’ll find recipes, beef cut information, nutrition and more. Click here to learn more about this program.'}
      breadcrumbsLinks={[
        {
          url: '/',
          content: 'Content Library',
        },
      ]}
    >
      <Box className="check-icon" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {profileCompletionChecks.map((check) => (
            <Grid
              key={check.title}
              item
              xs={6}
              md={3}
              lg={2}
            >
              <Link to={check.uri}>
                <ImageCard
                  item={{
                    title: check.title,
                    description: check.description,
                    url: check.completed ? CompletedIconImage : AddIconImage
                  }}
                  icon={true}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default SmartVideosMenu;