import { Input, MenuItem, Select, Stack, Typography } from "@mui/material"

function Dropdown({ field, label, description, value, options, handleInputChange }) {
    const onChange = (field, value) => {
        handleInputChange(field, value)
    };

    return (
        <Stack>
            <Typography
                className="form-label"
                variant='label'
            >
                {label}
            </Typography>

            <Select
                inputProps={{
                    className: 'form-input'
                }}
                sx={{
                    borderRadius: 0,
                    '& .MuiSelect-select': {
                        borderRadius: 0
                    }
                }}
                value={value}
                onChange={(event) => { onChange(field, event.target.value) }}
            >
                {
                    options.map(option => (
                        <MenuItem
                            key={option}
                            sx={{
                                textTransform: 'uppercase'
                            }}
                            value={option}
                        >
                            {option}
                        </MenuItem>
                    ))
                }
            </Select>

            <Typography
                className="form-input-description"
                variant='p'
            >
                {description}
            </Typography>
        </Stack>
    )
}

export default Dropdown