import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

import API from '../../api';

import { logoutUser, updateUser } from '../../actions/User';
import { resetRetailer, updateRetailer } from '../../actions/Retailer';

import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import { useQuery } from '../../common/hooks/useQuery';

import LoginPageImage from '../../shared/image/cows-image.jpeg';

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useQuery();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    useEffect(() => {
        dispatch(logoutUser());
        dispatch(resetRetailer());
    }, []);

    const login = async () => {
        try {
            setLoading(true);

            const loginPayload = {
                email,
                password
            };

            const loginResponse = await API.login(loginPayload);

            // save tokens to state
            dispatch(updateUser(loginResponse));

            // get user & retailer details
            const userResponse = await API.getUserAndRetailer();

            // save retailer state
            const retailer = userResponse.retailer;
            dispatch(updateRetailer(retailer));

            // save additional user details to state
            delete userResponse.retailer;
            dispatch(updateUser(userResponse));

            notify('success', 'Welcome back');

            // redirect to home if no redirect query param
            const redirectUrl = query.get('redirect') || '/';
            navigate(redirectUrl);
        } catch (error) {
            error?.map((err) => {
                notify('error', err.message || 'Something went wrong. Please try again.');
            });
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                // py: 3,
                height: '100vh',
            }}
        >
            <Grid
                container
                sx={{
                    height: '100%',
                }}
            >
                <Grid item
                    xs={12}
                    md={4}
                    lg={3}
                >
                    <Stack
                        justifyContent={'center'}
                        sx={{
                            p: 3,
                            height: '100%',
                        }}
                    >
                        <Box>
                            <Typography
                                className='form-title'
                                sx={{
                                    mb: 2
                                }}
                                variant="h2"
                            >
                                Sign In
                            </Typography>

                            <Box>
                                <Typography
                                    className="form-label"
                                    htmlFor="input-login-email"
                                    variant='label'
                                >
                                    Email
                                </Typography>
                                <Input
                                    id="input-login-email"
                                    inputProps={{
                                        className: 'form-input'
                                    }}
                                    sx={{
                                        color: '#262626 !important',
                                        py: 2,
                                        width: '100%'
                                    }}
                                    value={email}
                                    onChange={(event) => { setEmail(event.target.value) }}
                                    placeholder=""
                                    variant="standard"
                                />
                            </Box>

                            <Box>
                                <Typography className="form-label" htmlFor="input-login-password" variant='label'>Password</Typography>
                                <Input
                                    id="input-login-password"
                                    inputProps={{
                                        className: 'form-input'
                                    }}
                                    sx={{
                                        color: '#262626 !important',
                                        py: 2,
                                        width: '100%'
                                    }}
                                    type="password"
                                    label="Password"
                                    value={password}
                                    onChange={(event) => { setPassword(event.target.value) }}
                                />
                            </Box>

                            <Button
                                disabled={loading}
                                className='form-button'
                                variant="contained"
                                onClick={login}
                                sx={{
                                    mt: 2,
                                }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Stack>
                </Grid>

                <Grid item 
                    xs={12}
                    md={8}
                    lg={9}
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block'
                        },
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <img
                            src={LoginPageImage}
                            alt="img"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Login