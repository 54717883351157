import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import RecipeSummary from '../../components/RecipeSummary';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import { updateRetailer } from '../../actions/Retailer';
import { updateBeefCuts } from '../../actions/Data';
import { getTranslation } from '../../common/utils/translate';
import LinkToCreate from '../../components/LinkToCreate';
import { Link } from 'react-router-dom';
import ListPage from '../../layouts/ListPage';
import useCountInJunction from '../../common/hooks/useCountInJunction';
import LibraryActions from '../../components/shared/LibraryActions';
import LazyLoad from 'react-lazyload';

const BeefCuts = () => {
  const retailer = useSelector(state => state.retailer);
  const beefCuts = useSelector(state => state.beefCuts.data);
  const beefCutsStore = useSelector(state => state.beefCuts);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const beefCutsCount = useCountInJunction(
    'beef_product_retailer',
    {
      aggregate: {
        count: '*'
      },
      filter: {
        _and: [
          { retailer_id: { _eq: retailer.id } }
        ]
      },
    },
    loading
  );

  const summaryValues = [
    {
        label: 'Total',
        value: beefCutsStore.meta.total_count
    },
    {
        label: 'Collected',
        value: beefCutsCount || '-',
    },
  ];

  const makeGetBeefCutsRequest  = async () => {
    return await API.getListProducts({
      limit: 20,
      filter: {
        '_and': ([])
      }
    });
  };

  const addBeefCuteToLibray = async (beefCutId) => {
    try {
      setLoading(true);

      // get retailer details
      // TODO consider using retailer end point with 
      const userResponse = await API.getUserAndRetailer();

      // save retailer state
      let retailer = userResponse.retailer;
      dispatch(updateRetailer(retailer));

      // get list of recipes
      const payload = {
        beef_products: retailer.beef_products
      }

      // append
      payload.beef_products.push({
        beef_product_id: beefCutId
      });

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);
      dispatch(updateRetailer(retailer));

      // get new list of beef cuts
      // TODO consider refactoring this function
      await fetchBeefCuts();
    } catch(error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const removeBeefCutFromLibrary = async (beefCutId) => {
    try {
      setLoading(true);

      // get retailer details
      // TODO consider using retailer end point with 
      const userResponse = await API.getUserAndRetailer();

      // save retailer state
      let retailer = userResponse.retailer;
      dispatch(updateRetailer(retailer));

      // get list of recipes
      const payload = {
        beef_products: retailer.beef_products.filter(beefProduct => beefProduct.beef_product_id !== beefCutId)
      }

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);
      dispatch(updateRetailer(retailer));

      // get new list of beef cuts
      // TODO consider refactoring this function
      await fetchBeefCuts();
    } catch(error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const makeRetailerPatchRequest = async (payload) => {
    return await API.updateRetailer(payload);
  }

  useEffect(() => {
    fetchBeefCuts();
  }, []);

  const fetchBeefCuts = async () => {
    try {
      const listBeefCuts = await makeGetBeefCutsRequest();

      dispatch(
        updateBeefCuts(listBeefCuts)
      );
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const handleSearchComplete = (results) => {};

  if (loading) {
    return <Loader />;
  }

  return (
    <ListPage
      title={'Beef Cuts'}
      description={'With the Gateway, shopping for cuts of beef has never been easier. Simply look for the QR code on packages of beef, scan the code, select the cut of beef and enjoy. You’ll find recipes, beef cut information, nutrition and more. Click here to learn more about this program.'}
      summaries={summaryValues}
      searchOnly={['BEEF_CUTS']}
      handleSearchComplete={handleSearchComplete}
      searchStorageKey={'beef-cuts'}
      breadcrumbsLinks={[
        {
          url: '/recipes-menu',
          content: 'Content Library',
        },
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          {beefCuts?.map((beefCut) => (
            <Grid
              key={beefCut.id}
              item
              xs={12}
              md={3}
              lg={2}
            >
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <LazyLoad
                  height='100%'
                >
                  <ImageCard
                    url={`/beef-cuts/${beefCut.id}`}
                    item={{
                      title: getTranslation(beefCut, { key: 'languages_id', code: 'en-US' }, 'translations')?.name || ' ',
                      description: getTranslation(beefCut, { key: 'languages_id', code: 'en-US' }, 'translations')?.description || ' ',
                      url: beefCut.image ? `https://staging.beef.tanglemedia.net/assets/${beefCut.image}` : ''
                    }}
                    footerAction={
                      <LibraryActions
                        id={beefCut.id}
                        junctionTable='beef_product_retailer'
                        junctionPayload={{
                          beef_product_id: beefCut.id,
                          retailer_id: retailer.id
                        }}
                      />
                    }
                  />
                </LazyLoad>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default BeefCuts