import { Fragment, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import API from '../../api';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import api from '../../api';
import { videoToolkitFetcher } from '../../common/utils/fetcher';
import Wizard from '../../components/shared/Wizard';

function SmartVideosTemplateCreate() {
  useRefreshToken();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [videoTemplates, setVideoTemplates] = useState([]);
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [color, setColor] = useState('');
  const [color2, setColor2] = useState('');
  const [logo, setLogo] = useState('');
  const [closingSlide, setClosingSlide] = useState('');

  const params = useParams();
  const id = params.id;

  let currentName = '';
  let currentWebsite = '';
  let currentColor = '';
  let currentColor2 = '';
  let logoId = '';
  let closingSlideId = '';

  const { data, isLoading } = useSWR({
    url: id ? `/brand/${id}` : null,
    fields: '*',
  }, videoToolkitFetcher);

  if (data) {
    currentName = data.name;
    currentWebsite = data.website;
    currentColor = data.color;
    currentColor2 = data.color2;
    logoId = data.logo;
    closingSlideId = data.closing_slide;
  }

  const formSections = [
    {
      title: 'Details',
      fields: [
        {
          label: 'name',
          description: 'A name to identify this brand',
          name: 'name',
          type: 'text',
          value: name || currentName,
          handleInputChange: (field, value) => setName(value),
        },
        {
          label: 'Height',
          description: 'Height',
          name: 'height',
          type: 'text',
          value: website || currentWebsite,
          handleInputChange: (field, value) => setWebsite(value),
        },
        {
          label: 'Width',
          description: 'Height',
          name: 'width',
          type: 'text',
          value: website || currentWebsite,
          handleInputChange: (field, value) => setWebsite(value),
        },
      ]
    },
    {
      title: 'Edits',
      fields: [
        
      ]
    },
  ]

  const create = async () => {
    // TODO validate the form
    // build the payload

    const payload = {
      name: name || currentName,
      website: website || currentWebsite,
      color: color || currentColor,
      color2: color2 || currentColor2,
      color2: color2 || currentColor2,
      logo: logo || logoId,
      closing_slide: closingSlide || closingSlideId,

    };

    console.log('payload', payload);

    try {
      await api.updateVideoToolkit(`/brand/${id}`, payload);
      notify('success', 'Brand updated');
      navigate('/smart-videos/brands');
    } catch (error) {
      error?.map((err) => notify('error', err.message));
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ViewPage
      title={`Create Smart Video Template`}
      breadcrumbsLinks={[
        {
          url: '/smart-videos',
          content: 'Smart Videos'
        },
        {
          url: '/smart-videos/templates',
          content: 'Smart Video Templates'
        }
      ]}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Wizard
          pages={formSections}
          finalStageAction={(
            <Button
              disabled={loading}
              size="large"
              onClick={() => create()}
            >
              Save Asset
            </Button>
          )}
        />
      </Box>
    </ViewPage>
  )
}

export default SmartVideosTemplateCreate;