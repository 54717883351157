import { useState } from 'react';
import { useParams } from 'react-router-dom';

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

import Wysiwyg from '../../components/shared/Wysiwyg';

import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translate';
import { getAuthorDisplayName } from '../../utils/author';

function ArticleView() {
  useRefreshToken();

  const params = useParams();

  let title = '';
  let description = '';

  let fields = [];
  let sidebarFields =[];

  const [loading, setLoading] = useState(false);

  const payload = {
    fields: [
      '*',
      'translations.*',
    ],
  };

  let { data: article } = useSWR({
    url: `pages/${params.id}`,
    ...payload,
  });

  if (article) {
    const translatedItem = getTranslation(article, { key: 'languages_code', code: 'en-US' });

    const image = article?.image ?
      `${process.env.REACT_APP_API_URL}/assets/${article.image}` : null;

    title = translatedItem.title;

    fields = [
      {
        key: 'Title',
        value: translatedItem.title,
      },
      {
        key: 'Summary',
        value: translatedItem.summary,
      },
    ];

    sidebarFields = [
      {
        key: 'Image',
        children: (
          <ImageContainer
            url={image}
            alt={translatedItem.title}
            sx={{
              height: '20rem',
            }}
          />
        )
      },
    ];
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        breadcrumbsLinks={[
          {
            url: '/cba-menu',
            content: 'Canada Beef Advantage',
          },
          {
            url: `/articles`,
            content: 'Articles',
          }
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default ArticleView