import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Box, Typography } from "@mui/material";
import { CheckOutlined, ClearOutlined } from "@mui/icons-material";

import BasicTable from "../../shared/Table";

const DiagnosticsTable = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const headers = [
    { key: "name", name: "Name", disabled: true },
    { key: "image", name: "Image", disabled: true },
    { key: "Nutrients", name: "Nutrients", disabled: true },
  ];
  const diagnosticsTablePayload = {
    fields: ["id", "translations.*,Nutrients.*,image.*"],
    filter: {
      _or: [
        {
          image: {
            _null: true,
          },

          Nutrients: {
            _null: true,
          },
        },
      ],
    },
    limit: -1,
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data: diagnosticsTableData, isLoading } = useSWR({
    url: `beef_product?page=${page + 1}&meta=*`,
    ...diagnosticsTablePayload,
    limit: rowsPerPage,
  });

  useEffect(() => {
    let data;
    if (diagnosticsTableData) {
      data = diagnosticsTableData?.data
        ?.map((diagnosticsItem) => ({
          key: diagnosticsItem?.id,
          name: diagnosticsItem?.translations[0]?.name ?? "(Not Provided)",
          Nutrients:
            diagnosticsItem?.Nutrients === null ? (
              <ClearOutlined sx={{ color: "#ff0000" }} />
            ) : (
              <CheckOutlined sx={{ color: "#00ff00" }} />
            ),
          image:
            diagnosticsItem?.image === null ? (
              <ClearOutlined sx={{ color: "#ff0000" }} />
            ) : (
              <CheckOutlined sx={{ color: "#00ff00" }} />
            ),
        }))
        .sort((a, b) => b.value - a.value);
    }
    setItems(data);
  }, [diagnosticsTableData]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Product diagnostics
          </Typography>
        </Box>

        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            Identifies products without images and nutrition.
          </Typography>
        </Box>
      </Box>

      <BasicTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        headers={headers}
        items={items?.sort((a, b) => a.name.localeCompare(b.name))}
        totalCount={diagnosticsTableData?.meta?.filter_count}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default DiagnosticsTable;
