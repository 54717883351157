import { toast } from 'react-toastify';

const notify = (type, message) => {
  const tostProp = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  return type === 'default' ? toast(message, tostProp) : toast[type](message, tostProp);
};

export default notify;
