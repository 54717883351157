import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import API from '../../api';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

import Wysiwyg from '../../components/shared/Wysiwyg';
import RecipeVideos from '../../components/shared/RecipeVideos';

import { LightbulbOutlined } from '@mui/icons-material';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translate';
import Nutrition from '../../components/Nutrition';

function CookingMethodView() {
  useRefreshToken();

  const params = useParams();
  const { t } = useTranslation();

  const language = {
    code: 'en-US',
  };

  const retailer = useSelector(state => state.retailer);

  const cookingMethodId = params.cookingMethodId;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [fields, setFields] = useState([]);
  const [sidebarFields, setSidebarFields] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!cookingMethodId) return;

    makeGetQrCodeRequest();
  }, [cookingMethodId]);

  const makeGetQrCodeRequest = async () => {
    try {
      setLoading(true);

      const itemResponse = await API.getFrom('cooking_method', cookingMethodId);

      const translatedItem = getTranslation(itemResponse, { key: 'languages_code', code: 'en-US' });

      const image = itemResponse?.image ?
        `${process.env.REACT_APP_API_URL}/assets/${itemResponse.image}?key=tile-background-image` : null;

      setTitle(translatedItem.Method);

      setFields([
        {
          key: 'Name',
          value: translatedItem.Method,
        },
        {
          key: 'Summary',
          children: (
            <Typography>
              <Wysiwyg>{translatedItem.Description}</Wysiwyg>
            </Typography>
          )
        },
      ]);

      setSidebarFields([
        {
          key: 'Image',
          children: (
            <ImageContainer
              url={image}
              alt={translatedItem.Method}
              sx={{
                height: '20rem',
              }}
            />
          )
        },
      ])
    } catch (error) {
      console.log('error: ', error);
      notify('error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }


  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        addToLibrary={{
          junctionTable: 'retailer_cooking_method',
          filter: {
            _and: [
              { cooking_method_id: { _eq: cookingMethodId } },
              { retailer_id: { _eq: retailer.id } }
            ]
          },
          addPayload: {
            cooking_method_id: cookingMethodId,
            retailer_id: retailer.id
          },
          removePayload: {
            query: {
              filter: {
                retailer_id: {
                  _eq: retailer.id,
                },
                cooking_method_id: {
                  _eq: cookingMethodId,
                }
              }
            }
          },
        }}
        breadcrumbsLinks={[
          {
            url: '/recipes-menu',
            content: 'Content Library',
          },
          {
            url: `/cookimg-methods`,
            content: 'Cooking Methods',
          }
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default CookingMethodView