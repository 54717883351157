import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Box, Typography } from "@mui/material";

import BasicTable from "../../shared/Table";

const ProductsMostWidelyUsed = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const headers = [
    { key: "name", name: "Product Name", disabled: true },
    { key: "count", name: "Number Of Stores", disabled: true },
  ];
  const productsPayload = {
    fields: ["*", "translations.*"],
    limit: -1,
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data: productData, isLoading } = useSWR({
    url: `beef_product?page=${
      page + 1
    }&meta=*&filter[_and][0][_and][0][count(retailers)][_gt]=10`,
    ...productsPayload,
    limit: rowsPerPage,
  });

  useEffect(() => {
    if (productData) {
      setProducts(productData);
    }
  }, [productData]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Most Prevalent Products
          </Typography>
        </Box>

        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            Products most widely attached. In other words, which products are
            stores selling. (Products that have more than 10 stores)
          </Typography>
        </Box>
      </Box>

      <BasicTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        headers={headers}
        items={products?.data
          ?.map(({ translations, retailers }) => ({
            name: translations[0]?.name ?? "(Not Provided)",
            count: retailers?.length,
          }))
          ?.sort((a, b) => a.name.localeCompare(b.name))}
        totalCount={productData?.meta?.filter_count}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ProductsMostWidelyUsed;
