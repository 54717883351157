import { MenuItem, Select, Stack, Typography } from "@mui/material"

function StatusInput(props) {
    const { config, handleInputChange } = props;

    const statusOptions = [
        'published',
        'draft',
        'archived'
    ];

    return (
        <Stack>
            <Typography
                className="form-label"
                variant='label'
            >
                Status
            </Typography>
            <Select
                labelId="demo-simple-select-label"
                inputProps={{
                    className: 'form-input'
                }}
                sx={{
                    borderRadius: 0,
                    '& .MuiSelect-select': {
                        borderRadius: 0
                    }
                }}
                value={config.value}
                onChange={
                    event => {
                        handleInputChange(event.target.value, 'status')
                    }
                }
            >
                {statusOptions.map(option => (
                    <MenuItem
                        key={option}
                        value={option}
                        sx={{
                            textTransform: 'uppercase'
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </Stack>
  )
}

export default StatusInput