import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useSWR from "swr";

import { AccessTime, AccessTimeFilled, Add } from "@mui/icons-material";

import { Box, Chip, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import ListPage from '../../layouts/ListPage';
import LazyLoad from 'react-lazyload';

import AddIconImage from '../../shared/svg/plus.svg';

import { videoToolkitFetcher } from "../../common/utils/fetcher";
import api from '../../api';
import { useCheckVideoToolsEnabled } from '../../common/hooks/useCheckVideoToolsEnabled';
import FullPageMessage from '../../components/shared/FullPageMessage';

const SmartVideoBactionActions =  ({ url, }) => {
  const { data, isLoading } = useSWR({
    url,
  }, videoToolkitFetcher);

  const [queued, setQueued] = useState(false);

  const runBatch = async () => {
    try {
      setQueued(true);
      await api.updateVideoToolkit(`${url}`, {
        status: 'queued',
      });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
    >

    {
      isLoading && 
      <Chip
        icon={<AccessTimeFilled />}
        label="Loading"
        color="secondary"
        variant="outlined"
        disabled
      />
    }

    {
      (data?.status !== 'queued' && !queued ) &&
      <Chip
        icon={<AccessTime />}
        label="Run Batch"
        color="success"
        variant="outlined"
        onClick={runBatch}
      />
    }

    {
      (data?.status === 'queued' || queued) &&
      <Chip
        icon={<AccessTime />}
        label="Running"
        color="warning"
        variant="outlined"
      />
    }
    </Stack>
  )
}

const SmartVideoBatches = () => {
  const videoToolsEnabled = useCheckVideoToolsEnabled();
  
  const [batchesMeta, setBatchesMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useSWR({
    url: 'queue',
    sort: '-created',
  }, videoToolkitFetcher);

  let items = [];

  if (data) {
    items = data;
  }

  const summaryValues = [
    {
        label: 'Total',
        value: batchesMeta?.total_count,
    },
  ];

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async () => {
    try {
      const fetchBatchesResponse = await API.fetchFromVideoToolkit('queue', {
        sort: '-created',
        limit: 5,
      });
      setBatchesMeta(fetchBatchesResponse.meta);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  if (!videoToolsEnabled) {
    return <FullPageMessage />
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ListPage
      title='Smart Video Batches'
      summaries={summaryValues}
      searchStorageKey={'smart-videos-batches'}
      searchOnly={['BATCHES']}
      breadcrumbsLinks={[
        {
          url: '/smart-videos',
          content: 'Smart Videos',
        }
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
          >
            <Box
              sx={{
                height: '100%',
              }}
            >
              <ImageCard
                url={`create`}
                item={{
                  title: 'Create New Batch',
                  url: AddIconImage
                }}
              />
            </Box>
          </Grid>
          {items?.map((item) => (
            <Grid
              key={item.id}
              item
              xs={12}
              md={3}
              lg={2}
            >
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <LazyLoad
                  height='100%'
                >
                  <ImageCard
                    url={`${item.id}`}
                    item={{
                      title: item.name,
                      url: item.image ? `https://staging.beef.tanglemedia.net/assets/${item.image}` : ''
                    }} 
                    footerAction={
                      <SmartVideoBactionActions
                        url={`/queue/${item.id}`}
                      />
                    }
                  />
                </LazyLoad>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default SmartVideoBatches