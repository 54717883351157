export const updateFilters = (payload) => {
    return {
        type: 'filters/update',
        payload
    }
};

export const clearFilters = () => {
    return {
        type: 'filters/clear'
    }
};