import axios from "axios";

import store from "../app/store";

const baseUrl = "https://staging.beef.tanglemedia.net";

const getToken = () => {
  return store.getState().user.access_token;
};

const isAdmin = (user) => {
  if (user && user?.role === process.env.REACT_APP_ADMIN_ROLE_ID) {
    return true;
  } else {
    return false;
  }
};

const getRefreshToken = () => {
  return store.getState().user.refresh_token;
};

const getRetailer = () => {
  return store.getState().retailer;
};

const login = async (payload = {}) => {
  try {
    const res = await axios.post(`${baseUrl}/auth/login`, payload, {});
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const refreshToken = async () => {
  try {
    const res = await axios.post(
      `${baseUrl}/auth/refresh`,
      {
        refresh_token: getRefreshToken(),
      },
      {}
    );
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const createQrCode = async (payload = {}) => {
  try {
    const res = await axios.post(`${baseUrl}/items/qr`, payload, {
      params: {
        access_token: getToken(),
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const updateQrCode = async (payload = {}) => {
  try {
    const res = await axios.patch(
      `${baseUrl}/items/qr/${payload.id}`,
      payload,
      {
        params: {
          access_token: getToken(),
        },
      }
    );

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const updateBeefCut = async (payload = {}) => {
  console.log("payload: ", payload);

  try {
    const res = await axios.patch(
      `${baseUrl}/items/beef_product/${payload.id}`,
      payload,
      {
        params: {
          access_token: getToken(),
        },
      }
    );

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const deleteQrCode = async (payload = {}) => {
  try {
    const res = await axios.post(`${baseUrl}/items/qr`, payload, {
      params: {
        access_token: getToken(),
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const updateRetailer = async (payload = {}) => {
  try {
    const res = await axios.patch(
      `${baseUrl}/items/retailer/${getRetailer().id}`,
      payload,
      {
        params: {
          access_token: getToken(),
          fields:
            "*,translations.*,recipes.recipe_id,beef_products.beef_product_id",
        },
      }
    );

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getUserAndRetailer = async () => {
  try {
    const res = await axios.get(`${baseUrl}/users/me`, {
      params: {
        access_token: getToken(),
        fields:
          "first_name,last_name,enable_video_tools,id,status,retailer.*,retailer.translations.*,retailer.recipes.recipe_id,retailer.beef_product_brandings.beef_product_branding_id,retailer.beef_products.beef_product_id,role",
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getQrCode = async (qrCodeId) => {
  try {
    const res = await axios.get(`${baseUrl}/items/qr/${qrCodeId}`, {
      params: {
        access_token: getToken(),
        fields: "*",
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getQrCodes = async (filterParams = {}) => {
  try {
    const res = await axios.get(`${baseUrl}/items/qr`, {
      params: {
        ...{
          access_token: getToken(),
          fields: "id,name,url*",
        },
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const uploadFile = async (formData) => {
  try {
    const res = await axios.post(`${baseUrl}/files`, formData, {
      params: {
        access_token: getToken(),
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getListProducts = async (filterParams = {}) => {
  try {
    const res = await axios.get(`${baseUrl}/items/beef_product/`, {
      params: {
        ...{
          access_token: getToken(),
          fields:
            "id,name,description,date_created,parent_group,owner,svg_icon,image,retailers.retailer_id,translations.*",
          meta: "*",
        },
        ...filterParams,
      },
    });

    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getRecipes = async (
  productId = null,
  cookingMethodId = null,
  search = null,
  filterParams = {},
  categoryID = null
) => {
  const params = {
    access_token: getToken(),
    fields:
      "*,layout_translations.*,coupons.coupon_id.*,coupons.coupon_id.translations.*,beef_products.*,imagegallery.*,cooking_method.*,category.*.*,recipe_directions.*.*,youtube.*",
  };

  if (search) {
    params.search = search;
  }

  if (productId) {
    const filter = {
      beef_products: { beef_product_id: { _eq: productId } },
    };
    filterParams.filter._and.push(filter);
  }

  if (cookingMethodId) {
    const filter = {
      cooking_method: { cooking_method_id: { _eq: cookingMethodId } },
    };
    filterParams.filter._and.push(filter);
  }

  if (categoryID) {
    const filter = {
      category: { recipe_categories_id: { _eq: categoryID } },
    };
    filterParams.filter._and.push(filter);
  }

  try {
    const res = await axios.get(`${baseUrl}/items/recipe/`, {
      params: { ...params, ...filterParams },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getListCoupons = async (filterParams = {}) => {
  try {
    const res = await axios.get(`${baseUrl}/items/coupon/`, {
      params: {
        ...{
          access_token: getToken(),
          fields: "*,translations.*",
        },
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getListCookingMethods = async (filterParams = {}) => {
  const params = {
    access_token: getToken(),
    fields:
      "id,method,description,status,image,recipe.recipe_id.retailer,recipe.recipe_id.retailers.retailer_id,translations.*",
    meta: "*",
  };

  try {
    const res = await axios.get(`${baseUrl}/items/cooking_method/`, {
      params: { ...params, ...filterParams },
    });
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const fetchFromVideoToolkit = async (url, extraParams = {}) => {
  const params = {
    access_token: process.env.REACT_APP_SHOTSTACK_ADMIN_API_KEY,
    fields: '*',
    meta: '*',
    ...extraParams,
  };

  try {
    const res = await axios.get(`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/items/${url}/`, {
      params,
    });
    
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const uploadToVideoToolkit = async (url, payload, extraParams = {}) => {
  const params = {
    access_token: process.env.REACT_APP_SHOTSTACK_ADMIN_API_KEY,
    ...extraParams,
  };

  try {
    const res = await axios.post(`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/${url}/`, payload, {
      params,
    });
    
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const postToVideoToolkit = async (url, payload, extraParams = {}) => {
  const params = {
    access_token: process.env.REACT_APP_SHOTSTACK_ADMIN_API_KEY,
    ...extraParams,
  };

  try {
    const res = await axios.post(`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/items/${url}/`, payload, {
      params,
    });
    
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const updateVideoToolkit = async (url, payload, extraParams = {}) => {
  const params = {
    access_token: process.env.REACT_APP_SHOTSTACK_ADMIN_API_KEY,
    ...extraParams,
  };

  try {
    const res = await axios.patch(`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/items${url}`, payload, {
      params,
    });
    
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getListCategory = async (filterParams = {}) => {
  const params = {
    access_token: getToken(),
    fields:
      "id,category,status,recipe,recipe.recipe_id.retailer,recipe.recipe_id.retailers.retailer_id,image,translations.*",
  };

  try {
    const res = await axios.get(`${baseUrl}/items/recipe_categories/`, {
      params: { ...params, ...filterParams },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getListBeefProductBrands = async (search = null, filterParams = {}) => {
  const params = {
    access_token: getToken(),
    fields: "*,translations.*",
    meta: "*",
  };

  if (search) {
    params.search = search;
  }

  try {
    const res = await axios.get(`${baseUrl}/items/beef_product_branding/`, {
      params: { ...params, ...filterParams },
    });
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getListRecipe = async (
  productId = null,
  cookingMethodId = null,
  search = null,
  filterParams = {},
  categoryID = null
) => {
  const params = {
    access_token: getToken(),
    fields: "*,layout_translations.*,imagegallery.*,retailers.retailer_id",
    meta: "*",
  };

  if (search) {
    params.search = search;
  }

  if (productId) {
    const filter = {
      beef_products: { beef_product_id: { _eq: productId } },
    };
    filterParams.filter._and.push(filter);
  }

  if (cookingMethodId) {
    const filter = {
      cooking_method: { cooking_method_id: { _eq: cookingMethodId } },
    };
    filterParams.filter._and.push(filter);
  }

  if (categoryID) {
    const filter = {
      category: { recipe_categories_id: { _eq: categoryID } },
    };
    filterParams.filter._and.push(filter);
  }

  try {
    const res = await axios.get(`${baseUrl}/items/recipe/`, {
      params: { ...params, ...filterParams },
    });
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getLanguages = async () => {
  try {
    const res = await axios.get(`${baseUrl}/items/languages/`, {
      params: {
        access_token: getToken(),
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getPromotedProducts = async (filterParams = {}) => {
  try {
    const res = await axios.get(`${baseUrl}/items/ingredient/`, {
      params: {
        ...{
          access_token: getToken(),
          fields: "*,translations.*.*",
        },
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getListRetailer = async (
  filterParams = {},
  fields = "*,logo.*,translations.*"
) => {
  try {
    const res = await axios.get(`${baseUrl}/items/retailer/`, {
      params: {
        ...{ access_token: getToken(), fields: fields },
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getBeefProduct = async (id, deepFilter) => {
  try {
    const res = await axios.get(`${baseUrl}/items/beef_product/${id}`, {
      params: {
        deep: deepFilter,
        access_token: getToken(),
        fields:
          "id,name,description,parent_group.id,parent_group.translations.*,owner,beef_product_brandings.*.*.*,cooking_methods.*.*.*,translations.*,primal_configurations.*,svg_icon,image,retailer,status,date_created,recipes.*.*.*,Nutrients.*.*",
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getRecipe = async (id) => {
  try {
    const res = await axios.get(`${baseUrl}/items/recipe/${id}`, {
      params: {
        access_token: getToken(),
        fields:
          "*,layout_translations.*,imagegallery.*,recipe_directions.*.*,video.*,nutrition.*.*",
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getFrom = async (
  table,
  id,
  fields = "*,translations.*,layout_translations.*"
) => {
  try {
    const res = await axios.get(`${baseUrl}/items/${table}/${id}`, {
      params: {
        access_token: getToken(),
        fields,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getBarcode = async (barcode) => {
  try {
    const filter = { code: { _starts_with: barcode } };
    const res = await axios.get(`${baseUrl}/items/barcode`, {
      params: {
        access_token: getToken(),
        filter,
        fields: "*,beef_product_id.*.*.*,owner.*",
      },
    });
    return res.data.data;
  } catch (error) {
    throw error?.response?.data?.errors;
  }
};

const getVideos = async (seachString = null, filterParams = {}) => {
  try {
    const params = {
      access_token: getToken(),
      fields: "*,video_file.*,translations.*",
      meta: "*",
    };

    if (seachString) {
      params.search = seachString;
    }

    const res = await axios.get(`${baseUrl}/items/video_library/`, {
      params: { ...params, ...filterParams },
    });
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getBrand = async () => {
  try {
    const res = await axios.get(`${baseUrl}/items/brand/`, {
      params: { access_token: getToken() },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getRetailerDetails = async () => {
  try {
    const res = await axios.get(`${baseUrl}/items/retailer/`, {
      params: { access_token: getToken() },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getQRCode = async (filterParams = {}) => {
  try {
    const res = await axios.get(`${baseUrl}/items/qr/`, {
      params: {
        ...{ access_token: getToken() },
        ...filterParams,
        meta: "*",
      },
    });
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const findInJunction = async (junctionTable, filterParams = {}) => {
  try {
    const res = await axios.get(`${baseUrl}/items/${junctionTable}/`, {
      params: {
        access_token: getToken(),
        filter: filterParams,
      },
    });
    return res.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getNutrition = async (nutritionId) => {
  try {
    const res = await axios.get(`${baseUrl}/items/nutrition/${nutritionId}`, {
      params: {
        access_token: getToken(),
        fields: "*,translations.*",
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const postQRCodeAnalytics = async (payload = {}) => {
  try {
    const res = await axios.post(
      `${baseUrl}/items/qr_code_analytics/`,
      payload,
      {
        params: { access_token: getToken() },
      }
    );

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const postLoadPageViewAnalytics = async (payload = {}) => {
  try {
    const res = await axios.post(`${baseUrl}/items/page_views/`, payload, {
      params: { access_token: getToken() },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const postBarcode = async (payload = {}) => {
  try {
    const res = await axios.post(`${baseUrl}/items/barcode/`, payload, {
      params: { access_token: getToken() },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getJunctionItems = async (junctionTable, payload) => {
  try {
    const res = await axios.get(`${baseUrl}/items/${junctionTable}`, {
      params: {
        access_token: getToken(),
        ...payload,
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const addToJunction = async (junctionTable, payload) => {
  try {
    const res = await axios.post(`${baseUrl}/items/${junctionTable}`, payload, {
      params: { access_token: getToken() },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const deleteFromJunction = async (junctionTable, payload) => {
  try {
    const res = await axios.delete(`${baseUrl}/items/${junctionTable}`, {
      data: payload,
      params: {
        access_token: getToken(),
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const handleResponseError = (error) => {
  let errorMessages = [];

  if (error.response) {
    // if error 403 fhen redirect to login page
    if ([401, 403].includes(error.response.status)) {
      const currentUrl =
        window.location.pathname === "/login" ? "" : window.location.pathname;
      if (window.location.pathname === "/login") {
        // invalid credentials
      } else {
        window.location.href = `/login?redirect=${currentUrl}`;
      }
    }

    errorMessages = [...error.response?.data?.errors, ...errorMessages];
  } else if (error.request) {
    errorMessages.push({
      message: "Network error",
    });
  } else {
    errorMessages.push({
      message: error.message || "Failed to setup request",
    });
  }
  return errorMessages;
};

const searchArticles = async (filterParams = {}, fields = ["*"]) => {
  const params = {
    access_token: getToken(),
    fields,
  };

  try {
    const res = await axios.get(`${baseUrl}/items/pages/`, {
      params: {
        ...params,
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const searchRecipes = async (filterParams = {}, fields = ["*"]) => {
  const params = {
    access_token: getToken(),
    fields,
  };

  try {
    const res = await axios.get(`${baseUrl}/items/recipe/`, {
      params: {
        ...params,
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const search = async (table, filterParams = {}, fields = ["*"]) => {
  const params = {
    access_token: getToken(),
    fields,
  };

  try {
    const res = await axios.get(`${baseUrl}/items/${table}/`, {
      params: {
        ...params,
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const searchInTable = async ({ url, ...params }) => {
  try {
    const res = await axios.get(`${baseUrl}/items/${url}/`, {
      params: {
        access_token: getToken(),
        ...params,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const searchBeefCuts = async (filterParams = {}, fields = ["*"]) => {
  const params = {
    access_token: getToken(),
    fields,
  };

  try {
    const res = await axios.get(`${baseUrl}/items/beef_product/`, {
      params: {
        ...params,
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const searchRecipeAuthors = async (filterParams = {}, fields = ["*"]) => {
  const params = {
    access_token: getToken(),
    fields,
  };

  try {
    const res = await axios.get(`${baseUrl}/items/recipe_author/`, {
      params: {
        ...params,
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const searchCollections = async (filterParams = {}, fields = ["*"]) => {
  const params = {
    access_token: getToken(),
    fields,
  };

  try {
    const res = await axios.get(`${baseUrl}/items/collections/`, {
      params: {
        ...params,
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const searchTags = async (searchString, fields) => {
  const params = {
    access_token: getToken(),
    fields,
    filter: {
      _and: [
        {
          translations: {
            name: {
              _contains: searchString,
            },
          },
        },
        {
          status: {
            _eq: "published",
          },
        },
      ],
    },
  };

  try {
    const res = await axios.get(`${baseUrl}/items/tags/`, {
      params: { ...params },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const updateAdminRetailer = async (payload = {}) => {
  try {
    const res = await axios.patch(
      `${baseUrl}/users/${payload.id}`,
      {
        retailer: payload?.retailer,
      },
      {
        params: {
          access_token: getToken(),
        },
      }
    );

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getStoresList = async (filterParams = {}) => {
  try {
    const res = await axios.get(`${baseUrl}/items/store_location`, {
      params: {
        fields: "*,exterior.*,meat_department.*, retailer.*, merchandising.*",
        access_token: getToken(),
        ...filterParams,
      },
    });
    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const updateBarcode = async (payload = {}, id) => {
  try {
    const res = await axios.patch(`${baseUrl}/items/barcode/${id}`, payload, {
      params: {
        access_token: getToken(),
        fields:
          "*,beef_product_id.*.*,owner.*,owner.translations.*,product_image.*, user_created.*",
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getUsersList = async () => {
  try {
    const res = await axios.get(`${baseUrl}/users`, {
      params: {
        access_token: getToken(),
        fields: "id,first_name,last_name",
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

const getBarcodeCountBySource = async (params) => {
  try {
    const res = await axios.get(`${baseUrl}/barcode/count-by-data-source`, {
      params: {
        access_token: getToken(),
        ...params,
      },
    });

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};
const getDuplicatedBarcodeCountBySource = async (params) => {
  try {
    const res = await axios.get(
      `${baseUrl}/barcode/count-duplicates-by-data-source`,
      {
        params: {
          access_token: getToken(),
          ...params,
        },
      }
    );

    return res.data.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

export default {
  addToJunction,
  createQrCode,
  deleteQrCode,
  deleteFromJunction,
  findInJunction,
  fetchFromVideoToolkit,
  getBarcode,
  getBarcodeCountBySource,
  getBeefProduct,
  getBrand,
  getDuplicatedBarcodeCountBySource,
  getFrom,
  getJunctionItems,
  getLanguages,
  getListBeefProductBrands,
  getListCoupons,
  getListCookingMethods,
  getListCategory,
  getListProducts,
  getListRecipe,
  getListRetailer,
  getNutrition,
  getPromotedProducts,
  getQrCode,
  getQrCodes,
  getRecipe,
  getRetailerDetails,
  getUserAndRetailer,
  getUsersList,
  getVideos,
  login,
  isAdmin,
  postBarcode,
  postLoadPageViewAnalytics,
  postQRCodeAnalytics,
  postToVideoToolkit,
  search,
  searchInTable,
  searchBeefCuts,
  searchRecipes,
  refreshToken,
  updateBeefCut,
  updateQrCode,
  updateRetailer,
  uploadFile,
  updateAdminRetailer,
  getStoresList,
  updateBarcode,
  updateVideoToolkit,
  uploadToVideoToolkit,
};
