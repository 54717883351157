import React, { useState } from "react";
import useSWR from "swr";
import { useSelector } from "react-redux";

import TableCard from "../shared/TableCard";
import periods from "./periods";

const VisitByPage = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);
  const [selectedPeriod, setSelectedPeriod] = useState(periods[1]);
  let table = {};

  const devicesChartDataPayload = {
    fields: ["id", "url", "retailer.store_url"],
    filter: {
      _and: [
        {
          date_created: {
            _gte: selectedPeriod.startDate,
          },
        },
        {
          date_created: {
            _lte: selectedPeriod.endDate,
          },
        },
        showFullData
          ? {}
          : {
              retailer: {
                _eq: retailer.id,
              },
            },
      ],
    },
    limit: -1,
  };

  const { data: devicesChartData } = useSWR({
    url: "page_views",
    ...devicesChartDataPayload,
  });

  if (devicesChartData) {
    if (showFullData) {
      // Create an object to store the count of each URL
      const urlCount = {};

      // Iterate through the devicesChartData array and count the repetitions of each URL
      devicesChartData
        ?.filter(({ retailer }) => retailer)
        .forEach(({ url, retailer: { store_url } }) => {
          const storeUrl = store_url?.startsWith("https://")
            ? store_url
            : `https://${store_url}`;

          const key = `${
            storeUrl?.endsWith("/") ? storeUrl?.slice(0, -1) : storeUrl
          }${url === "/" ? "" : url}`;

          // If the URL is not in the count object, initialize it with 1, otherwise increment the count
          urlCount[key] = {
            count: (urlCount[key]?.count || 0) + 1,
          };
        });

      // Create an array with the URL and the number of repetitions
      const resultArray = Object.entries(urlCount).map(([url, { count }]) => ({
        url,
        count,
      }));

      table = {
        title: "Visits By Page",
        description:
          "Review the number of visits to your gateway grouped by page. <strong>Click link to view page</strong>",
        items: resultArray
          .map((devicesChartDataItem) => ({
            key: devicesChartDataItem.url.replace(/^https:\/\//, ""),
            value: devicesChartDataItem.count,
            url: devicesChartDataItem.url,
          }))
          .sort((a, b) => b.value - a.value),
      };
    } else {
      // Create an object to store the count of each URL
      const urlCount = {};

      // Iterate through the devicesChartData array and count the repetitions of each URL
      devicesChartData.forEach(({ url, retailer: { store_url } }) => {
        // If the URL is not in the count object, initialize it with 1, otherwise increment the count
        urlCount[url] = {
          count: (urlCount[url]?.count || 0) + 1,
          store_url: store_url,
        };
      });

      // Create an array with the URL and the number of repetitions
      const resultArray = Object.entries(urlCount).map(
        ([url, { count, store_url }]) => ({
          url,
          count,
          store_url,
        })
      );

      table = {
        title: "Visits By Page",
        description:
          "Review the number of visits to your gateway grouped by page. <strong>Click link to view page</strong>",
        items: resultArray
          .map((devicesChartDataItem) => ({
            key: devicesChartDataItem.url,
            value: devicesChartDataItem.count,
            url: `${devicesChartDataItem.store_url}${devicesChartDataItem.url}`,
          }))
          .sort((a, b) => b.value - a.value),
      };
    }
  }

  return (
    <TableCard
      title={table.title}
      description={table.description}
      items={table.items}
      // action={(
      //     <Box
      //         sx={{
      //             mb: 2,
      //         }}
      //     >
      //         {
      //             periods.map(period => (
      //                 <Chip
      //                     label={period.label}
      //                     variant={selectedPeriod.label === period.label ? 'filled' : 'outlined'}
      //                     size='small'
      //                     sx={{
      //                         mr: 1,
      //                     }}
      //                     onClick={() => { onPeriodClick(period) }}
      //                 />
      //             ))
      //         }
      //     </Box>
      // )}
    />
  );
};

export default VisitByPage;
