import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import Chart from "./Chart";

const DonutChart = ({ showFullData }) => {
  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Barcode Duplication and source efficiency
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ my: 1 }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            Donut chart to show percentage of barcodes by source, and
            percentages duplicating between sources
          </Typography>
        </Stack>
      </Box>
      <Chart showFullData={showFullData} />
    </Box>
  );
};

export default DonutChart;
