import PropTypes, { arrayOf, number, string } from 'prop-types';

import DOMPurify from 'dompurify';
import { Box } from '@mui/system';

import styles from './index.module.scss';

function Wysiwyg({ component='div', appendAllowedTags, onlyAllowedTags, truncateAt, children='', truncateAfterLine }) {
  let allowedTagsList = ['a', 'br', 'p', 'b', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'strong', 'span', 'div'];
  let sx = {};

  // add to default allowed tags
  if (appendAllowedTags) {
    allowedTagsList = [...allowedTagsList, ...appendAllowedTags];
  }

  // only allow these tags
  if (onlyAllowedTags) {
    allowedTagsList = onlyAllowedTags;
  }

  // allow no tags if truncateAt or truncateAfterLine is set
  if (truncateAt || truncateAfterLine) {
    allowedTagsList = [];
  }

  const config = {
    ALLOWED_TAGS: allowedTagsList,
  };

  let sanitisedText = DOMPurify.sanitize(children, config);

  // truncate the text if truncateAt is set
  if (truncateAt) {
    // add an ellipsis if the text is truncated
    if (sanitisedText.length > truncateAt) {
      sanitisedText = sanitisedText.substring(0, truncateAt) + '...';
    } else {
      sanitisedText = sanitisedText.substring(0, truncateAt);
    }
  }

  // truncate the text if truncateAfterLine is set
  if (truncateAfterLine) {
    sx = {
      WebkitLineClamp: truncateAfterLine,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  }

  return (
    <Box
      className={'wysiwyg'}
      component={component}
      dangerouslySetInnerHTML={{ __html: sanitisedText }}
      sx={sx}
    />
  )
}

Wysiwyg.propTypes = {
  component: string,
  appendAllowedTags: arrayOf(string), // adds to the default allowed tags
  onlyAllowedTags: arrayOf(string), // overrides the default allowed tags
  truncateAt: number, // this will allow no tags and truncate the text at the given number of characters
  truncateAfterLine: number, // this will allow no tags and truncate the text after the given number of lines
  children: string,
}

export default Wysiwyg;