import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import TableView from '../../TableView';
import { DataGrid } from '@mui/x-data-grid';
import { Close } from '@mui/icons-material';

import './index.module.scss';
import { useSelector } from 'react-redux';
import Wysiwyg from '../Wysiwyg';
import Tabs from '../Tabs';
import TabsNavigation from '../Tabs';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Popup = ({ label, description, count, columns, junctionTableItems, mainTableItems }) => {
  const retailer = useSelector(state => state.retailer);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Chip
        avatar={count && <Avatar>{count}</Avatar>}
        label={label}
        variant={'outlined'}
        onClick={() => setOpen(true)}
        sx={{
          textTransform: 'capitalize',
        }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}
        fullWidth={true}
        scroll='paper'
      >
        <DialogTitle
          sx={{
            textTransform: 'capitalize',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              className='form-title'
              variant="h2"
            >
              {label}
            </Typography>

            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{}}
          >
            {
              !!description && 
              <Typography
                sx={{
                  mb: 2,
                }}
              >
                <Wysiwyg>{description}</Wysiwyg>
              </Typography>
            }

            <TabsNavigation
              tabs={[
                {
                  label: 'Selected',
                  children: (
                    <DataGrid
                      sx={{
                        border: 'none',
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: `4px solid ${retailer.brand_color}`,
                          mb: 1,
                          fontWeight: 'bold',
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                        },
                        "& .popup-image": {
                          borderRadius: '50%',
                          height: '2rem',
                          width: '2rem',
                        },
                      }}
                      rows={junctionTableItems}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      checkboxSelection
                    />
                  )
                },
                {
                  label: 'Not Selected',
                  children: (
                    <DataGrid
                      sx={{
                        border: 'none',
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: `4px solid ${retailer.brand_color}`,
                          mb: 1,
                          fontWeight: 'bold',
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                        },
                        "& .popup-image": {
                          borderRadius: '50%',
                          height: '2rem',
                          width: '2rem',
                        },
                      }}
                      rows={mainTableItems}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 50 },
                        },
                      }}
                      pageSizeOptions={[20, 50]}
                      checkboxSelection
                    />
                  )
                },
              ]}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            color='error'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='success'
          >
            Update Relationships
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Popup