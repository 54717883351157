import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';

import useSWR from 'swr';
import { videoToolkitFetcher } from '../../common/utils/fetcher';
import Videos from '../../components/shared/Videos';

function SmartVideoProjectView() {
  useRefreshToken();

  const params = useParams();

  const id = params.id;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const { data, isLoading } = useSWR({
    url: `/project/${id}`,
  }, videoToolkitFetcher);

  let sidebarFields = [];
  let fields = [];

  if (data) {
    fields = [
      {
        key: 'Name',
        value: data.name,
      },
      {
        key: 'Video',
        value: data.renderURL,
        type: 'video',
        children: (
          <Videos
            videos={[
              {
                id: data.id,
                sourceUrl: `${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${data.base_video}`,
                videoType: 'aws',
              }
            ]}
          />
        )
      },
    ];

    sidebarFields = [
      {
        key: 'Aspect Ratio',
        value: `${data.width} x ${data.height}`,
      },
      {
        key: 'Output',
        value: `${data.format} ${data.quality || ''}`,
      },
    ];
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        breadcrumbsLinks={[
          {
            url: '/smart-videos',
            content: 'Smart Videos',
          },
          {
            url: `/smart-videos/projects`,
            content: 'Smart Video Assets',
          }
        ]}
        editUrl={'/smart-videos/projects/edit/' + id}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default SmartVideoProjectView