import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Upload from "@mui/icons-material/Upload";
import Stack from "@mui/material/Stack";

import API from "../../api";
import { Typography } from "@mui/material";
import constants from "../../common/utils/constants";

import { CloseOutlined } from "@mui/icons-material";
import { useState } from "react";

import styles from "./index.module.scss";
import api from "../../api";
import Videos from "../shared/Videos";

function VideoUpload({
  field,
  label,
  description,
  value,
  handleInputChange,
  baseUrl,
}) {
  console.log('value', value);
  
  const apiURL = baseUrl || process.env.REACT_APP_API_URL;
  const [imageUrl, setImageUrl] = useState(
    value
      ? value?.includes('http')
        ? value
        : `${apiURL}/assets/${value}`
      : null
  );

  const uploadFile = async (file) => {
    const formData = new FormData();

    formData.append("file", file, file.name);

    const fileUploadResponse = await api.uploadToVideoToolkit('files', formData);

    setImageUrl(
      `${apiURL}/assets/${fileUploadResponse.id}`
    );

    handleInputChange(field, fileUploadResponse.id);
  };

  return (
    <Stack>
      <Typography className="form-label" variant="label">
        {label}
      </Typography>

      {imageUrl && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            background: "#000",
          }}
        >
          <Videos
            mode="dark"
            videos={[
              {
                id: imageUrl,
                sourceUrl: imageUrl,
                videoType: 'aws',
              }
            ]}
          />
        </Box>
      )}

      <Stack
        direction={"row"}
        alignItems={"stretch"}
        justifyContent={"stretch"}
      >
        <Input
          id={`input-my-brand-${field}`}
          accept="image/*"
          onChange={(event) => {
            uploadFile(event.target.files[0]);
          }}
          type="file"
          inputProps={{
            className: "form-input",
          }}
          sx={{
            color: "#262626 !important",
            py: 0,
            width: "100%",
            overflow: "hidden",
          }}
          placeholder=""
          variant="standard"
        />
        
        <Box>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "100%", background: "#000" }}
          >
            <label className="form-icon" htmlFor={`input-my-brand-${field}`}>
              <IconButton
                sx={{ color: "#fff" }}
                aria-label="upload picture"
                component="span"
              >
                <Upload />
              </IconButton>
            </label>
          </Stack>
        </Box>
      </Stack>

      <Typography className="form-input-description" variant="p">
        {description}
      </Typography>
    </Stack>
  );
}

export default VideoUpload;
