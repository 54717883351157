import useSWR from 'swr';

export default (junctionTable, params, loading) => {
    const { data: hits } = useSWR(!loading ? {
        url: `/${junctionTable}`,
        ...params,
    } : null);

    const [countRecord] = hits || [];

    return countRecord?.count;
}

