import { useState } from "react";
import { useSelector } from "react-redux";

import useSWR, { useSWRConfig} from "swr";

import { Box, Button, ButtonGroup, Stack } from "@mui/material"

import api from "../../../api";

const AddToLibrary = ({ junctionTable, filter, addPayload, removePayload }) => {
    const { mutate } = useSWRConfig();

    const retailer = useSelector(state => state.retailer);
    
    const [loading, setLoading] = useState(false);

    const { data: hits } = useSWR(!loading && junctionTable ? {
        url: `/${junctionTable}`,
        filter,
    } : null);

    const actionOptions = [
        ...(
            !!!hits || !!hits?.length ? [] : [
                {
                    key: 'Add to Library',
                    content: 'Add to Library ',
                    color: 'success',
                    onClick: () => {
                        addToLibrary(junctionTable, addPayload);
                    },
                },
            ]
        ),
        ...(
            !!!hits || !!!hits?.length ? [] : [
                {
                    key: 'Remove from Library',
                    content: 'Remove from Library ',
                    color: 'error',
                    onClick: () => {
                        removeFromLibrary(junctionTable, removePayload);
                    },
                },
            ]
        ),
    ];

    const addToLibrary = async (junctionTable, payload) => {
        setLoading(true);
        await api.addToJunction(junctionTable, payload);
        setLoading(false);
        mutate();
    }

    const removeFromLibrary = async (junctionTable, payload) => {
        setLoading(true);
        await api.deleteFromJunction(junctionTable, payload);
        setLoading(false);
        mutate();
    }

    return (
        <Box>
            <Stack
                spacing={0.5}
            >
                <ButtonGroup>
                    {/* <Button
                        variant="contained"
                        color="success"
                        sx={{
                            '&:hover': {
                                color: '#3B8234',
                            },
                        }}
                    >
                        <Check />
                    </Button> */}
                    {
                        actionOptions.map((option) => (
                            <Button
                                key={option.key}
                                color={option.color}
                                variant="outlined"
                                disabled={option.disabled}
                                onClick={option.onClick}
                            >
                                {option.content}
                            </Button>
                        ))
                    }
                </ButtonGroup>
            </Stack>
        </Box>
    )
}

export default AddToLibrary