import { useSelector } from 'react-redux';

import { getRecipeVideoOverride } from '../../../utils/overrides';
import { getTranslation } from '../../../utils/translate';
import { Box, Grid, Stack } from '@mui/material';
import constants from '../../../utils/constants';

function RecipeVideos({ recipe }) {
  const language = {
    code: 'en-US',
  };
  
  const retailer = useSelector(state => state.retailer);

  const videoOverrides = getRecipeVideoOverride(recipe, retailer?.id, language.code);

  const translatedRecipe = getTranslation(
    recipe,
    {
      key: 'languages_code',
      code: language.code
    },
    'layout_translations'
  );

  const defaultVideoList = [
    ...(
      translatedRecipe.vimeo?.map(vimeo => {
        const embedId = vimeo.vimeo_id.replace('https://vimeo.com/', '');

        return {
          embedId,
          embedUrl: `${constants.VIMEO_PLAYER_URL}/${embedId}`,
          videoType: 'vimeo',
        }
      }) || []
    ),
    ...(
      translatedRecipe.youtube?.map(youtube => ({
        embedId: youtube.youtube_id,
        embedUrl: `${constants.YOUTUBE_PLAYER_URL}/${youtube.youtube_id}`,
        videoType: 'youtube',
      })) || []
    )
  ];

  let overrideVideoList = [];

  for (const videoOverride of videoOverrides) {
    console.log('videoOverride: ', videoOverride);

    const translatedVideoOverride = getTranslation(
      videoOverride,
      {
        key: 'languages_code',
        language: language.code
      }
    );

    overrideVideoList = [
      ...(overrideVideoList),
      ...(
        translatedVideoOverride.vimeo?.map(vimeo => {
          const embedId = vimeo.vimeo_id.replace('https://vimeo.com/', '');

          return {
            embedId,
            embedUrl: `${constants.VIMEO_PLAYER_URL}/${embedId}`,
            videoType: 'vimeo',
          }
        }) || []
      ),
      ...(
        translatedVideoOverride.youtube?.map(youtube => ({
          embedId: youtube.youtube_id,
          embedUrl: `${constants.YOUTUBE_PLAYER_URL}/${youtube.youtube_id}`,
          videoType: 'youtube',
        })) || []
      )
    ];
  }

  // ignore recipe videos if overrides exist
  const videoList = overrideVideoList.length ? overrideVideoList : defaultVideoList;

  if (!videoList.length) return null;

  return (
      <Stack
        sx={{
          my: 3
        }}
      >
        <Grid container
          spacing={2}
        >
          {
            videoList.map(item => (
              <Grid item
                xs={12}
                md={6}
                lg={videoList.length > 1 ? 6 : 12}
                key={item.embedId}
              >
                <Box
                  className="recipe_video"
                  sx={{
                    width: "100%",
                    background: "#222",
                    alignContent: "center"
                  }}
                >
                  <iframe
                    src={item.embedUrl}
                    className="video-list_img"
                    title="Recipe Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
              </Grid>
            ))
          }
        </Grid>
      </Stack>
  )
}

export default RecipeVideos