import React from "react";
import { Input, MenuItem, Select, Stack, Typography } from "@mui/material";

import InputUpload from "../InputUpload";
import TranslationInputField from "../TranslationInputField";

const inputs = (field) => {
  const {
    handleInputChange,
    label,
    value,
    name,
    type,
    options,
    config,
    error,
  } = field;

  let component = <></>;

  switch (type) {
    case "dropdown":
      component = (
        <Dropdown
          inputProps={{
            className: "form-input",
          }}
          sx={{
            color: "#262626 !important",
            py: 0,
            width: "100%",
          }}
          error={error}
          label={label}
          value={value}
          handleInputChange={handleInputChange}
          placeholder=""
          variant="standard"
          options={options}
        />
      );
      break;

    case "image":
      component = (
        <InputUpload
          field={name}
          label={label}
          value={value}
          handleInputChange={handleInputChange}
          placeholder=""
          variant="standard"
          options={options}
          config={config}
          removeImageOnDelete={true}
        />
      );
      break;

    case "number":
      component = (
        <Stack>
          <Typography className="form-label" variant="label">
            {label}
          </Typography>

          <Input
            inputProps={{
              className: "form-input",
            }}
            sx={{
              color: "#262626 !important",
              py: 0,
              width: "100%",
            }}
            type="number"
            value={value}
            onChange={(event) => {
              handleInputChange(name, event.target.value);
            }}
            placeholder=""
            variant="standard"
          />
        </Stack>
      );
      break;

    default:
      component = (
        <>
          <TranslationInputField
            name={name}
            field={name}
            label={label}
            value={value}
            type={type}
            config={config}
            handleInputChange={handleInputChange}
          />
        </>
      );
  }

  return component;
};

export default inputs;

const Dropdown = ({ label, error, value, options, handleInputChange }) => {
  return (
    <Stack>
      <Typography className="form-label" variant="label">
        {label}
      </Typography>

      <Select
        inputProps={{
          className: "form-input",
        }}
        sx={{
          borderRadius: 0,
          "& .MuiSelect-select": {
            borderRadius: 0,
          },
        }}
        value={value?.value}
        onChange={(event) => {
          handleInputChange(event.target.value);
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            sx={{
              textTransform: "uppercase",
            }}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
      </Select>

      <Typography variant="p" color="red">
        {error}
      </Typography>
    </Stack>
  );
};
