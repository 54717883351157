import React, { useState } from "react";
import useSWR from "swr";
import { useSelector } from "react-redux";
import { Box, Chip } from "@mui/material";

import periods from "./periods";
import ChartContainer from "../shared/ChartContainer";

const QRScans = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);

  const [selectedPeriod, setSelectedPeriod] = useState(periods[1]);
  let chart = {};

  const qrCodeChartsDataPayload = {
    aggregate: {
      count: "*",
    },
    filter: {
      _and: [
        {
          date_created: {
            _gte: selectedPeriod.startDate,
          },
        },
        {
          date_created: {
            _lte: selectedPeriod.endDate,
          },
        },
        ...(showFullData
          ? []
          : [
              {
                qr_code_uuid: {
                  retailer: {
                    _eq: retailer.id,
                  },
                },
              },
            ]),
      ],
    },
    groupBy: selectedPeriod.groupBy,
    limit: -1,
  };

  const { data: qrCodeChartsData } = useSWR({
    url: "qr_code_analytics",
    ...qrCodeChartsDataPayload,
  });

  if (qrCodeChartsData) {
    const labels = selectedPeriod.chartLabelsDateComponents;
    const data = labels.map((label) => {
      const {
        date_created_year,
        date_created_month,
        date_created_day,
        date_created_hour,
      } = label;

      const dataItem = qrCodeChartsData.find((qrCodeChartsDataItem) => {
        return (
          qrCodeChartsDataItem.date_created_year == date_created_year &&
          qrCodeChartsDataItem.date_created_month == date_created_month &&
          qrCodeChartsDataItem.date_created_day == date_created_day &&
          qrCodeChartsDataItem.date_created_hour == date_created_hour
        );
      });

      return dataItem?.count || 0;
    });

    const datasets = [
      {
        label: "QR Code Scans",
        data,
        backgroundColor: retailer?.brand_color,
      },
    ];

    chart = {
      labels: selectedPeriod.chartLabels,
      datasets,
    };
  }

  const onPeriodClick = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <ChartContainer
      title="QR Scans"
      description="Review the number of times your QR codes have been scanned."
      labels={chart.labels}
      datasets={chart.datasets}
      footerAction={
        <Box
          sx={{
            mb: 2,
          }}
        >
          {periods.map((period) => (
            <Chip
              label={period.label}
              variant={
                selectedPeriod.label === period.label ? "filled" : "outlined"
              }
              size="small"
              sx={{
                mr: 1,
              }}
              onClick={() => {
                onPeriodClick(period);
              }}
            />
          ))}
        </Box>
      }
    />
  );
};

export default QRScans;
