import { Box, Input, Stack, Typography } from "@mui/material"

function ColourInput({ field, label, description, value, handleInputChange, type = 'text' }) {
    const onChange = (field, value) => {
        handleInputChange(field, value)
    };
    
    return (
        <Stack>
            <Typography
                className="form-label"
                variant='label'
            >
                {label}
            </Typography>

            <Stack
                direction={'row'}
            >
                <Input
                    inputProps={{
                        className: 'form-input',
                        sx: {
                            borderTopRightRadius: '0',
                            borderBottomRightRadius: '0',
                        }
                    }}
                    sx={{
                        color: '#262626 !important',
                        py: 0,
                        width: '100%'
                    }}
                    type="type"
                    value={value}
                    onChange={(event) => { 
                        let hex = event.target.value;

                        // check if hex has a hash
                        if (hex[0] !== '#') {
                            hex = `#${hex}`
                        }

                        onChange(field, hex);
                    }}
                    placeholder=""
                    variant="standard"
                />

                <Box
                    sx={{
                        width: '20%',
                        border: '1px solid #000',
                        borderLeft: 'none',
                    }}
                >
                    <Input
                        inputProps={{
                            className: 'form-input p-0'
                        }}
                        sx={{
                            color: '#262626 !important',
                            py: 0,
                            width: '100%',
                            height: '100%',
                        }}
                        type={type}
                        value={value}
                        onChange={(event) => { onChange(field, event.target.value) }}
                        placeholder=""
                        variant="standard"
                    />
                </Box>
            </Stack>

            <Typography
                className="form-input-description"
                variant='p'
            >
                {description}
            </Typography>
        </Stack>
    )
}

export default ColourInput