const userReducer = (state = {}, action) => {
    switch(action.type) {
        case 'user/update':
            return {
                ...state,
                ...action.payload
            };

        case 'user/logout':
            return {
                ...state,
                ...{
                    access_token: '',
                    refresh_token: '',
                    first_name: 'Welcome',
                    last_name: '',
                }
            };
        default:
            return state;
    }
};

export default userReducer;