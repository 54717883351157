import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import RecipeSummary from '../../components/RecipeSummary';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import { updateRetailer } from '../../actions/Retailer';
import { updateBeefCuts } from '../../actions/Data';
import { getTranslation } from '../../common/utils/translate';
import LinkToCreate from '../../components/LinkToCreate';
import { Link } from 'react-router-dom';

const FeaturedProducts = () => {
  const retailer = useSelector(state => state.retailer);
  const beefCuts = useSelector(state => state.beefCuts.data);
  const beefCutsStore = useSelector(state => state.beefCuts);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const summaryValues = [
    {
        label: 'Total',
        value: beefCutsStore.meta.total_count
    },
    {
        label: 'New',
        value: 0,
        // value: beefCuts?.filter(beefCuts => {
        //   return dayjs(beefCuts.date_created).format('YYYY-MM-DDTHH:mm:ssZ[Z]') >= dayjs().subtract(2, 'months').format('YYYY-MM-DDTHH:mm:ssZ[Z]')
        // })?.length
    },
    {
        label: 'Collected',
        value: retailer?.beef_products?.filter(beefProduct => beefProduct.beef_product_id).length // filter out null values
    },
  ];

  const makeGetBeefCutsRequest  = async () => {
    return await API.getListProducts({
      filter: {
        '_and': ([])
      }
    });
  };

  const addBeefCuteToLibray = async (beefCutId) => {
    try {
      setLoading(true);

      // get retailer details
      // TODO consider using retailer end point with 
      const userResponse = await API.getUserAndRetailer();

      // save retailer state
      let retailer = userResponse.retailer;
      dispatch(updateRetailer(retailer));

      // get list of recipes
      const payload = {
        beef_products: retailer.beef_products
      }

      // append
      payload.beef_products.push({
        beef_product_id: beefCutId
      });

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);
      dispatch(updateRetailer(retailer));

      // get new list of beef cuts
      // TODO consider refactoring this function
      await fetchBeefCuts();
    } catch(error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const removeBeefCutFromLibrary = async (beefCutId) => {
    try {
      setLoading(true);

      // get retailer details
      // TODO consider using retailer end point with 
      const userResponse = await API.getUserAndRetailer();

      // save retailer state
      let retailer = userResponse.retailer;
      dispatch(updateRetailer(retailer));

      // get list of recipes
      const payload = {
        beef_products: retailer.beef_products.filter(beefProduct => beefProduct.beef_product_id !== beefCutId)
      }

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);
      dispatch(updateRetailer(retailer));

      // get new list of beef cuts
      // TODO consider refactoring this function
      await fetchBeefCuts();
    } catch(error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const makeRetailerPatchRequest = async (payload) => {
    return await API.updateRetailer(payload);
  }

  useEffect(() => {
    fetchBeefCuts();
  }, []);

  const fetchBeefCuts = async () => {
    try {
      const listBeefCuts = await makeGetBeefCutsRequest();
      console.log('listBeefCuts: ', listBeefCuts);

      dispatch(
        updateBeefCuts(listBeefCuts)
      );
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Box sx={{ flexGrow: 1, my: 3 }}>
      <Typography className='form-title' variant="h2">Beef Cuts</Typography>

      <RecipeSummary summaryValues={summaryValues} />

      <Grid sx={{ mt: 4 }} container spacing={2}>
        <Grid item xs={6} md={3} lg={2}>
          <LinkToCreate />
        </Grid>
        {beefCuts?.map((beefCut) => (
          <Grid key={beefCut.id} item xs={6} md={3} lg={2}>
            <Link to={`/beef-cuts/${beefCut.id}`}>
              <ImageCard
                item={{
                  title: getTranslation(beefCut, { key: 'languages_id', code: 'en-US' }, 'translations')?.name || ' ',
                  url: beefCut.image ? `https://staging.beef.tanglemedia.net/assets/${beefCut.image}?key=system-medium-cover` : ''
                }} 
                footerAction={ !!beefCut.retailers.filter(retailerJunction => retailerJunction.retailer_id === retailer.id).length ?
                  <IconButton
                    variant="contained"
                    onClick={() => { removeBeefCutFromLibrary(beefCut.id) }}
                    sx={{
                      background: '#3B8234',
                      color: 'white',
                      '&:hover': {
                        color: '#3B8234',
                      },
                    }}
                  >
                    <CheckIcon/>
                  </IconButton>:
                  <IconButton
                    onClick={() => { addBeefCuteToLibray(beefCut.id) }}
                    variant="contained"
                    sx={{
                      background: '#932525',
                      color: 'white',
                      '&:hover': {
                        color: '#932525',
                      },
                    }}
                  >
                    <RemoveIcon/>
                  </IconButton>
                }
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default FeaturedProducts