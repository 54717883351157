import { Link } from "react-router-dom"
import ImageCard from "../ImageCard"
import AddIconImage from '../../shared/svg/plus.svg';

function LinkToCreate(props) {
    const { url = AddIconImage, title = `Create` } = props;

    return (
        <Link to={'create'}>
            <ImageCard
                item={{
                    title,
                    url
                }}
            />
        </Link>
    )
}

export default LinkToCreate