export const buildTranslationPayload = (translations, translationKey = 'languages_code') => {
    console.log('translations: ', translations);
    console.log('translationKey: ', translationKey);
    // get english translation
    // get french translation
    const englishTranslationItem = translations.find(translation => translation[translationKey] === 'en-US');
    const frenchTranslationItem = translations.find(translation => translation[translationKey] === 'fr-FR');

    console.log('englishTranslationItem: ', englishTranslationItem);
    console.log('frenchTranslationItem: ', frenchTranslationItem);

    return {
        translations: {
            create: [
                ...(englishTranslationItem?.id ? [] : [
                    englishTranslationItem
                ]),
                ...(frenchTranslationItem?.id ? [] : [
                    frenchTranslationItem
                ]),
            ],
            update: [
                ...(englishTranslationItem?.id ? [
                    englishTranslationItem
                ] : []),
                ...(frenchTranslationItem?.id ? [
                    frenchTranslationItem
                ] : []),
            ],
            delete: [],
        }
    }
}

export const buildRelationshipPayload = (currentPayload = {}, crudOperation, ids) => {
    console.log('currentPayload: ', currentPayload);
    console.log('crudOperation: ', crudOperation);
    console.log('ids: ', ids);

    const updatedPayload = {
        create: [],
        update: [],
        delete: [],
        ...currentPayload
    };

    if (crudOperation && ids.length) {
        updatedPayload[crudOperation] = [
            ...(updatedPayload[crudOperation]),
            ...ids
        ];
    }

    console.log('updatedPayload: ', updatedPayload);

    return updatedPayload;
}

export default buildTranslationPayload;