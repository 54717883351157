import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const AlertBox = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    limit={4}
  />
);

export default AlertBox;
