import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';

import useSWR from 'swr';
import { videoToolkitFetcher } from '../../common/utils/fetcher';
import { useCheckVideoToolsEnabled } from '../../common/hooks/useCheckVideoToolsEnabled';
import FullPageMessage from '../../components/shared/FullPageMessage';
import RelationshipList from '../../components/RelationshipList';

function SmartVideoBatchView() {
  useRefreshToken();
  const videoToolsEnabled = useCheckVideoToolsEnabled();

  const params = useParams();

  const id = params.id;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [sidebarFields, setSidebarFields] = useState([]);

  const { data, isLoading } = useSWR({
    url: `/queue/${id}`,
    fields: '*,project.project_ID,brand.brand_id',
  }, videoToolkitFetcher);

  let fields = [];

  let projectsOnCurrentBatch = [];
  let brandsOnCurrentBatch = [];
  let batchName = '';

  if (data) {
    batchName = data.name;

    projectsOnCurrentBatch = data?.project?.map(item => ({
      id: item.project_ID,
      url: `project/${item.project_ID}`,
      responseHandler: response => ({
        url: `project/${response.ID}`,
        title: response.name,
      }),
    })) || [];

    brandsOnCurrentBatch = data?.brand?.map(item => ({
      id: item.brand_id,
      url: `brand/${item.brand_id}`,
      responseHandler: response => ({
        url: `brand/${response.id}`,
        title: response.name,
      }),
    })) || [];

    fields = [
      {
        key: 'Name',
        value: data.name,
      },
      {
        key: 'Projects',
        children: (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              Projects
            </Typography>
            <RelationshipList
              items={projectsOnCurrentBatch}
            />
          </Box>
        )
      },
      {
        key: 'Brands',
        children: (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              Brands
            </Typography>
            <RelationshipList
              items={brandsOnCurrentBatch}
            />
          </Box>
        )
      },
    ];
  }

  if (!videoToolsEnabled) {
    return <FullPageMessage />
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        breadcrumbsLinks={[
          {
            url: '/smart-videos',
            content: 'Smart Videos',
          },
          {
            url: `/smart-videos/batches`,
            content: 'Smart Video Batches',
          }
        ]}
        editUrl={'/smart-videos/batches/edit/' + id}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default SmartVideoBatchView