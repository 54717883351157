import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

import useSWR from 'swr';
import { videoToolkitFetcher } from '../../common/utils/fetcher';
import RelationshipList from '../../components/RelationshipList';
import { Chip, Stack, Typography } from '@mui/material';
import { PlayCircle } from '@mui/icons-material';

const Actions = ({ url }) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
    >
      {
        !!url &&
        <Chip
          icon={<PlayCircle />}
          label="Play Video"
          color="success"
          variant="outlined"
          component="a"
          target="_blank"
          href={url}
          sx={{
            cursor: 'pointer',
          }}
        />
      }
    </Stack>
  )
}
function SmartVideoBrandView() {
  useRefreshToken();

  const params = useParams();
  const retailer = useSelector(state => state.retailer);

  const id = params.id;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const { data, isLoading } = useSWR({
    url: `/brand/${id}`,
    fields: '*,renders.*',
    deep: {
      renders: {
        _sort: '-id',
        _limit: 10,
      },
    }
  }, videoToolkitFetcher);

  let fields = [];
  let sidebarFields = [];

  if (data) {
    const renders = data?.renders?.map(item => ({
      id: item.id,
      url: `Batch_Renders/${item.id}`,
      responseHandler: response => ({
        url: `Batch_Renders/${response.id}`,
        title: response.project,
        actions: (
          !!response.renderURL &&
          <Actions
            url={response.renderURL}
          />
        )
      }),
    })) || [];

    fields = [
      {
        key: 'Name',
        value: data.name,
      },
      {
        key: 'Primary Brand Color',
        value: data.color,
      },
      {
        key: 'Secondary Brand Color',
        value: data.color2,
      },
      {
        key: 'Revent Videos',
        children: (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              Videos
            </Typography>
            <RelationshipList
              items={renders}
            />
          </Box>
        )
      },
    ];

    sidebarFields = [
      {
        key: 'Logo',
        children: (
          <ImageContainer
            url={`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${data.logo}`}
            alt={data.name}
            sx={{
              height: '20rem',
            }}
          />
        )
      },
      {
        key: 'Closing Slide',
        children: (
          <ImageContainer
            url={`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${data.closing_slide}`}
            alt={data.name}
            sx={{
              height: '20rem',
            }}
          />
        )
      },
    ];
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        breadcrumbsLinks={[
          {
            url: '/smart-videos',
            content: 'Smart Videos Menus',
          },
          {
            url: `/smart-videos/brands`,
            content: 'Smart Video Brands',
          }
        ]}
        editUrl={'/smart-videos/brands/edit/' + id}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default SmartVideoBrandView