import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import RecipeSummary from "../../components/RecipeSummary";

import SearchNav from "../../components/SearchNav";

import AlertBox from "../../components/AlertBox";
import DeleteDialog from "../../components/DeleteDialog";
import { ButtonGroup, Button, IconButton, Stack } from "@mui/material";
import { arrayOf, element, func, node, oneOf, shape, string } from "prop-types";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import { Check, Edit, Remove } from "@mui/icons-material";
import DropdownButton from "../../components/shared/DropdownButton";
import AddToLibrary from "../../components/shared/AddToLibrary";
import Authenticated from "../Authenticated";
import { Fragment } from "react";
import Popup from "../../components/shared/Popup";
import TagsRelationshipContainer from "../../components/pages/Cuisine/TagsContainer";
import { Link } from "react-router-dom";

const ViewPage = ({
  children,
  title,
  summaries = [],
  instructionMessage,
  breadcrumbsLinks = [],
  actionOptions = [],
  onDelete,
  addToLibrary = {},
  relationships = [],
  editUrl,
}) => {
  return (
    <Authenticated>
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          spacing={1}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography className="form-title" variant="h2">
              {title}
            </Typography>

            <Breadcrumbs home links={breadcrumbsLinks} />
          </Box>

          <Box>
            <AddToLibrary
              junctionTable={addToLibrary.junctionTable}
              filter={addToLibrary.filter}
              addPayload={addToLibrary.addPayload}
              removePayload={addToLibrary.removePayload}
            />

            {
              !!editUrl &&
              <Link
                to={editUrl}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outlined"
                >
                  <Edit />
                </Button>
              </Link>
            }

            {!!onDelete && <DeleteDialog />}
          </Box>
        </Stack>

        {relationships?.length > 0 && (
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              my: 2,
            }}
          >
            {relationships.map((relationship) => (
              <Fragment key={relationship.name}>
                <TagsRelationshipContainer
                  name={relationship.name}
                  description={relationship.description}
                  count={relationship.count}
                  columns={relationship.columns}
                  junctionTable={relationship.junctionTable}
                  mainTable={relationship.mainTable}
                  mainTablePayload={relationship.mainTablePayload}
                  mainTableResponseHandler={
                    relationship.mainTableResponseHandler
                  }
                  payload={relationship.payload}
                  requestHandler={relationship.requestHandler}
                  responseHandler={relationship.responseHandler}
                />
              </Fragment>
            ))}
          </Stack>
        )}

        {instructionMessage && <AlertBox message={instructionMessage} />}

        <RecipeSummary summaryValues={summaries} />

        {children}
      </Box>
    </Authenticated>
  );
};

ViewPage.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  instructionMessage: string,
  breadcrumbsLinks: arrayOf(
    shape({
      url: string,
      content: oneOf([string, element]).isRequired,
    })
  ),
  actionOptions: arrayOf(
    shape({
      key: string.isRequired,
      content: oneOf([string, element]).isRequired,
      onClick: func.isRequired,
    })
  ),
  onDelete: func,
};

export default ViewPage;
