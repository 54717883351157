import { Box, Button, Select, Typography } from "@mui/material";
import InputUpload from "../../components/InputUpload";
import RelationshipDialog from "../../components/RelationshipDialog";
import RelationshipList from "../../components/RelationshipList";
import StatusInput from "../../components/StatusInput";
import TranslationInputField from "../../components/TranslationInputField";
import ColourInput from "../../components/shared/ColourInput";
import Dropdown from "../../components/shared/Dropdown";
import { options } from "../../components/shared/ChartContainer";
import AddRelationships from "../../components/shared/AddRelationships";
import FormInput from "../../components/shared/FormInput";
import VideoUpload from "../../components/VideoUpload";

export const chooseInputComponent = (field) => {
    const { handleInputChange, label, description, value, name, type, options, config } = field;

    let component = <></>;

    switch(type) {
        case 'm2o':
            component = (
                <Box
                    sx={{
                        margin: '16px 0'
                    }}
                >
                    <Typography
                        className="form-label"
                        variant='label'
                    >
                        {label}
                    </Typography>
                    <RelationshipList
                        items={config.items}
                    />
                    <RelationshipDialog
                        title={config.title}
                        rows={config.rows}
                        headCells={config.headCells}
                        onSelect={handleInputChange}
                    />
                </ Box>
            )
            break;
        case 'm2m':
            component = (
                <Box
                    sx={{
                        margin: '16px 0'
                    }}
                >
                    <Typography
                        className="form-label"
                        component='label'
                    >
                        {label}
                    </Typography>

                    <Typography
                        className="form-input-description"
                        component="p"
                    >
                        {description}
                    </Typography>

                    <RelationshipList
                        items={config.items}
                    />
                    
                    <AddRelationships
                        field={name}
                        label={label}
                        description={description}
                        value={value}
                        onClose={handleInputChange}
                        selectedItems={config.items}
                        title={config.title}
                        url={config.url}
                        payload={config.payload}
                        columns={config.columns}
                        idField={config.idField}
                    />
                </ Box>
            )
            break;
        case 'image':
            component = (
                <InputUpload
                    field={name}
                    label={label}
                    description={description}
                    value={value}
                    handleInputChange={handleInputChange}
                    placeholder=""
                    variant="standard"
                    options={options}
                    config={config}
                    uploadHandler={(field, uploadedFileId) => { handleInputChange(uploadedFileId, field) }}
                />
            )
            break;
        case 'video':
            component = (
                <VideoUpload
                    key={value}
                    field={name}
                    label={label}
                    description={description}
                    value={value}
                    handleInputChange={handleInputChange}
                    placeholder=""
                    variant="standard"
                    options={options}
                    config={config}
                    baseUrl={process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}
                    uploadHandler={(field, uploadedFileId) => { handleInputChange(uploadedFileId, field) }}
                />
            )
            break;
        case 'font':
            component = (
                <Dropdown
                    inputProps={{
                        className: 'form-input'
                    }}
                    sx={{
                        color: '#262626 !important',
                        py: 0,
                        width: '100%'
                    }}
                    label={label}
                    field={name}
                    description={description}
                    value={value}
                    handleInputChange={handleInputChange}
                    placeholder=""
                    variant="standard"
                    options={options}
                />
            )
            break;
        case 'color':
            component = (
                <ColourInput
                    inputProps={{
                        className: 'form-input'
                    }}
                    sx={{
                        color: '#262626 !important',
                        py: 0,
                        width: '100%'
                    }}
                    label={label}
                    field={name}
                    description={description}
                    value={value}
                    handleInputChange={handleInputChange}
                    placeholder=""
                    variant="standard"
                    type="color"
                />
            )
            break;
        case 'text':
            component = (
                <FormInput
                    inputProps={{
                        className: 'form-input'
                    }}
                    sx={{
                        color: '#262626 !important',
                        py: 0,
                        width: '100%'
                    }}
                    label={label}
                    field={name}
                    description={description}
                    value={value}
                    handleInputChange={(field, name) => handleInputChange(field, name)}
                    placeholder=""
                    variant="standard"
                />
            )
            break;
        case 'status':
            component = (
                <StatusInput
                    name={name}
                    label={label}
                    description={description}
                    type={type}
                    config={config}
                    handleInputChange={handleInputChange}
                />
            )
            break;
        default:
            component = (
                <>
                    <TranslationInputField
                        name={name}
                        field={name}
                        label={label}
                        value={value}
                        description={description}
                        type={type}
                        config={config}
                        handleInputChange={handleInputChange}
                    />
                </>
            );
    }

    return component;
}