import React from 'react'

import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Typography from '@mui/material/Typography';

import './index.scss';


function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to],
  );

  return (
    <li className='drawer-sidebar-link'>
      <ListItem button component={renderLink}>
        <ListItemText disableTypography={true} primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default function SideBar() {

    const sideBarLinks = [
        {
            label: 'Dashboard',
            to: '/dashboard',
        },
        {
            label: 'Analytics',
            to: '/analytics',
        },
        {
            label: 'Content Library',
            to: '/recipes-menu',
        },
        {
            label: 'Collections & Cuisine',
            to: '/collections-menu',
        },
        {
            label: 'Smart Videos',
            to: '/smart-videos',
        },
        {
            label: 'Barcodes & QR Codes',
            to: '/codes-menu',
        },
        {
            label: 'Canada Beef Advantage',
            to: '/cba-menu',
        },
        {
            label: 'Brand & Settings',
            to: '/my-brand',
        },
        // {
        //   label: 'Recipe Book',
        //   to: 'recipe-book',
        // },
        // {
        //   label: 'Beef Cuts',
        //   to: 'beef-cuts',
        // },
        // {
        //   label: 'Cooking Methods',
        //   to: 'cooking-methods',
        // },
        // {
        //   label: 'Collections',
        //   to: 'collections',
        // },
        // {
        //   label: 'Authors',
        //   to: 'authors',
        // },
        // {
        //   label: 'Brand Settings',
        //   to: 'my-brand',
        // },
    ];
  return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
          <List>
              {sideBarLinks.map(link => (
                <ListItemLink sx={{ mb: 2 }} key={link.label} to={link.to} primary={link.label} />
              ))}
          </List>
      </Box>
  );
}