import ListPage from "../../layouts/ListPage";
import ReportsWithFilter from "../../components/Analytics/ReportsWithFilter";

function Analytics() {
  return (
    <ListPage
      title="Analytics"
      description="Not sure about the difference between grilling and marinating steaks. Want to know how to make a delicious stir-fry or how to make the perfect oven roast? Our cooking know-how pages provide you with all the information you need! View your analytics here."
      breadcrumbsLinks={[
        {
          url: "/",
          content: "Content Library",
        },
      ]}
    >
      <ReportsWithFilter />
    </ListPage>
  );
}

export default Analytics;
