import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import api from "../../../api";

import { getTranslation } from "../../../utils/translate";

import Loader from "../Loader";
import notify from "../Notification/helper";

import SearchNav from "../SearchNav";

import BeefCuts from "../BeefCuts";
import NoResultsFound from "../NoResultsFound";
import ListView from "../ListView";

import './index.scss';
import { arrayOf, bool, func, oneOf, string } from "prop-types";
import { getAuthorDisplayName } from "../../../utils/author";
import constants from "../../../utils/constants";

function Search({
    handleSearchTriggered = () => {},
    handleSearchCleared = () => {},
    handleSearchComplete = () => {},
    storageKey = 'searchQuery',
    searchOnly,
    compact=false,
  }) {

  const language = { code: 'en-US' };
  const retailer = useSelector(state => state.retailer);

  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const [collections, setCollections] = useState([]);
  const [beefCuts, setBeefCuts] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [articles, setArticles] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [recipeAuthors, setRecipeAuthors] = useState([]);
  const [searchQuery, setSearchQuery] = useState(() => window.localStorage.getItem(storageKey) || '');

  useEffect(() => {
    window.sessionStorage.setItem(storageKey, searchQuery);
  }, [searchQuery]);

  const handleResultClick = () => {
    clearSearch();
  }

  const checkIfFullMatch = (searchString, dataset, fields=[]) => {
    const searchRegex = new RegExp(`\\b(${searchString})\\b`, 'i');

    // combine text for different translations
    const combinedTextToSearch = dataset.map(dataItem => {
      return fields.map(field => dataItem[field]);
    })?.join(' ');

    const fullMatch = searchRegex.test(combinedTextToSearch);

    return fullMatch;
  }

  const searchRecipes = async (searchString) => {
    const apiFields = [
      '*',
      '*.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
              retailer_id: {
                  '_eq': retailer.id
              }
          }
        },
        {
          retailer: {
              id: {
                  '_eq': retailer.id
              }
          }
        }
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchRecipeFieldsPayload = {
      "_and": [
        {
          "_or": [
            {
              "layout_translations": {
                  "name": {
                    "_contains": searchString
                  }
              }
            },
            {
              "layout_translations": {
                  "summary": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
      ]
    };

    const recipeSearchResults = await api.searchRecipes({ filter: searchRecipeFieldsPayload }, apiFields);

    return recipeSearchResults;
  }

  const searchBeefCuts = async (searchString) => {
    const apiFields = [
      '*',
      'translations.*',
      'retailers.*',
      'beef_product_overrides.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
              retailer_id: {
                  '_eq': retailer.id
              }
          }
        },
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchBeefCutFieldsPayload = {
      "_and": [
        {
          "_or": [
            {
              "translations": {
                  "name": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "description": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
      ]
    };

    // search recipes by tags first
    const beefCutSearchResults = await api.searchBeefCuts({ filter: searchBeefCutFieldsPayload }, apiFields);

    return beefCutSearchResults;
  }

  const searchCookingMethods = async (searchString) => {
    const apiFields = [
      '*',
      'translations.*',
    ];

    const retailerFilterPayload = {
      '_or': [
        {
          retailers: {
              retailer_id: {
                  '_eq': retailer.id
              }
          }
        },
      ]
    };

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const searchPayload = {
      "_and": [
        {
          "_or": [
            {
              "translations": {
                  "Method": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "Description": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "tips": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[retailerFilterPayload],
        ...[statusFilterPayload],
      ]
    };

    // search recipes by tags first
    const searchResults = await api.search('cooking_method', { filter: searchPayload }, apiFields);

    return searchResults;
  }

  const searchCuisine = async (searchString) => {
    const fields = [
      '*',
      'translations.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const filter = {
      "_and": [
        {
          "_or": [
            {
              "translations": {
                  "title": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "content": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[statusFilterPayload],
      ]
    };

    return await searchInEndpoint({ endpoint: 'collections', params: { filter, fields } });
  }

  const searchCollections = async (searchString) => {
    const fields = [
      '*',
      'translations.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      },
      type: {
        '_neq': 'CUISINE'
      }
    };

    const filter = {
      "_and": [
        {
          "_or": [
            {
              "translations": {
                  "title": {
                    "_contains": searchString
                  }
              }
            },
            {
              "translations": {
                  "content": {
                    "_contains": searchString
                  }
              }
            },
          ]
        },
        ...[statusFilterPayload],
      ]
    };

    return await searchInEndpoint({ endpoint: 'collections', params: { filter, fields } });
  }

  const searchAuthors = async (searchString) => {
    const fields = [
      '*',
      'translations.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const filter = {
      "_and": [
        {
          "_or": [
            {
              "first_name": {
                  "_contains": searchString
              }
            },
            {
              "last_name": {
                  "_contains": searchString
              }
            },
            {
              "biography": {
                  "_contains": searchString
              }
            },
          ]
        },
        ...[statusFilterPayload],
      ]
    };

    return await searchInEndpoint({ endpoint: 'recipe_author', params: { filter, fields } });
  }

  const searchArticles = async (searchString) => {
    const fields = [
      '*',
      'translations.*',
    ];

    const statusFilterPayload = {
      status: {
        '_eq': 'published'
      }
    };

    const filter = {
      "_and": [
        {
          "_or": [
            {
              translations: {
                title: {
                    _contains: searchString
                }
              }
            },
            {
              translations: {
                summary: {
                    _contains: searchString
                }
              }
            },
          ]
        },
        ...[statusFilterPayload],
      ]
    };

    return await searchInEndpoint({ endpoint: 'pages', params: { filter, fields } });
  }

  const searchInEndpoint = async ({ endpoint, params }) => {
    return await api.searchInTable({
      url: endpoint,
      ...params,
    });
  }

  const clearSearch = () => {
    setArticles([]);
    setBeefCuts([]);
    setCuisines([]);
    setCollections([]);
    setRecipes([]);
    setRecipeAuthors([]);

    setNoResultsFound(false);

    setSearchQuery('');

    handleSearchCleared();
  };

  const triggerSearch = async (searchString) => {
    try {
      setSearchQuery(searchString);
      setLoading(true);
      setNoResultsFound(false);
      handleSearchTriggered();

      let searchResults, articleResults, beefCutResults, cuisineResults, recipeAuthorResults, recipeResults;
      searchResults = articleResults = beefCutResults = cuisineResults = recipeAuthorResults = recipeResults = [];

      const shouldSearch = (endpoint) => {
        return !!searchOnly?.includes(endpoint);
      }

      switch (true) {
        case shouldSearch('BEEF_CUTS'):
          const beefCutResults = (await searchBeefCuts(searchString))
            .filter(beefCut => checkIfFullMatch(searchString, beefCut.translations, ['name', 'description']))

          searchResults = [...searchResults, ...beefCutResults];
          break;
        case shouldSearch('ARTICLES'):
          searchResults = [
            ...searchResults,
            ...(
              (await searchArticles(searchString))
                ?.map(item => {
                  const translatedItem = getTranslation(item, { key: 'languages_code', code: language.code }, 'translations');
                  const image = item.image ?
                    `${process.env.REACT_APP_API_URL}/assets/${item.image}` :
                    null;

                  return {
                    description: translatedItem.summary,
                    image,
                    subtitle: t('article'),
                    title: translatedItem.title,
                    url: `/articles/${item.id}`
                  }
                })
            )
          ];

          break;
        case shouldSearch('AUTHORS'):
          searchResults = [
            ...searchResults,
            ...(
              (await searchAuthors(searchString))
                ?.map(item => {
                  const translatedItem = getTranslation(item, { key: 'languages_code', code: language.code }, 'translations');
                  const image = item.image ?
                    `${process.env.REACT_APP_API_URL}/assets/${item.image}` :
                    null;

                  return {
                    description: item.biography,
                    image,
                    subtitle: t('author'),
                    title: getAuthorDisplayName(item),
                    url: `/authors/${item.id}`
                  }
                })
            )
          ];

          break;
        case shouldSearch('CUISINE'):
          searchResults = [
            ...searchResults,
            ...(
              (await searchCuisine(searchString))
                ?.map(item => {
                  const translatedItem = getTranslation(item, { key: 'languages_code', code: language.code }, 'translations');
                  const image = translatedItem.image ?
                    `${process.env.REACT_APP_API_URL}/assets/${translatedItem.image}` :
                    null;

                  return {
                    description: translatedItem.content,
                    image,
                    subtitle: t('cuisine'),
                    title: translatedItem.title,
                    url: `/cuisine/${item.id}`
                  }
                })
            )
          ];

          break;
        case shouldSearch('COLLECTIONS'):
          searchResults = [
            ...searchResults,
            ...(
              (await searchCollections(searchString))
                ?.map(item => {
                  const translatedItem = getTranslation(item, { key: 'languages_code', code: language.code }, 'translations');
                  const image = translatedItem.image ?
                    `${process.env.REACT_APP_API_URL}/assets/${translatedItem.image}` :
                    null;

                  return {
                    description: translatedItem.content,
                    image,
                    subtitle: t('cuisine'),
                    title: translatedItem.title,
                    url: `/collections/${item.id}`
                  }
                })
            )
          ];

          break;
        case shouldSearch('COOKING_METHODS'):
          const results = (await searchCookingMethods(searchString))
            ?.map(item => {
              const translatedItem = getTranslation(item, { key: 'languages_code', code: language.code }, 'translations');
              const image = item.image ?
                `${process.env.REACT_APP_API_URL}/assets/${item.image}?key=system-small-cover` :
                null;

              return {
                description: translatedItem.Description,
                image,
                subtitle: t('recipe'),
                title: translatedItem.Method,
                url: `/cooking-method/${item.id}`
              }
            });

          searchResults = [...searchResults, ...results];
          break;
        case shouldSearch('RECIPES'):
          const recipeResults = (await searchRecipes(searchString))
            .filter(recipe => checkIfFullMatch(searchString, recipe.layout_translations, ['name', 'summary']))
            ?.map(recipe => {
              const translatedRecipe = getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations');
              const recipeImage = recipe?.imagegallery?.length ?
                `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=system-small-cover` :
                null;

              return {
                description: translatedRecipe.summary,
                image: recipeImage,
                subtitle: t('recipe'),
                title: translatedRecipe.name,
                url: `/recipes/${recipe.id}`
              }
            });

          searchResults = [...searchResults, ...recipeResults];
          break;
        default:
          // do nothing
      }

      // // if tags search returned no hits, then search article localised fields
      // if (!articleResults.length) {
      //   articleResults = (await searchArticles(searchString))
      //     .filter(article => checkIfFullMatch(searchString, article.translations, ['title', 'summary']))
      //     ?.map(article => {
      //       const translatedArticle = getTranslation(article, { key: 'languages_code', code: language.code });

      //       return {
      //         description: translatedArticle.summary,
      //         handleClick: handleResultClick,
      //         image: article.image ?
      //         `${process.env.REACT_APP_API_URL}/assets/${article.image}?key=system-small-cover` :
      //         null,
      //         subtitle: t('article'),
      //         title: translatedArticle.title,
      //         url: `/article/${article.id}`
      //       }
      //     });
      // }

      // // if tags search returned no hits, then search cuisine localised fields
      // if (!cuisineResults.length) {
      //   cuisineResults = (await searchCollections(searchString))
      //     .filter(collection => checkIfFullMatch(searchString, collection.translations, ['title', 'content']))
      //     ?.map(collection => {
      //       const translatedCollection = getTranslation(collection, { key: 'languages_code', code: language.code });

      //       return {
      //         description: translatedCollection.content,
      //         handleClick: handleResultClick,
      //         image: translatedCollection.image ?
      //         `${process.env.REACT_APP_API_URL}/assets/${translatedCollection.image}?key=system-small-cover` :
      //         null,
      //         subtitle: ['CUISINE', 'CUISINES'].includes(collection.type) ? t('cuisine') : t('collection'),
      //         title: translatedCollection.title,
      //         url: `/collections/${collection.id}`
      //       }
      //     });
      // }

      // // if tags search returned no hits, then search author fields
      // if (!recipeAuthorResults.length) {
      //   recipeAuthorResults = (await searchAuthors(searchString))
      //     .filter(author => checkIfFullMatch(searchString, [author], ['first_name', 'last_name', 'biography']))
      //     ?.map(author => ({
      //       description: getTranslation(author, { key: 'languages_code', code: language.code })?.biography,
      //       handleClick: handleResultClick,
      //       image: author.image ?
      //         `${process.env.REACT_APP_API_URL}/assets/${author.image}?key=system-small-cover` :
      //         null,
      //       subtitle: t('author'),
      //       title: `${author.first_name} ${author.last_name}`,
      //       url: `/author/${author.id}`
      //     }))
      // }

      setArticles(articleResults);
      setBeefCuts(beefCutResults);
      setCuisines(cuisineResults);
      setRecipeAuthors(recipeAuthorResults);
      setRecipes(recipeResults);

      handleSearchComplete(searchResults);
    } catch(error) {
      console.log(error);
      error?.map((err) => notify('error', err.message));
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <Box
      className="collection"
    >
        <SearchNav
          searchString={searchQuery}
          triggerSearch={triggerSearch}
          handleClearSearch={clearSearch}
          loading={loading}
          compact={compact}
        />
    </Box>
  )
}

Search.propType =  {
  handleSearchTriggered: func,
  handleSearchCleared: func,
  handleSearchComplete: func,
  storageKey: string,
  compact: bool,
  searchOnly: arrayOf(
    oneOf(constants.searchOnlyEnums)
  )
};

export default Search