import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useSWR from "swr";

import { AccessTime, Add, PlayCircle } from "@mui/icons-material";

import { Box, Chip, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import ListPage from '../../layouts/ListPage';
import LazyLoad from 'react-lazyload';

import { videoToolkitFetcher } from "../../common/utils/fetcher";
import { useCheckVideoToolsEnabled } from '../../common/hooks/useCheckVideoToolsEnabled';
import FullPageMessage from '../../components/shared/FullPageMessage';

const SmartVideoRenderActions = ({ url, }) => {
  const { data, isLoading } = useSWR({
    url,
  }, videoToolkitFetcher);

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
    >

      {
        isLoading &&
        <Chip
          icon={<Add />}
          label="Loading"
          color="secondary"
          variant="outlined"
          disabled
        />
      }

      {
        data?.status === 'completed' &&
        <Chip
          icon={<PlayCircle />}
          label="Play Video"
          color="success"
          variant="outlined"
          component="a"
          target="_blank"
          href={data?.renderURL}
          sx={{
            cursor: 'pointer',
          }}
        />
      }

      {
        data?.status === 'queued' &&
        <Chip
          icon={<AccessTime />}
          label="Rendering"
          color="warning"
          variant="outlined"
        />
      }
    </Stack>
  )
}
const SmartVideoRenders = () => {
  const retailer = useSelector(state => state.retailer);
  
  const videoToolsEnabled = useCheckVideoToolsEnabled();

  const [itemsMeta, setItemsMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useSWR({
    url: 'Batch_Renders',
    sort: '-created',
    fields: '*, render_brand.logo',
    ...(!videoToolsEnabled ? {
      filter: {
        _and: [
          {
            render_brand: {
              id: {
                _eq: retailer.smart_videos_brand,
              }
            }
          }
        ]
      }
    } : {}),
  }, videoToolkitFetcher);

  let items = [];

  if (data) {
    items = data;
  }

  const summaryValues = [
    {
        label: 'Total',
        value: itemsMeta?.total_count,
    },
  ];

  useEffect(() => {
    fetchBatches();
  }, []);

  // TODO fetch meta data using hook and remove this
  const fetchBatches = async () => {
    try {
      const fetchItemsResponse = await API.fetchFromVideoToolkit('Batch_Renders', {
        sort: '-created',
        fields: '*, render_brand.logo'
      });

      setItemsMeta(fetchItemsResponse.meta);
    } catch (error) {
      console.log('error: ', error);
    } finally {}
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ListPage
      title='Smart Video Renders'
      summaries={summaryValues}
      searchStorageKey={'smart-videos-renders'}
      searchOnly={['RENDERS']}
      breadcrumbsLinks={[
        {
          url: '/smart-videos',
          content: 'Smart Videos',
        }
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          {items?.map((item) => (
            <Grid
              key={item.id}
              item
              xs={12}
              md={3}
              lg={2}
            >
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <LazyLoad
                  height='100%'
                >
                  <ImageCard
                    url={`/smart-videos/renders/${item.id}`}
                    item={{
                      title: item.project,
                      url: item?.render_brand?.logo ? `${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${item.render_brand.logo}` : ''
                    }} 
                    footerAction={(
                      <SmartVideoRenderActions
                        url={`/Batch_Renders/${item.id}`}
                      />
                  )}
                  />
                </LazyLoad>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default SmartVideoRenders