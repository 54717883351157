import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import API from '../../api';

import AddIconImage from '../../shared/svg/plus.svg';

import ImageCard from '../../components/ImageCard';
import { getTranslation } from '../../common/utils/translate';

function Coupons() {
  const retailer = useSelector(state => state.retailer);

  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);

      try {
        const coupons = await makeFetchCouponsRequest();
        setCoupons(coupons);
      } catch (error) {
        console.log('error: ', error);
        // TODO handle error
      } finally {
        setLoading(false)
      }
    };

    fetchCoupons();
  }, []);

  const makeFetchCouponsRequest = async () => {
    const couponsList = await API.getListCoupons({
      filter: {
        '_and': ([
          {
            '_or': [
              {
                owner: {
                  id: {
                    '_eq': retailer.id
                  }
                }
              }
            ]
          },
          {
            status: {
              '_eq': 'published'
            }
          },
          {
            _or: [
              {
                start_date: {
                  '_lte': '$NOW'
                }
              },
              {
                start_date: {
                  '_null': true
                }
              },
            ]
          },
          {
            _or: [
              {
                end_date: {
                  '_gte': '$NOW'
                }
              },
              {
                end_date: {
                  '_null': true
                }
              },
            ]
          }
        ])
      }
    });

    return couponsList;
  };

  return (
    <Box sx={{ flexGrow: 1, my: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3} lg={2}>
          <Link to={'create'}>
            <ImageCard item={{ title: 'New QR Code', url: AddIconImage }} />
          </Link>
        </Grid>

        {coupons.map((coupon) => (
          <Grid key={coupon.id} item xs={6} md={3} lg={2}>
            <Link to={`/coupons/${coupon.id}`}>
              <ImageCard
                item={{
                  title: getTranslation(coupon)?.name,
                  image: `https://staging.beef.tanglemedia.net/assets/${coupon.image}?key=system-large-cover`
                }}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Coupons