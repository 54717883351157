// TODO are these necessary?
let categoriesList = [];
let cookingMethodsList = [];

const defaultState = {
    searchString: '',
    prepTime: 0,
    cookTime: 0,
    chillTime: 0,
    freezingTime: 0,
    totalTime: 0,
    difficulty: 0,
    recipeYield: 0,
    categoryCheckedState: new Array(categoriesList.length).fill(null),
    cookingMethodCheckedState: new Array(cookingMethodsList.length).fill(null),
    ingredients: [],
};

const filtersReducer = (state = defaultState, action) => {
    switch(action.type) {
        case 'filters/clear':
            return {};
        case 'filters/update':
            return {
                ...state,
                ...action.payload
            };
        default: 
            return state;
    }
};

export default filtersReducer;