export const updateRetailer = (payload) => {
    return {
        type: 'retailer/update',
        payload
    }
};

export const resetRetailer = () => {
    return {
        type: 'retailer/reset'
    }
};