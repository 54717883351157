import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import API from '../../api';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

import Wysiwyg from '../../components/shared/Wysiwyg';

import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translate';
import { getAuthorDisplayName } from '../../utils/author';

function AuthorView() {
  useRefreshToken();

  const params = useParams();

  const retailer = useSelector(state => state.retailer);

  let title = '';
  let description = '';

  let fields = [];
  let sidebarFields =[];

  const [loading, setLoading] = useState(false);

  const payload = {
    fields: [
      '*',
      'translations.*',
    ],
  };

  let { data: author } = useSWR({
    url: `recipe_author/${params.id}`,
    ...payload,
  });

  if (author) {
    const translatedItem = getTranslation(author, { key: 'languages_code', code: 'en-US' });

    const image = author?.image ?
      `${process.env.REACT_APP_API_URL}/assets/${author.image}` : null;

    title = getAuthorDisplayName(author);

    fields = [
      {
        key: 'Display Name',
        value: author.display_name,
      },
      {
        key: 'First Name',
        value: author.first_name,
      },
      {
        key: 'Last Name',
        value: author.last_name,
      },
      {
        key: 'Biography',
        children: (
          <Typography>
            <Wysiwyg>{author.biography}</Wysiwyg>
          </Typography>
        )
      },
    ];

    sidebarFields = [
      {
        key: 'Image',
        children: (
          <ImageContainer
            url={image}
            alt={translatedItem.title}
            sx={{
              height: '20rem',
            }}
          />
        )
      },
    ];
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        breadcrumbsLinks={[
          {
            url: '/collections-menu',
            content: 'Collections & Cuisine',
          },
          {
            url: `/authors`,
            content: 'Authors',
          }
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default AuthorView