import { Box, Input, Stack, Typography } from "@mui/material"

function FormInput({ field, label, description, value, handleInputChange, type = 'text' }) {
    const onChange = (field, value) => {
        handleInputChange(field, value)
    };
    
    return (
        <Stack>
            <Typography
                className="form-label"
                variant='label'
            >
                {label}
            </Typography>

            <Stack
                direction={'row'}
            >
                <Input
                    inputProps={{
                        className: 'form-input',
                        sx: {
                            borderTopRightRadius: '0',
                            borderBottomRightRadius: '0',
                        }
                    }}
                    sx={{
                        color: '#262626 !important',
                        py: 0,
                        width: '100%'
                    }}
                    type={type}
                    value={value}
                    onChange={(event) => { 
                        const value = event.target.value;

                        onChange(field, value);
                    }}
                    placeholder=""
                    variant="standard"
                />
            </Stack>

            <Typography
                className="form-input-description"
                variant='p'
            >
                {description}
            </Typography>
        </Stack>
    )
}

export default FormInput