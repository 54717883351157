import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import API from "../../api";
import inputs from "./Inputs";
import notify from "../Notification/helper";

const EditBarcode = ({
  barcodeValue,
  setShowForm,
  setSavedData,
  formData,
  setFormData,
  savedData,
  products,
  retailers,
  stores,
  editFormLoading,
}) => {
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const retailer = useSelector((state) => state.retailer);

  const formSections = [
    {
      title: formData?.code,
      fields: [
        {
          label: "Product",
          name: "beef_product_id",
          type: "dropdown",
          error: formError?.beef_product_id,
          value: formData?.beef_product_id,
          handleInputChange: (e) => {
            formError.beef_product_id = "";
            setFormData((prev) => ({
              ...prev,
              beef_product_id: products
                ?.filter(({ id }) => id === e)
                ?.map(({ id, translations }) => ({
                  value: id,
                  label: translations?.[0]?.name || "",
                }))[0],
            }));
          },
          options: products?.map((product) => ({
            value: product?.id,
            label: product?.translations?.[0]?.name || "",
          })),
        },
        {
          label: "Owner",
          name: "owner",
          type: "dropdown",
          error: formError?.owner,
          value: formData?.owner,
          handleInputChange: (e) => {
            formError.owner = "";
            setFormData((prev) => ({
              ...prev,
              owner: retailers
                ?.filter(({ id }) => id === e)
                ?.map(({ id, translations }) => ({
                  value: id,
                  label: translations?.[0]?.retailer_name || "",
                }))[0],
            }));
          },
          options: retailers?.map((retailer) => ({
            value: retailer?.id,
            label: retailer?.translations?.[0]?.retailer_name || "",
          })),
        },
        {
          label: "Description",
          name: "raw_description",
          type: "translation",
          value: formData?.raw_description,
          options: [],
          handleInputChange: (field, value) => {
            setFormData({
              ...formData,
              [field]: value,
            });
          },
        },
        {
          label: "Retailer",
          name: "raw_retailer",
          type: "translation",
          value: formData?.raw_retailer,
          options: [],
          handleInputChange: (field, value) => {
            setFormData({
              ...formData,
              [field]: value,
            });
          },
        },
        {
          label: "Price",
          name: "price_data",
          type: "number",
          value: formData?.price_data,
          options: [],
          handleInputChange: (field, value) => {
            setFormData({
              ...formData,
              [field]: value,
            });
          },
        },
        {
          label: "Label Text",
          name: "label_text",
          type: "translation",
          value: formData?.label_text,
          options: [],
          handleInputChange: (field, value) => {
            setFormData({
              ...formData,
              [field]: value,
            });
          },
        },
        {
          label: "Scanned At",
          name: "store",
          type: "dropdown",
          value: formData?.scanned_at,
          handleInputChange: (e) => {
            formError.scanned_at = "";
            setFormData((prev) => ({
              ...prev,
              scanned_at: stores
                ?.filter(({ id }) => id === e)
                ?.map(({ id, store_name }) => ({
                  value: id,
                  label: store_name || "",
                }))[0],
            }));
          },
          options: stores?.map((store) => ({
            value: store?.id,
            label: store?.store_name || "",
          })),
        },
        {
          name: "product_image",
          label: "Product Image",
          type: "image",
          value: formData?.product_image,
          handleInputChange: (field, value) => {
            let keyValuePair = {};
            keyValuePair[field] = value;
            console.log(field, value);
            setFormData({
              ...formData,
              ...keyValuePair,
            });
          },
        },
      ],
    },
  ];

  const formValidation = () => {
    const validationErrors = {};
    if (
      !formData?.owner ||
      formData?.owner?.value?.trim() === "" ||
      !formData?.owner?.value
    ) {
      validationErrors.owner = "This field is required";
    }
    if (
      !formData?.beef_product_id ||
      formData?.beef_product_id?.value?.trim() === "" ||
      !formData?.beef_product_id?.value
    ) {
      validationErrors.beef_product_id = "This field is required";
    }

    return validationErrors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = formValidation(formData);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);

      setSavedData({
        ...savedData,
        code: barcodeValue,
        owner: retailers?.filter(
          (item) => item?.id === formData?.owner?.value
        )[0],
        beef_product_id: products?.filter(
          (item) => item?.id === formData?.beef_product_id?.value
        )[0],
        scanned_at: stores?.filter(
          (item) => item?.id === formData?.scanned_at?.value
        )[0],
        raw_description: formData?.raw_description,
        raw_retailer: formData?.raw_retailer,
        product_image: {
          filename_disk: formData?.product_image?.includes(
            process.env.REACT_APP_API_URL
          )
            ? savedData?.product_image?.filename_disk
            : formData?.product_image,
        },
        price_data: formData?.price_data,
        label_text: formData?.label_text,
      });

      try {
        await API.updateBarcode(
          {
            owner: formData?.owner?.value,
            beef_product_id: formData?.beef_product_id?.value,
            scanned_at: formData?.scanned_at?.value,
            raw_description: formData?.raw_description,
            raw_retailer: formData?.raw_retailer,
            product_image: formData?.product_image?.includes(
              process.env.REACT_APP_API_URL
            )
              ? savedData?.product_image
              : formData?.product_image || null,
            price_data: formData?.price_data,
            label_text: formData?.label_text,
          },
          savedData?.id
        );
        notify("success", "Barcode Data Updated Successfully");
        setShowForm(false);
      } catch (err) {
        err?.map((err) => notify("error", err.message));
      } finally {
        setLoading(false);
      }
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <form onSubmit={(e) => handleFormSubmit(e)}>
      {editFormLoading || !products || !retailers || !stores ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12} md={7}>
                {formSections.map((section) => (
                  <Box key={section.title}>
                    {section.title && (
                      <Typography
                        className="form-section-title"
                        sx={{
                          mt: 5,
                          mb: 1,
                          color: retailer.brand_color || "#000000",
                        }}
                        variant="h2"
                      >
                        {section.title}
                      </Typography>
                    )}

                    <Grid container spacing={2}>
                      {section.fields.map((field) => (
                        <Fragment key={field.name}>
                          <Grid
                            key={field.name}
                            item
                            {...(["colour"].includes(field?.type)
                              ? {
                                  xs: 12,
                                  md: 4,
                                }
                              : {
                                  xs: 12,
                                })}
                          >
                            {inputs(field)}
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </Grid>

              <Grid item xs={12} md={7}>
                <Button
                  disabled={loading}
                  className="form-button"
                  sx={{ width: "40%", my: 5, mr: 2 }}
                  size="large"
                  variant="contained"
                  onClick={() => {
                    setShowForm(false);
                  }}
                >
                  Back
                </Button>
                <Button
                  disabled={loading}
                  className="form-button"
                  sx={{ width: "40%", my: 5 }}
                  size="large"
                  variant="contained"
                  onClick={handleFormSubmit}
                >
                  {loading ? "Updating... " : "Update"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </form>
  );
};

export default EditBarcode;
