import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import API from '../../api';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

import Wysiwyg from '../../components/shared/Wysiwyg';
import RecipeVideos from '../../components/shared/RecipeVideos';

import { LightbulbOutlined } from '@mui/icons-material';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translate';
import Nutrition from '../../components/Nutrition';

function RecipeView() {
  useRefreshToken();

  const params = useParams();  
  const { t } = useTranslation();

  const language = {
    code: 'en-US',
  };

  const retailer = useSelector(state => state.retailer);
  
  const recipeId = params.recipeId;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  
  const [fields, setFields] = useState([]);
  const [sidebarFields, setSidebarFields] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!recipeId) return;

    makeGetQrCodeRequest();
  }, [recipeId]);

  const makeGetQrCodeRequest = async () => {
    try {
      setLoading(true);

      const recipeResponse = await API.getRecipe(recipeId);
      const nutrition = recipeResponse.nutrition?.id ? await API.getNutrition(recipeResponse.nutrition.id) : {};

      const translatedRecipe = getTranslation(recipeResponse, { key: 'languages_code', code: 'en-US' }, 'layout_translations');
      
      const recipeImage = recipeResponse?.imagegallery?.length ?
      `${process.env.REACT_APP_API_URL}/assets/${recipeResponse.imagegallery[0].directus_files_id}?key=tile-background-image` : null;
      
      setTitle(translatedRecipe.name);

      setFields([
        {
          key: 'Name',
          value: translatedRecipe.name
        },
        {
          key: 'Summary',
          children: (
            <Typography>
              <Wysiwyg>{translatedRecipe.summary}</Wysiwyg>
            </Typography>
          )
        },
        {
          key: 'Directions',
          children: (
            <>
              {
                recipeResponse?.recipe_directions?.map((instructionInfo) => (
                  <li key={instructionInfo.id}>
                    {
                      getTranslation(instructionInfo, { key: 'languages_code', code: language.code })?.steps?.blocks?.map((block) => (
                        <Stack
                          key={block.id}
                          sx={{ mb: 1 }}
                        >
                          {
                            block.type === 'paragraph' && !!(block.data.text?.replace('<br>', '')) &&
                            <Typography>
                              <Wysiwyg>
                                {block.data.text}
                              </Wysiwyg>
                            </Typography>
                          }

                          {
                            block.type === 'header' &&
                            <Typography
                              sx={{ color: '#4D4D4D', mb: 1 }}
                              variant={`h${block.data.level}`}
                            >
                              <Wysiwyg>
                                {block.data.text}
                              </Wysiwyg>
                            </Typography>
                          }

                          {
                            block.type === 'image' &&
                            <ImageList sx={{ mt: 1 }} cols={1}>
                              <ImageListItem>
                                <img
                                  className='brand-image'
                                  src={`${process.env.REACT_APP_API_URL}${block.data.file.url}?crossorigin`}
                                  srcSet={`${process.env.REACT_APP_API_URL}${block.data.file.url}?key=system-large-contain`}
                                  alt={block.data.caption}
                                  loading="lazy"
                                />
                                {!!block.data.caption &&
                                  <ImageListItemBar
                                    title={block.data.caption}
                                  />
                                }
                              </ImageListItem>
                            </ImageList>
                          }

                          {
                            block.type === 'alert' &&
                            <Box
                              className='tip_wrap'
                              sx={{ my: 2 }}
                            >
                              <Stack
                                className='tip_tile'
                                sx={{
                                  p: 3,
                                  pb: 5
                                }}
                              >
                                <Stack
                                  direction={'row'}
                                  alignItems={'center'}
                                  justifyContent={'flex-start'}
                                >
                                  <LightbulbOutlined
                                    sx={{
                                      fontSize: '32px',
                                      color: retailer.brand_color,
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      pl: 1,
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      textTransform: 'uppercase',
                                      color: '#262626'
                                    }}
                                  >
                                    {t('tip')}
                                  </Typography>
                                </Stack>
                                <Typography
                                  sx={{
                                    pt: 2,
                                    fontSize: '18px',
                                    fontWeight: '300',
                                  }}
                                  component={'div'}
                                >
                                  <Wysiwyg>
                                    {block.data.message}
                                  </Wysiwyg>
                                </Typography>
                              </Stack>
                            </Box>
                          }
                        </Stack>
                      ))
                    }
                  </li>
                ))
              }
            </>
          )
        },
        {
          key: 'Ingredients',
          children: (
            <ul>
              {
                getTranslation(recipeResponse, { key: 'languages_code', code: language.code }, 'layout_translations').ingredients_list?.map((val, index) => (
                  val.heading ?
                    <Typography
                      key={index}
                      variant='h3'
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        mt: 2
                      }}
                    >
                      {val.ingredient}
                    </Typography> :
                    <li>
                      <Typography>
                        <Wysiwyg>
                          {val.ingredient}
                        </Wysiwyg>
                      </Typography>
                    </li>
                ))
              }
            </ul>
          )
        },
        {
          key: 'Shopping List',
          children: (
            <ul>
              {
                getTranslation(recipeResponse, { key: 'languages_code', code: language.code }, 'layout_translations').shopping_list?.map((val, index) => (
                  val.heading ?
                    <Typography
                      key={index}
                      variant='h3'
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        mt: 2
                      }}
                    >
                      {val.ingredient}
                    </Typography> :
                    <li>
                      <Typography>
                        <Wysiwyg>
                          {val.name}
                        </Wysiwyg>
                      </Typography>
                    </li>
                ))
              }
            </ul>
          )
        }
      ]);

      setSidebarFields([
        {
          key: 'Image',
          children: (
            <ImageContainer
              url={recipeImage}
              alt={translatedRecipe.name}
              sx={{
                height: '20rem',
              }}
            />
          )
        },
        {
          key: 'Videos',
          children: (
            <RecipeVideos recipe={recipeResponse} />
          )
        },
        {
          key: 'Nutrition',
          children: (
            <Nutrition nutrition={nutrition} recipe={recipeResponse} />
          )
        },
      ])
    } catch (error) {
      console.log('error: ', error);
      notify('error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  const makeRetailerPatchRequest = async (payload) => {
    return await API.updateRetailer(payload);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={ title }
        description={ description }
        addToLibrary={{
          junctionTable: 'recipe_retailer',
          filter: {
            _and: [
              { recipe_id: { _eq: recipeId } },
              { retailer_id: { _eq: retailer.id } }
            ]
          },
          addPayload: {
            recipe_id: recipeId,
            retailer_id: retailer.id
          },
          removePayload: {
            query: {
              filter: {
                retailer_id: {
                  _eq: retailer.id,
                },
                recipe_id: {
                  _eq: recipeId,
                }
              }
            }
          },
        }}
        breadcrumbsLinks={[
          {
            url: '/recipes-menu',
            content: 'Content Library',
          },
          {
            url: `/recipes`,
            content: 'Recipes',
          }
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>            
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          { field.children }
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid> 
                </Grid> 
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          { field.children }
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid> 
                </Grid> 
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default RecipeView