import { Box } from '@mui/material';
import { object, string } from 'prop-types';

import styles from './index.module.scss';

function ImageContainer({ url, alt, sx={} }) {
    const imageTransformationKey = url?.includes('key') ? '' : '?key=brand-portal-image-card-square';

    return (
        <Box
            className={styles.image_container}
            sx={{
                width: '100%',
                border: '1px solid #e0e0e0',
                ...sx
            }}
        >
            <img
                className={styles.image}
                src={`${url}${imageTransformationKey}`}
                alt={alt}
            />
        </Box>
    )
}

ImageContainer.propTypes = {
    url: string.isRequired,
    alt: string.isRequired,
    sx: object,
}

export default ImageContainer