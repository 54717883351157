import { Link } from 'react-router-dom';

import LinkMUI from '@mui/material/Link';
import BreadcrumbsMUI from '@mui/material/Breadcrumbs';
import { Box } from '@mui/material';
import { Apps, Home, Menu, NavigateNext } from '@mui/icons-material';
import { arrayOf, bool, element, oneOf, oneOfType, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';

function Breadcrumbs({ home, links}) {
    const retailer = useSelector(state => state.retailer);

    return (
        <Box
            sx={{}}
        >
            <BreadcrumbsMUI aria-label="breadcrumb"
                separator={(
                    <NavigateNext
                        fontSize="small"
                        sx={{
                            color: `${retailer?.brand_color || 'rgb(51, 51, 51)'}`,
                        }}
                    />
                )}
                sx={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    color: 'rgba(0, 0, 0, 0.64)'
                }}
            >
                {
                    !!home &&
                        <LinkMUI
                            to={'/'}
                            underline="hover"
                            color="black"
                            component={Link}
                            sx={{
                            }}
                        >
                            <Home
                                sx={{
                                    my: 'auto',
                                }}
                                fontSize="inherit"
                            />
                        </LinkMUI>
                }

                {
                    links.map(link => (
                        <LinkMUI
                            to={link.url}
                            key={link.url}
                            underline="none"
                            color="inherit"
                            component={Link}
                            sx={{
                                textTransform: 'capitalize',
                            }}
                        >
                            {link.content}
                        </LinkMUI>
                    ))
                }
            </BreadcrumbsMUI>
        </Box>
    )
}

Breadcrumbs.propTypes = {
    home: bool,
    links: arrayOf(
        shape({
            url: string,
            content: oneOfType([
                string,
                element
            ]).isRequired
        })
    )
}

export default Breadcrumbs