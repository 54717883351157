import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import API from '../../api';

import InputUpload from '../../components/InputUpload';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

function QrCodeView() {
  const params = useParams();

  const qrCodeId = params.qrCodeId;

  const retailer = useSelector(state => state.retailer);

  useRefreshToken();

  // move this to state
  const languages = [{"code":"de-DE"},{"code":"en-US"},{"code":"es-ES"},{"code":"fr-FR"},{"code":"it-IT"},{"code":"pt-BR"},{"code":"ru-RU"}];

  const [qrCodePayload, setQrCodePayload] = useState({ name: '', url: '', retailer: retailer.id });
  const [fields, setFields] = useState([]);
  const [sidebarFields, setSidebarFields] = useState([]);

  const [loading, setLoading] = useState(false);

  const formSections = [
    {
      title: '',
      fields: [
        {
          label: 'Label',
          name: 'name',
          type: 'text',
          options: [],
        },
        {
          label: 'URL',
          name: 'url',
          type: 'url',
          options: []
        }
      ],
    },
  ]

  useEffect(() => {
    if (!qrCodeId) return;

    // if qrCodeId exists then fetch qr code
    // set payload
    makeGetQrCodeRequest();
  }, [qrCodeId]);

  const makeGetQrCodeRequest = async () => {
    try {
      setLoading(true);

      const qrCodeResponse = await API.getQrCode(qrCodeId);
      setQrCodePayload({ id: qrCodeId, name: qrCodeResponse.name, url: qrCodeResponse.url, retailer: retailer.id });

      setFields([
        {
          key: 'Name',
          value: qrCodeResponse.name
        },
        {
          key: 'URL',
          value: qrCodeResponse.url
        },
      ]);

      setSidebarFields([
        {
          key: 'QR Code',
          children: (
            <ImageContainer
              url={`https://chart.googleapis.com/chart?cht=qr&chs=450x450&chl=${qrCodeResponse.url}`}
              alt={qrCodeResponse.name}
            />
          )
        }
      ])
    } catch (error) {
      notify('error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  const makeCreateQrCodeRequest = async () => {
    try {
      setLoading(true);

      await API.createQrCode(qrCodePayload);
      notify('success', 'QR Code created');
    } catch (error) {
      console.log('error: ', error);
      notify('error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  const makeEditQrCodeRequest = async () => {
    try {
      setLoading(true);

      await API.updateQrCode(qrCodePayload);
      notify('success', 'QR Code updated');
    } catch (error) {
      console.log('error: ', error);
      notify('error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  // TODO refactor this
  const handleInputChange = (value, field) => {
    let keyValuePair = {};
    keyValuePair[field] = value;

    setQrCodePayload({
      ...qrCodePayload,
      ...keyValuePair
    })
  };

  const getFieldValue = (field, languageCode) => {
    if (languageCode) {
      let translatedFieldValue = '';

      const translations = qrCodePayload.translations.filter(translation => translation.languages_code === languageCode);

      translatedFieldValue = translations.length ?
        (translations[0][field] || '') :
        translatedFieldValue;
      
      return translatedFieldValue;
    } else {
      return qrCodePayload[field];
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={ qrCodePayload.name }
        onDelete={() => {}}
        breadcrumbsLinks={[
          {
            url: '/recipes-menu',
            content: 'Content Library',
          },
          {
            url: '/codes-menu',
            content: 'Barcodes & QR Codes',
          },
          {
            url: '/qr-codes',
            content: 'QR Codes',
          },
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          { field.children }
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid> 
                </Grid> 
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          { field.children }
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid> 
                </Grid> 
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              {formSections.map((section) => (
                <Box key={section.title}>
                  {section.title && <Typography className='section-title' variant="h2">{section.title}</Typography>}

                  <Grid container spacing={2}>
                    {section.fields.map(field => (
                      <Fragment key={field.name}>
                        <Grid key={field.name} item xs={['color', 'font', 'dropdown'].includes(field?.type) ? 4 : 12}>
                          <Stack>
                            <Typography className="form-label" htmlFor="input-login-email" variant='label'>{field.label}</Typography>
                            {['translation'].includes(field?.type) &&
                              <Accordion>
                                <AccordionSummary
                                  sx={{ my: 0, '& .MuiAccordionSummary-content': { my: 0 }, '& .MuiAccordionSummary-content.Mui-expanded': { my: 0 } }}
                                  className='form-input'
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {getFieldValue(field.name)} &nbsp;
                                </AccordionSummary>
                                <AccordionDetails>
                                  {languages.map(language => (
                                    <Stack key={language.code}>
                                      <Typography className="form-label" htmlFor={`input-my-brand-${field.name}-${language.code}`} variant='label'>{language.code}</Typography>
                                      <Input
                                        id={`input-my-brand-${field.name}-${language.code}`}
                                        inputProps={{
                                          className: 'form-input'
                                        }}
                                        sx={{
                                          color: '#262626 !important',
                                          py: 0,
                                          width: '100%'
                                        }}
                                        value={getFieldValue(field.name, language.code)}
                                        onChange={(event) => { handleInputChange(event.target.value, field.name, language.code) }}
                                        placeholder=""
                                        variant="standard"
                                      />
                                    </Stack>
                                  ))}
                                </AccordionDetails>
                              </Accordion>
                            }

                            {!['translation', 'image'].includes(field?.type) && (
                              field?.options?.length ?
                                <Select
                                  labelId="demo-simple-select-label"
                                  inputProps={{
                                    className: 'form-input'
                                  }}
                                  sx={{ borderRadius: 0, '& .MuiSelect-select': { borderRadius: 0 } }}
                                  value={getFieldValue(field.name)}
                                  onChange={(event) => { handleInputChange(event.target.value, field.name) }}
                                >
                                  {field.options.map(option => (
                                    <MenuItem key={option.url} value={option.url} sx={{ textTransform: 'uppercase' }}>{option.label}</MenuItem>
                                  ))}
                                </Select>
                                :
                                <Input
                                  id={`input-my-brand-${field.name}`}
                                  inputProps={{
                                    className: 'form-input'
                                  }}
                                  sx={{
                                    color: '#262626 !important',
                                    py: 0,
                                    width: '100%'
                                  }}
                                  value={getFieldValue(field.name)}
                                  onChange={(event) => { handleInputChange(event.target.value, field.name) }}
                                  placeholder=""
                                  variant="standard"
                                />
                            )
                            }

                            {!['translation'].includes(field?.type) && field.type === 'image' &&
                              <InputUpload
                                field={field.name}
                                uploadHandler={(field, uploadedFileId) => { handleInputChange(uploadedFileId, field) }}
                              />
                            }
                          </Stack>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} md={7} >
              <Button
                disabled={loading}
                className='form-button'
                sx={{ width: '40%', my: 5 }}
                size="large"
                variant="contained"
                onClick={() => { qrCodeId ? makeEditQrCodeRequest() : makeCreateQrCodeRequest() }}
              >{qrCodeId ? 'Update' : 'Create a'} QR Code</Button>
            </Grid>
          </Grid>
        </Box> */}
      </ViewPage>
    </>
  )
}

export default QrCodeView