import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function Read(props) {
    const { title, children } = props;

    return (
        <Box sx={{ flexGrow: 1, my: 3 }}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                    mb: 2
                }}
            >
                <Typography
                    className='form-title'
                    variant="h2"
                >
                    {title}
                </Typography>
            </Stack>

            <>
                {children}
            </>
        </Box>
    )
}

export default Read