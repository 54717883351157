
import BackgroundImage from '../../shared/image/placeholder.jpg';

const constants = {
    idle: 'IDLE',
    fetching: 'FETCHING',
    fetched: 'FETCHED',
    error: 'FETCHED',

    placeholderImage: BackgroundImage,

    supportedLanguadeCodes: ['en-US', 'fr-FR'],
};

export default constants;