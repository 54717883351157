import { useEffect, useState } from "react";

import { Add, Check, Close, Remove } from "@mui/icons-material";
import { Box, Chip, IconButton } from "@mui/material";
import api from "../../../api";
import { useDispatch } from "react-redux";
import { updateRetailer } from "../../../actions/Retailer";

function LibraryActions({ id, junctionTable='recipe_retailer', junctionPayload,  }) {
    const [inLibrary, setInLibrary] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    // TODO consider making this a custom hook
    const updateRetailerInStore = async () => {
        const userResponse = await api.getUserAndRetailer();

        // save retailer state
        let retailer = userResponse.retailer;
        dispatch(updateRetailer(retailer));

    }

    const checkIfInLibrary = async () => {
        const payload = {
            filter: {
                _and: [junctionPayload]
            }
        };

        const junctionTableItems = await api.getJunctionItems(junctionTable, payload);
        return junctionTableItems?.length > 0;
    }

    const addToLibrary = async () => {
        try {
            setLoading(true);

            console.log('junctionPayload: ', junctionPayload);
            await api.addToJunction(junctionTable, junctionPayload);
            
            // will force checking of item in library
            setInLibrary(null);
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoading(false);
        }
    }

    const removeFromLibrary = async () => {
        try {
            setLoading(true);

            // get key of junctions items to remove
            const fetchJunctionItemsPayload = {
                filter: {
                    _and: [junctionPayload]
                }
            };
            const junctionItems = await api.getJunctionItems(junctionTable, fetchJunctionItemsPayload);

            // delete junctions items
            const payload = {
                keys: junctionItems.map(item => item.id),
            };

            await api.deleteFromJunction(junctionTable, payload);
            
            // will force checking of item in library
            setInLibrary(null);
            
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const initialise = async () => {
            setInLibrary(await checkIfInLibrary());
        };

        initialise();
    }, [inLibrary]);

    return (
        <Box>
            {
                inLibrary === false &&
                <>
                    <Chip
                        icon={<Add />}
                        label="Add to Library"
                        onClick={() => { addToLibrary() }}
                        disabled={loading}
                        color="success"
                        variant="outlined"
                    />
                </>
            }

            {
                inLibrary === true &&
                <>
                    <Chip
                        icon={<Close />}
                        label="Remove from Library"
                        onClick={() => { removeFromLibrary() }}
                        disabled={loading}
                        color="error"
                        variant="outlined"
                    />
                </>
            }
        </Box>
  )
}

export default LibraryActions