import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import { chooseInputComponent } from '../../common/utils/components';
import { getTranslation } from '../../common/utils/translate';
import constants from '../../common/utils/constants';
import {buildTranslationPayload} from '../../common/utils/payloads';

import api from '../../api';

import notify from '../../components/Notification/helper';
import Nutrition from '../../components/Nutrition';
import Loader from '../../components/Loader';

function BeefProductBrandCreate() {
  // TODO rename this to userRefreshAccessToken
  useRefreshToken();

  const params = useParams();

  const recipesStore = useSelector(state => state.recipes);

  const [linkedRecipes, setLinkedRecipes] = useState([]);
  const [beefCut, setBeefCut] = useState({});
  const [translatedBeefCut, setTranslatedBeefCut] = useState({});
  const [beefCutPayload, setBeefCutPayload] = useState({
    status: '',
    image: '',
    svg_icon: '',
    translations: {
      create: [],
      update: [],
      delete: [],
    }
  });
  const [apiCallStatus, setApiCallStatus] = useState(constants.idle);

  const beefCutId = params.beefCutId;


  const headerForRecipes = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'summary',
      numeric: false,
      disablePadding: false,
      label: 'Summary',
    },
  ];

  // get recipes
  const recipeRows = recipesStore.data.map(row => {
    const translation = getTranslation(row, { key: 'languages_code', code: 'en-US' }, 'layout_translations');

    return {
      id: row.id,
      name: translation?.name,
      description: translation?.summary?.substring(0, 99) + '...',
    };
  });

  const handleInputChange = (value, field, languageCode) => {
    let keyValuePair = {};
    keyValuePair[field] = value;

    if (languageCode) {
      keyValuePair.languages_code = languageCode;

      console.log('keyValuePair: ', keyValuePair);
      console.log('languageCode: ', languageCode);

      // locate translation object if exists
      const translationObjects = beefCutPayload.translations.filter(translation => translation.languages_code === languageCode);

      console.log('translationObjects: ', translationObjects);

      if (translationObjects.length) {
        beefCutPayload.translations = beefCutPayload.translations.map(translation => {
          if (translation.languages_code === languageCode) {
            translation = {
              ...translation,
              ...keyValuePair
            }
          }

          return translation;
        });

        keyValuePair = {};
      } else {
        keyValuePair = {
          translations: [
            ...beefCutPayload.translations,
            keyValuePair
          ]
        };

        console.log('keyValuePair: ', keyValuePair);        
      }
    }

    const updatedBeefCutPayload = {
      ...beefCutPayload,
      ...keyValuePair
    };

    console.log('updatedBeefCutPayload: ', updatedBeefCutPayload);

    setBeefCutPayload(updatedBeefCutPayload);
  };

  const formSections = [
    {
      title: 'Overview',
      fields: [
        {
          label: 'Name',
          name: 'name',
          type: 'text',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
          },
        },
        {
          label: 'Description',
          name: 'description',
          type: 'textarea',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
          },
        },
        {
          label: 'Tips',
          name: 'tips',
          type: 'textarea',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
          },
        },
      ],
    },
    {
      title: 'Images',
      fields: [
        {
          label: 'Image',
          name: 'image',
          type: 'image',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            value: beefCutPayload.image
          },
        },
        {
          label: 'SVG Icon',
          name: 'svg_icon',
          type: 'image',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            value: beefCutPayload.svg_icon
          },
        },
      ],
    },
    {
      title: 'Collections',
      fields: [
        {
          label: 'Recipes',
          name: 'recipes',
          type: 'm2m',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            title: 'Recipes',
            rows: recipeRows,
            headCells: headerForRecipes,
            items: linkedRecipes
          },
        },
      ],
    },
    {
      title: 'Visibility',
      fields: [
        {
          label: 'Status',
          name: 'status',
          type: 'status',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            value: beefCutPayload.status
          },
        },
      ],
    },
  ]

  const getBeefProduct = async () => {
    return await api.getBeefProduct(beefCutId);
  }

  useEffect(() => {
    if (!beefCutId) return;

    const initialiseStates = async () => {
      try {
        setApiCallStatus(constants.fetching);

        const beefCut = await getBeefProduct();

        setBeefCut(beefCut);

        // TODO move to own function
        const requestPayload = buildTranslationPayload(beefCut.translations, 'languages_id');
        setBeefCutPayload({
          status: beefCut.status,
          image: beefCut.image,
          svg_icon: beefCut.svg_icon,
          ...requestPayload
        });
        console.log('requestPayload: ', requestPayload);
        
        setTranslatedBeefCut(
          getTranslation(
            beefCut,
            {
              key: 'languages_id',
              code: 'en-US' // TODO add default language code to state
            },
            'translations'
          )
        );

        const linkedRecipes = beefCut.recipes?.map(recipeJunction => {
          return {
            id: recipeJunction.recipe_id.id,
            name: getTranslation(recipeJunction.recipe_id, {
              key: 'languages_code',
              code: 'en-US' },
              'layout_translations'
            )?.name
          }
        });
        setLinkedRecipes(linkedRecipes);

        setApiCallStatus(constants.fetched);
      } catch (error) {
        console.error('error: ', error);
        notify('error', 'Something went wrong');
        setApiCallStatus(constants.error);
      }
    }

    initialiseStates();
  }, [beefCutId]);


  if (apiCallStatus === constants.fetching) {
    return <Loader />;
  }

  return (
    <Box sx={{ flexGrow: 1, my: 3 }}>
      <Typography className='form-title' variant="h2">
        {translatedBeefCut?.name || 'Create New Beef Cut'}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={7} lg={6}>
          {formSections.map((section) => (
            <Box key={section.title}>
              {
                section.title &&
                <Typography
                  className='section-title'
                  sx={{
                    mt: 5,
                    mb: 1
                  }}
                  variant="h2"
                >
                  {section.title}
                </Typography>
              }

              <Grid container spacing={2}>
                {section.fields.map(field => (
                  <Fragment key={field.name}>
                    <Grid
                      item
                      key={field.name}
                      xs={['color', 'font', 'dropdown'].includes(field?.type) ? 4 : 12}
                    >
                      { chooseInputComponent(field) }
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          ))}
          
          <Button
            className='form-button'
            sx={{
              my: 5
            }}
            size="large"
            variant="contained"
          // onClick={() => { beefCutId ? makeEditQrCodeRequest() : makeCreateQrCodeRequest() }}
          >
            {beefCutId ? 'Update' : 'Create a'} Beef Cut
          </Button>
        </Grid>
        {
          !!beefCut.Nutrients &&
          <Grid item xs={12} md={5} lg={6} >
            <Nutrition
              nutrition={beefCut.Nutrients}
            />
          </Grid>
        }
      </Grid>
    </Box>
  )
}

export default BeefProductBrandCreate