import React, { useState } from "react";
import useSWR from "swr";
import { useSelector } from "react-redux";
import { Box, Chip } from "@mui/material";

import periods from "./periods";
import ChartContainer from "../shared/ChartContainer";

const PageViews = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);
  const [selectedPeriod, setSelectedPeriod] = useState(periods[1]);
  let chart = {};

  const pageViewsChartDataPayload = {
    aggregate: {
      count: "*",
    },
    filter: {
      _and: [
        {
          date_created: {
            _gte: selectedPeriod.startDate,
          },
        },
        {
          date_created: {
            _lte: selectedPeriod.endDate,
          },
        },
        showFullData
          ? {}
          : {
              retailer: {
                _eq: retailer.id,
              },
            },
      ],
    },
    groupBy: selectedPeriod.groupBy,
    limit: -1,
  };

  const { data: pageViewsChartData } = useSWR({
    url: "page_views",
    ...pageViewsChartDataPayload,
  });

  if (pageViewsChartData) {
    const labels = selectedPeriod.chartLabelsDateComponents;
    const data = labels.map((label) => {
      const {
        date_created_year,
        date_created_month,
        date_created_day,
        date_created_hour,
      } = label;

      const dataItem = pageViewsChartData.find((pageViewsChartDataItem) => {
        return (
          pageViewsChartDataItem.date_created_year == date_created_year &&
          pageViewsChartDataItem.date_created_month == date_created_month &&
          pageViewsChartDataItem.date_created_day == date_created_day &&
          pageViewsChartDataItem.date_created_hour == date_created_hour
        );
      });

      return dataItem?.count || 0;
    });

    const datasets = [
      {
        label: "Page Views",
        backgroundColor: retailer?.brand_color,
        data,
      },
    ];

    chart = {
      labels: selectedPeriod.chartLabels,
      datasets,
    };
  }

  const onPeriodClick = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <ChartContainer
      title="Page Views"
      description="Review the number of visits to your gateway."
      labels={chart.labels}
      datasets={chart.datasets}
      footerAction={
        <Box
          sx={{
            mb: 2,
          }}
        >
          {periods.map((period) => (
            <Chip
              label={period.label}
              variant={
                selectedPeriod.label === period.label ? "filled" : "outlined"
              }
              size="small"
              sx={{
                mr: 1,
              }}
              onClick={() => {
                onPeriodClick(period);
              }}
            />
          ))}
        </Box>
      }
    />
  );
};

export default PageViews;
