// TODO consider splitting this reducer
const defaultState = {
    beefCuts: [],
    cookingMethods: [],
    categories: [],
    recipes: [],
};

const dataReducer = (state = defaultState, action) => {
    switch(action.type) {
        case 'recipes/update':
            return {
                ...state,
                ...action.payload
            };
        case 'cookingMethods/update':
            return {
                ...state,
                ...action.payload
            };
        case 'categories/update':
            return {
                ...state,
                ...action.payload
            };
        case 'beefCuts/update':
            return {
                ...state,
                beefCuts: action.payload
            };
        default:
            return state;
    }
}

export default dataReducer;