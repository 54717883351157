import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import { getTranslation } from '../../common/utils/translate';
import { updateCookingMethods } from '../../actions/Data';
import ListPage from '../../layouts/ListPage';
import useCountInJunction from '../../common/hooks/useCountInJunction';
import LibraryActions from '../../components/shared/LibraryActions';
import LazyLoad from 'react-lazyload';

const CookingMethods = () => {
  const retailer = useSelector(state => state.retailer);
  const cookingMethods = useSelector(state => state.cookingMethods.data);
  const cookingMethodsStore = useSelector(state => state.cookingMethods);

  const [loading, setLoading] = useState(false);

  const cookingMethodCount = useCountInJunction(
    'retailer_cooking_method',
    {
      aggregate: {
        count: '*'
      },
      filter: {
        _and: [
          { retailer_id: { _eq: retailer.id } }
        ]
      },
    },
    loading
  );

  const dispatch = useDispatch();

  const summaryValues = [
    {
        label: 'Total',
        value: cookingMethodsStore?.meta?.total_count
    },
    {
      label: 'Collected',
      value: cookingMethodCount || '-'
    },
  ];

  useEffect(() => {
    fetchCookingMethods();
  }, []);

  const fetchCookingMethods= async () => {
    try {
      const listCookingMethods = await makeGetRequest();

      dispatch(updateCookingMethods(listCookingMethods));
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const makeGetRequest = async () => {
    return await API.getListCookingMethods();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ListPage
      title='Cooking Methods'
      summaries={summaryValues}
      searchStorageKey={'cooking-methods'}
      searchOnly={['COOKING_METHODS']}
      breadcrumbsLinks={[
        {
          url: '/recipes-menu',
          content: 'Content Library'
        }
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          {cookingMethods?.map((item) => (
            <Grid
              key={item.id}
              item
              xs={12}
              md={3}
              lg={2}
            >
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <LazyLoad
                  height='100%'
                >
                  <ImageCard
                    url={`/cooking-methods/${item.id}`}
                    item={{
                      title: getTranslation(item, { key: 'languages_code', code: 'en-US' }, 'translations')?.Method || ' ',
                      description: getTranslation(item, { key: 'languages_code', code: 'en-US' }, 'translations')?.Description || ' ',
                      url: item.image ? `https://staging.beef.tanglemedia.net/assets/${item.image}` : ''
                    }} 
                    footerAction={
                      <LibraryActions
                        id={item.id}
                        junctionTable='retailer_cooking_method'
                        junctionPayload={{
                          cooking_method_id: item.id,
                          retailer_id: retailer.id
                        }}
                      />
                    }
                  />
                </LazyLoad>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default CookingMethods