import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { TablePagination } from "@mui/material";

import BasicTable from "../../shared/Table";
import BarcodeCardView from "./BarcodeCardView";

const BarcodeView = ({ view, setPage, page, filterObject }) => {
  const [barcodes, setBarcodes] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortValue, setSortValue] = useState("");
  const headers = [
    { id: "code", key: "code", name: "Code" },
    {
      id: "beef_product_id.translations.name",
      key: "product",
      name: "Product",
    },
    {
      id: "owner.translations.retailer_name",
      key: "owner",
      name: "Owner",
    },
    { id: "data_source", key: "data_source", name: "Source" },
    {
      id: "last_verified_by.first_name",
      key: "verified_by",
      name: "Verified By",
    },
    { id: "needs_review", key: "needs_review", name: "Needs Review" },
    {
      id: "count_of_duplicated_code",
      key: "count_of_duplicated_code",
      name: "Duplicated Codes",
      disabled: true,
    },
  ];

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (value) => {
    setSortValue(value);
    setPage(0);
  };

  const { data: barcodeData, isLoading } = useSWR({
    url: `barcode?page=${page + 1}&meta=*`,
    fields: [
      "id",
      "data_source",
      "needs_review",
      "code",
      "owner.translations.retailer_name",
      "beef_product_id.translations.name",
      "last_verified_by.first_name",
      "last_verified_by.last_name",
    ],
    filter: filterObject,
    sort: sortValue ? [sortValue] : [],
    limit: rowsPerPage,
  });
  const { data: barcodeCountData } = useSWR({
    url: `barcode?aggregate[countDistinct][0]=id&groupBy[]=code&limit=-1`,
  });

  useEffect(() => {
    if (barcodeData) {
      setBarcodes(barcodeData);
    }
  }, [barcodeData]);

  return (
    <>
      {view === "table" ? (
        <BasicTable
          handleSort={handleSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          headers={headers}
          items={barcodes?.data?.map(
            ({
              code,
              owner,
              beef_product_id,
              id,
              data_source,
              last_verified_by,
              needs_review,
            }) => {
              const count = barcodeCountData?.find(
                (data) => data?.code === code && data?.countDistinct?.id > 1
              )?.countDistinct?.id;
              return {
                id: id,
                code: code,
                data_source: data_source,
                owner: owner?.translations[0]?.retailer_name,
                product: beef_product_id?.translations[0]?.name,
                verified_by: `${last_verified_by?.first_name ?? ""} ${
                  last_verified_by?.last_name ?? ""
                }`,
                needs_review: needs_review ? "Yes" : "No",
                navigate: true,
                count_of_duplicated_code: count ? count - 1 : "",
              };
            }
          )}
          totalCount={barcodes?.meta?.filter_count}
          isLoading={isLoading}
        />
      ) : (
        <>
          <BarcodeCardView barcodes={barcodes} isLoading={isLoading} />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={barcodes?.meta?.filter_count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default BarcodeView;
