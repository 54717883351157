import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import API from '../../api';

import AddIconImage from '../../shared/svg/plus.svg';

import ImageCard from '../../components/ImageCard';
import ListPage from '../../layouts/ListPage';
import { updateQRCodes } from '../../actions/QRCodesActions';
import { Box } from '@mui/material';

function QRCodes() {
  const dispatch = useDispatch();

  const retailer = useSelector(state => state.retailer);
  
  const qrCodes = useSelector(state => state.qrCodes.data);
  const qrCodesStore = useSelector(state => state.qrCodes);

  const [loading, setLoading] = useState(false);

  const summaryValues = [
    {
      label: 'Total',
      value: 0
    },
  ];

  const fetchQrCodes = async () => {
    setLoading(true);

    try {
      const qrCodesResponse = await makeFetchQrCodesRequest();
      dispatch(
        updateQRCodes(qrCodesResponse)
      )
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchQrCodes();
  }, []);

  const makeFetchQrCodesRequest = async () => {
    const listQrCodes = await API.getQRCode({
      filter: {
        '_and': [
          {
            retailer: {
              '_eq': retailer.id
            }
          }
        ]
      }
    });

    return listQrCodes;
  };

  return (
    <ListPage
      summaries={ summaryValues }
      title={'QR Codes'}
      searchStorageKey={'qr-codes'}
      breadcrumbsLinks={[
        {
          url: '/recipes-menu',
          content: 'Content Library',
        },
        {
          url: '/codes-menu',
          content: 'Barcodes & QR Codes',
        },
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid container spacing={2}>
          {qrCodes.map((qrCode) => (
            <Grid
              key={qrCode.id}
              item
              xs={12}
              md={3}
              lg={2}
            >
              <ImageCard
                url={`/qr-codes/${qrCode.id}`}
                item={{
                  title: qrCode.name,
                  url: `https://chart.googleapis.com/chart?cht=qr&chs=450x450&chl=${qrCode.url}`
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default QRCodes