import data from "./types/data";
import meta from "./types/meta";
import query from "./types/query";

const initialState = {
    data,
    query,
    meta
};

const videosReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'videos/update':
            return {
                ...state,
                ...action.payload
            };

        case 'videos/clear':
            return initialState;

        default:
            return state;
    }
};

export default videosReducer;