import { arrayOf, object, oneOf, shape, string } from 'prop-types';

import { Box, Grid, Typography } from '@mui/material';

import styles from './index.module.scss';

function Videos({ videos, title, content, mode='light', sx={} }) {
  if (!videos.length) return null;

  const background = mode === 'light' ? '#fff' : '#000';
  const color = mode === 'light' ? '#000' : '#fff';
  const border = mode === 'light' ? '' : '1px solid #fff';

  return (
    <Box
      sx={{
        background,
        color,
        width: "100%",
        ...sx,
      }}
    >
      <Grid container>
        {
          videos.map(item => (
            <Grid item
              xs={12}
              md={6}
              lg={videos.length > 1 ? 6 : 12}
              key={item.id}
            >
              <Box
                className={styles.video}
                sx={{
                  width: "100%",
                  background: "#000",
                  alignContent: "center",
                  border,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <video controls
                    className="video-list_img"
                  >
                    <source src={item.sourceUrl} type="video/mp4"></source>
                  </video>
                </Box>
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

Videos.propTypes = {
  content: string,
  title: string,
  sx: object,
  videos: arrayOf(
    shape({
      id: string.isRequired,
      sourceUrl: string.isRequired,
      videoType: oneOf(['vimeo', 'youtube', 'aws']),
    })
  ),
  mode: oneOf(['light', 'dark']),
}

export default Videos