import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';

import { updateRetailer } from '../../actions/Retailer';
import { getTranslation } from '../../common/utils/translate';
import { updateRecipes } from '../../actions/RecipeActions';
import ListPage from '../../layouts/ListPage';
import useFetchFromJunction from '../../common/hooks/useFetchFromJunction';
import LibraryActions from '../../components/shared/LibraryActions';
import LazyLoad from 'react-lazyload';

const RecipeBook = () => {
  const retailer = useSelector(state => state.retailer);
  const filters = useSelector(state => state.filters);
  const recipes = useSelector(state => state.recipes.data);

  const recipesStore = useSelector(state => state.recipes);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const recipesInLibrary = useFetchFromJunction(
    'recipe_retailer',
    {
      aggregate: {
        count: '*'
      },
      filter: {
        _and: [
          { retailer_id: { _eq: retailer.id } }
        ]
      },
    },
    loading
  );

  const filterPayload = {
    filter: {
      _and: [
        {
          recipe_id: {
            _eq: ''
          }
        },
        {
          retailer_id: {
            _eq: ''
          }
        }
      ]
    },
  }

  const [recipeCount] = recipesInLibrary || [];

  let beefProductId = searchParams.get("beef_cut");
  let cookingMethodId = searchParams.get("cooking_method");

  const summaryValues = [
    {
        label: 'Total Recipes',
        value: recipesStore?.meta.total_count
    },
    {
        label: 'Collected',
        value: recipeCount?.count || '-'
    },
  ];

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async () => {
    try {
      const listRecipes = await makeGetRecipesRequest();

      dispatch(updateRecipes(listRecipes));
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }


  const addRecipeToRecipeBook = async (recipeId) => {
    try {
      setLoading(true);

      // get retailer details
      // TODO consider using retailer end point with 
      const userResponse = await API.getUserAndRetailer();

      // save retailer state
      let retailer = userResponse.retailer;
      dispatch(updateRetailer(retailer));

      // get list of recipes
      const payload = {
        recipes: retailer.recipes
      }

      // append
      payload.recipes.push({
        recipe_id: recipeId
      });

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);

      // save retailer state
      dispatch(updateRetailer(retailer));

      // get new list of recipes
      await fetchRecipes();
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const removeRecipeFromRecipeBook = async (recipeId) => {
    try {
      setLoading(true);

      // get retailer details
      // TODO consider using retailer end point with 
      const userResponse = await API.getUserAndRetailer();

      // save retailer state
      let retailer = userResponse.retailer;
      dispatch(updateRetailer(retailer));

      // get list of recipes
      const payload = {
        recipes: retailer.recipes.filter(recipe => recipe.recipe_id !== recipeId)
      }

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);

      // save retailer state
      dispatch(updateRetailer(retailer));

      // get new list of recipes
      await fetchRecipes();
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const makeRetailerPatchRequest = async (payload) => {
    return await API.updateRetailer(payload);
  }

  const makeGetRecipesRequest  = async () => {
    const searchFilters = filters.searchString ? {
      "_or": [
          {
            "cooking_method": {
                "cooking_method_id": {
                    "method": {
                        "_contains": filters.searchString
                    }
                }
            }
          },
          {
            "id": {
                "_nnull": true
            }
          }
      ]
    } : {};

        // TODO refactor this implementation
    if (beefProductId && beefProductId === 'null') beefProductId = JSON.parse(beefProductId);
	  if (cookingMethodId) cookingMethodId = JSON.parse(cookingMethodId);

    return await API.getListRecipe(beefProductId, cookingMethodId, filters.searchString, {
      filter: {
        '_and': ([
          searchFilters,
          ...buildFilterParams(filters)
        ])
      }
    });
  };

  const buildFilterParams = (filters) => { 
    // build filter params
    const filterParams = [];

    if (!!filters.prepTime) filterParams.push({
      prep: {
        '_lte': filters.prepTime
      }
    })

    if (!!filters.cookTime) filterParams.push({
      cooking: {
        '_lte': filters.cookTime
      }
    })

    if (!!filters.chillTime) filterParams.push({
      chill_time: {
        '_lte': filters.chillTime
      }
    })

    if (!!filters.freezingTime) filterParams.push({
      freezing_time: {
        '_lte': filters.freezingTime
      }
    })

    if (!!filters.totalTime) filterParams.push({
      total_time: {
        '_lte': filters.totalTime
      }
    })

    if (!!filters.difficulty) filterParams.push({
      difficulty: {
        '_lte': filters.difficulty
      }
    })

    if (filters.categoryCheckedState.filter(item => item).length) filterParams.push({
      category: {
        recipe_categories_id: {
          '_in': filters.categoryCheckedState.filter(item => item)
        }
      }
    })

    if (filters.cookingMethodCheckedState.filter(item => item).length) filterParams.push({
      cooking_method: {
        cooking_method_id: {
          '_in': filters.cookingMethodCheckedState.filter(item => item)
        }
      }
    })

    if (!!filters.recipeYield) filterParams.push({
      yields: {
        '_lte': filters.recipeYield
      }
    })

    if (filters.ingredients.length) {
      const ingredientsFilter = {
        _or: [
          ...filters.ingredients.map(ingredient => {
            return {
              ingredients_list: {
                '_contains': ingredient
              }
            }
          })
        ]
      };

      filterParams.push(ingredientsFilter);
    }

    return filterParams;
  };

  if (loading) {
    return <Loader />;
  }

  return (
  <ListPage
    summaries={summaryValues}
    title={'Recipes'}
    description={'Tried and true recipes from our test kitchen with no cookbook to buy.  Ordinary ingredients, extraordinary results. Go ahead and take the credit.  We won’t tell anyone where you got the inspiration.'}
    searchOnly={['RECIPES']}
    handleSearchComplete={() => {}}
    searchStorageKey={'recipes'}
    breadcrumbsLinks={[
      {
        url: '/recipes-menu',
        content: 'Content Library',
      },
    ]}
  >
    <Box sx={{ flexGrow: 1, my: 3 }}>
      <Grid container spacing={2}>
        {recipes.map((recipe) => (
          <Grid
            key={recipe.id}
            item
            xs={12}
            md={3}
            lg={2}
          >
            <Box
              sx={{
                height: '100%',
              }}
            >
              <LazyLoad
                height='100%'
              >
                <ImageCard
                  description={ getTranslation(recipe, { key: 'languages_code', code: 'en-US' }, 'layout_translations').summary }
                  url={`/recipes/${recipe.id}`}
                  item={{
                    title: getTranslation(recipe, { key: 'languages_code', code: 'en-US' }, 'layout_translations').name,
                    description: getTranslation(recipe, { key: 'languages_code', code: 'en-US' }, 'layout_translations').summary,
                    url: `https://staging.beef.tanglemedia.net/assets/${recipe?.imagegallery[0]?.directus_files_id}` || ''
                  }} 
                  footerAction={
                    <LibraryActions
                      id={recipe.id}
                      junctionTable='recipe_retailer'
                      junctionPayload={{
                        recipe_id: recipe.id,
                        retailer_id: retailer.id
                      }}
                    />
                  }
                />
              </LazyLoad>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    </ListPage>
  )
}

export default RecipeBook