import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { chooseInputComponent } from '../../../common/utils/components';
import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Container, Divider, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ArrowBack, ArrowBackIos, ArrowForward, ArrowRightAlt, Circle, KeyboardArrowLeft, KeyboardArrowRight, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import LabelAndDisplay from '../../LabelAndDisplay';

function CustomTabPanel(props) {
    const { children, value, index, setValue, pagesLength, finalStageAction, title, subtitle, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Card
                variant='outlined'
            >
                <CardHeader
                    avatar={
                        <Avatar
                            aria-label="wizard-step"
                        >
                            {index + 1}
                        </Avatar>
                    }
                    title={title}
                    subheader={`${index + 1} of ${pagesLength}`}
                    action={(
                        <ButtonGroup>
                            <Button
                                disabled={index === 0}
                                size='small'
                                onClick={() => setValue(index - 1)}
                            >
                                <KeyboardArrowLeft />
                            </Button>

                            <Button
                                disabled={index === (pagesLength - 1)}
                                size='small'
                                onClick={() => setValue(index + 1)}
                            >
                                <KeyboardArrowRight />
                            </Button>
                        </ButtonGroup>
                    )}
                />

                <Divider />

                <CardContent>
                    {value === index && children}
                </CardContent>

                {/* <Divider />

                <CardActions>
                    <Stack
                        direction={'row'}
                        sx={{
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}
                    >
                        <ButtonGroup
                            disabled={index !== pagesLength - 1}
                        >
                            {finalStageAction}
                        </ButtonGroup>
                    </Stack>
                </CardActions> */}
            </Card>
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Wizard = ({ pages = [], finalStageAction }) => {
    const retailer = useSelector(state => state.retailer);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                width: '100%',
                mt: 3,
            }}
        >
            <Grid
                container
                spacing={2}
            >
                <Grid item
                    xs={12}
                    md={8}
                    lg={7}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            mb: 3,
                            display: 'none',
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            centered
                        >
                            {
                                pages?.map((section, index) => (
                                    <Tab
                                        key={section.title}
                                        icon={index === value ? <Circle /> : <RadioButtonUnchecked />}
                                        {...a11yProps(index)}
                                    />
                                ))
                            }
                        </Tabs>
                    </Box>

                    {
                        pages?.map((section, index) => (
                            <CustomTabPanel
                                key={section.title}
                                value={value}
                                index={index}
                                setValue={setValue}
                                pagesLength={pages.length}
                                finalStageAction={finalStageAction}
                                title={section.title}
                                subtitle={section.description}
                            >
                                <Box
                                    sx={{}}
                                >
                                    <Box>
                                        <Grid container>
                                            {section.fields.map(field => (
                                                <Fragment key={field.name}>
                                                    <Grid
                                                        key={field.name}
                                                        item
                                                        {
                                                        ...(['colour'].includes(field?.type) ? {
                                                            xs: 12,
                                                            md: 4,
                                                        } : {
                                                            xs: 12,
                                                        }
                                                        )
                                                        }
                                                    >
                                                        {chooseInputComponent(field)}
                                                    </Grid>
                                                </Fragment>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Box>
                            </CustomTabPanel>
                        ))
                    }
                </Grid>

                <Grid item
                    xs={12}
                    md={4}
                    lg={5}
                >
                    <Box>
                        <Card
                            variant='outlined'
                        >
                            <CardHeader
                                avatar={
                                    <Avatar
                                        aria-label="wizard-step"
                                    >
                                        R
                                    </Avatar>
                                }
                                title={'Review'}
                                subheader={'Confirm before submitting'}
                            />

                            <Divider />

                            <CardContent>
                                {
                                    pages?.map((section, index) => (
                                        <Box
                                            key={section.title}
                                            sx={{ flexGrow: 1, my: 3 }}
                                        >
                                            {
                                                section.fields.map(field => (
                                                    <LabelAndDisplay
                                                        key={field.name}
                                                        label={field.name}
                                                        value={field.value}
                                                    >
                                                        {field.children}
                                                    </LabelAndDisplay>
                                                ))
                                            }
                                        </Box>
                                    ))
                                }
                            </CardContent>
                            
                            <Divider />

                            <CardActions>
                                <ButtonGroup
                                    disabled={value !== pages.length - 1}
                                >
                                    {finalStageAction}
                                </ButtonGroup>
                            </CardActions>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Wizard