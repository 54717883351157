import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';

import { SWRConfig } from 'swr';

import store from './app/store';

import App from './App';

import i18n from './i18n/config';

import { fetcher } from './common/utils/fetcher';

import Coupons from './routes/coupons';
import Dashboard from './routes/dashboard';
import FeaturedProducts from './routes/featured-products';
// import Login from './routes/login';
import Login from './routes/login/index';
import MyBrand from './routes/my-brand';
import QRCodes from './routes/qr-codes';
import QRCodeCreate from './routes/qr-codes/create';
import RecipeBook from './routes/recipe-book';
import UPCBarcodes from './routes/upc-barcodes';
import reportWebVitals from './reportWebVitals';
import Read from './components/CRUD/Read';
import BeefCutCreate from './routes/featured-products/create';
import BeefProductBrands from './routes/beef-product-brands';
import BeefProductBrandCreate from './routes/beef-product-brands/create';
import CookingMethods from './routes/cooking-methods';
import Videos from './routes/videos';
import BeefCuts from './routes/beef-cuts';
import Retailers from './routes/retailers';
import Collections from './routes/Collections';
import Authors from './routes/authors';
import RetailersCreate from './routes/retailers/create';
import QrCodeView from './routes/qr-codes/view';

import './index.scss';
import RecipesMenu from './routes/recipes-menu';
import RecipeView from './routes/recipe-book/view';
import BeefCutsMenu from './routes/beef-cuts-menu';
import BeefCutView from './routes/beef-cuts/view';
import Analytics from './routes/analytics';
import BarCodeDetailsView from './routes/barcodes/View';
import CollectionsMenu from './routes/collections-menu';
import CodesMenu from './routes/codes-menu';
import CBAMenu from './routes/cba-menu';
import CookingMethodView from './routes/cooking-methods/view';
import Cuisine from './routes/cuisine';
import CuisineView from './routes/cuisine/view';
import AuthorView from './routes/authors/view';
import Articles from './routes/articles';
import ArticleView from './routes/articles/view';
import CollectionView from './routes/Collections/view';
import SmartVideosMenu from './routes/smart-videos-menu';
import SmartVideoBatches from './routes/smart-video-batches';
import SmartVideoRenders from './routes/smart-video-renders';
import SmartVideoBatchView from './routes/smart-video-batches/view';
import SmartVideosBatchCreate from './routes/smart-video-batches/create';
import SmartVideoRenderView from './routes/smart-video-renders/view';
import SmartVideoBrands from './routes/smart-video-brands';
import SmartVideoBrandView from './routes/smart-video-brands/view';
import SmartVideoProjects from './routes/smart-video-projects';
import SmartVideoProjectView from './routes/smart-video-projects/view';
import SmartVideosProjectCreate from './routes/smart-video-projects/create';
import SmartVideosBrandCreate from './routes/smart-video-brands/create';
import SmartVideosTemplateCreate from './routes/smart-video-template/create';

i18n.changeLanguage('en');

const root = ReactDOM.createRoot(document.getElementById('root'));

// TODO extract to own file
root.render(
  <Provider  store={store}>
    <BrowserRouter>
      <SWRConfig
        value={{
          fetcher: fetcher
        }}
      >
        <Routes>
          <Route path='/' element={<App />}>
            <Route
              index
              element={<Dashboard />}
            />

            <Route path='recipes-menu' element={<RecipesMenu />} />
            <Route path='beef-cuts-menu' element={<BeefCutsMenu />} />
            <Route path='collections-menu' element={<CollectionsMenu />} />
            <Route path='codes-menu' element={<CodesMenu />} />
            <Route path='cba-menu' element={<CBAMenu />} />

            <Route path='smart-videos'>
              <Route
                index
                element={<SmartVideosMenu />}
              />

              <Route
                path='templates'
              >
                <Route
                  index
                  element={<SmartVideoBatches />}
                />

                <Route
                  path={':id'}
                  element={<SmartVideoBatchView />}
                />

                <Route
                  path={'edit/:id'}
                  element={<SmartVideosTemplateCreate />}
                />

                <Route
                  path={'create'}
                  element={<SmartVideosTemplateCreate />}
                />
              </Route>

              <Route
                path='batches'
              >
                <Route
                  index
                  element={<SmartVideoBatches />}
                />

                <Route
                  path={':id'}
                  element={<SmartVideoBatchView />}
                />

                <Route
                  path={'edit/:id'}
                  element={<SmartVideosBatchCreate />}
                />

                <Route
                  path={'create'}
                  element={<SmartVideosBatchCreate />}
                />
              </Route>

              <Route
                path='renders'
              >
                <Route
                  index
                  element={<SmartVideoRenders />}
                />

                <Route
                  path={':id'}
                  element={<SmartVideoRenderView />}
                />

                <Route
                  path={'create'}
                  element={<SmartVideosBatchCreate />}
                />
              </Route>

              <Route
                path='brands'
              >
                <Route
                  index
                  element={<SmartVideoBrands />}
                />

                <Route
                  path={':id'}
                  element={<SmartVideoBrandView />}
                />

                <Route
                  path={'edit/:id'}
                  element={<SmartVideosBrandCreate />}
                />

                <Route
                  path={'create'}
                  element={<SmartVideosBatchCreate />}
                />
              </Route>

              <Route
                path='projects'
              >
                <Route
                  index
                  element={<SmartVideoProjects />}
                />

                <Route
                  path={':id'}
                  element={<SmartVideoProjectView />}
                />

                <Route
                  path={'edit/:id'}
                  element={<SmartVideosProjectCreate />}
                />

                <Route
                  path={'create'}
                  element={<SmartVideosProjectCreate />}
                />
              </Route>
            </Route>

            <Route path='coupons'>
              <Route
                index
                element={
                  <Read
                    title={'Coupons'}
                  >
                    <Coupons />
                  </Read>
                }
              />
            </Route>
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='featured-products' element={<FeaturedProducts />} />
            <Route path='beef-product-brands'>
              <Route
                index
                element={<BeefProductBrands />}
              />
              <Route path={'create'} element={<BeefProductBrandCreate />} />
              <Route path={':beefProductBrandId'} element={<BeefProductBrandCreate />} />
            </Route>
            <Route path='beef-product-overrides'>
              <Route
                index
                element={<BeefProductBrands />}
              />
              <Route path={'create'} element={<BeefProductBrandCreate />} />
              <Route path={':beefProductBrandId'} element={<BeefProductBrandCreate />} />
            </Route>
            <Route path='analytics'>
              <Route
                index
                element={<Analytics />}
              />
            </Route>
            <Route path='barcodes/:barcodeId'>
              <Route
                index
                element={<BarCodeDetailsView />}
              />
            </Route>
            <Route path='beef-cuts'>
              <Route
                index
                element={<BeefCuts />}
              />
              <Route path={'create'} element={<BeefCutCreate />} />
              <Route path={':beefCutId'} element={<BeefCutView />} />
            </Route>
            <Route path='videos'>
              <Route
                index
                element={<Videos />}
              />
              <Route path={'create'} element={<BeefCutCreate />} />
              <Route path={':beefCutId'} element={<BeefCutView />} />
            </Route>
            <Route path='cooking-methods'>
              <Route
                index
                element={<CookingMethods />}
              />
              <Route path={'create'} element={<BeefCutCreate />} />
              <Route path={':cookingMethodId'} element={<CookingMethodView />} />
            </Route>
            <Route path='cuisine'>
              <Route
                index
                element={<Cuisine />}
              />
              <Route path={'create'} element={<BeefCutCreate />} />
              <Route path={':id'} element={<CuisineView />} />
            </Route>
            <Route path='collections'>
              <Route
                index
                element={<Collections />}
              />
              <Route path={'create'} element={<BeefCutCreate />} />
              <Route path={':id'} element={<CollectionView />} />
            </Route>
            <Route path='articles'>
              <Route
                index
                element={<Articles />}
              />
              <Route path={':id'} element={<ArticleView />} />
            </Route>
            <Route path='authors'>
              <Route
                index
                element={<Authors />}
              />
              <Route path={'create'} element={<BeefCutCreate />} />
              <Route path={':id'} element={<AuthorView />} />
            </Route>
            <Route path='login' element={<Login />} />
            <Route path='my-brand' element={<MyBrand />} />
            <Route path='qr-codes'>
              <Route
                index
                element={<QRCodes />}
              />
              <Route path='create' element={<QRCodeCreate />} />
              <Route path=":qrCodeId" element={<QrCodeView />} />
            </Route>
            <Route path='recipe-book' element={<RecipeBook />}>
              <Route
                index
                element={<RecipeBook />}
              />
            </Route>
            <Route path='recipes'>
              <Route
                index
                element={<RecipeBook />}
              />
              <Route path='create' element={<QRCodeCreate />} />
              <Route path=":recipeId" element={<RecipeView />} />
            </Route>
            <Route path='barcodes' element={<UPCBarcodes />} />
            <Route path='retailers' element={<Retailers />} />
            <Route path='create' element={<RetailersCreate />} />
          </Route>
        </Routes>
      </SWRConfig>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
