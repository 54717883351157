import { Fragment } from "react";

import useSWR from 'swr';

import { Box, Divider, IconButton, Input, List, ListItem, ListItemText, Stack } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

import './index.scss';
import { videoToolkitFetcher } from "../../common/utils/fetcher";

const RelationshipListItem = ({ item }) => {
    const { data } = useSWR({
        url: item.url,
    }, videoToolkitFetcher);
    
    let formattedItem = {};

    if (data) {
        formattedItem = item.responseHandler(data);
    }

    return (
        <Fragment>
            <Divider />
            <ListItem
                sx={{
                    pl: 0,
                }}
            >
                <ListItemText
                    primary={formattedItem.title}
                />
            </ListItem>

            {
                !!formattedItem.actions && 
                <Box
                    sx={{
                        mb: 1,
                    }}
                >
                    { formattedItem.actions }
                </Box>
            }
        </Fragment>
    )
}

const RelationshipList = (props) => {
    const { items } = props;

    return (
        <List>
            {
                items.map((item, index) => (
                    <Fragment
                        key={item.id}
                    >
                     <RelationshipListItem
                        item={item}
                     />   
                    </ Fragment>
                ))
            }
        </List>
    );
}

export default RelationshipList