import { useState } from 'react';

import useSWR from 'swr';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import { getTranslation } from '../../common/utils/translate';
import ListPage from '../../layouts/ListPage';
import useCountInJunction from '../../common/hooks/useCountInJunction';
import { getAuthorDisplayName } from '../../utils/author';

const Authors = () => {
    const filter = {
        _and: [
            {
                status: {
                    _eq: 'published'
                }
            },
        ]
    };

    const payload = {
        fields: [
            '*',
            'translations.*',
        ],
        filter,
        limit: -1,
    };

    let { data: authors } = useSWR({
        url: 'recipe_author',
        ...payload,
    });

    if (authors) {
        authors = authors.map((item) => {
            const translatedItem = getTranslation(item, { key: 'languages_code', code: 'en-US' });
            const image = item.image ? `${process.env.REACT_APP_API_URL}/assets/${item.image}` : null;

            return {
                title: getAuthorDisplayName(item),
                description: item?.biography,
                image,
                url: `/authors/${item.id}`,
            };
        });
    }

    const [loading, setLoading] = useState(false);

    const authorCount = useCountInJunction(
        'recipe_author',
        {
            aggregate: {
                count: '*'
            },
            filter,
        },
        loading,
    );

    const summaryValues = [
        {
            label: 'Total',
            value: authorCount || '-'
        },
    ];

    if (loading) {
        return <Loader />;
    }

    return (
        <ListPage
            title='Authors'
            summaries={summaryValues}
            searchStorageKey={'authors'}
            handleSearchComplete={() => { }}
            searchOnly={['AUTHORS']}
            breadcrumbsLinks={[
                {
                    url: '/collections-menu',
                    content: 'Collections & Cuisine'
                }
            ]}
        >
            <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid sx={{ mt: 4 }} container spacing={2}>
                    {authors?.map(item => (
                        <Grid
                            item
                            key={item.id}
                            xs={12}
                            md={3}
                            lg={2}
                        >
                            <ImageCard
                                url={item.url}
                                item={{
                                    title: item.title,
                                    description: item.description,
                                    url: item.image,
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </ListPage>
    )
}

export default Authors