export const handleResponseError = (error) => {
    let errorMessages = [];

    if (error.response) {
        // if error 403 fhen redirect to login page
        if ([401, 403].includes(error.response.status)) {
            const currentUrl = window.location.pathname;
            window.location.href = `/login?redirect=${currentUrl}`;
        }

        errorMessages = [...error.response?.data?.errors, ...errorMessages];
    } else if (error.request) {
        errorMessages.push({
            message: 'Network error'
        });
    } else {
        errorMessages.push({
            message: error.message || 'Failed to setup request'
        });
    }
    return errorMessages;
};