import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GridOn, TableChart } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Checkbox,
} from "@mui/material";

import BarcodeView from "../BarcodeView";

const BarcodeList = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(0);
  const [view, setView] = useState("table");
  const retailerId = retailer?.id;

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Barcode List
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ my: 1 }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            List of all barcodes
          </Typography>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ my: 1 }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
              <Typography variant="p">Show noticed codes only</Typography>
            </label>

            <Tooltip title={view === "table" ? "Card View" : "Table View"}>
              <IconButton
                onClick={() => {
                  setView(view === "table" ? "card" : "table");
                }}
                aria-label="view"
                size="large"
              >
                {view === "table" ? (
                  <GridOn fontSize="inherit" />
                ) : (
                  <TableChart fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>

      <BarcodeView
        view={view}
        setPage={setPage}
        page={page}
        filterObject={
          showFullData
            ? checked
              ? {
                  needs_review: {
                    _eq: true,
                  },
                }
              : {}
            : checked
            ? {
                _and: [
                  {
                    owner: {
                      id: {
                        _eq: retailerId,
                      },
                    },
                  },
                  {
                    needs_review: {
                      _eq: true,
                    },
                  },
                ],
              }
            : {
                owner: {
                  id: {
                    _eq: retailerId,
                  },
                },
              }
        }
      />
    </Box>
  );
};

export default BarcodeList;
