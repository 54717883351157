import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import ImageCard from '../../components/ImageCard';
import Loader from '../../components/Loader';
import API from '../../api';
import { getTranslation } from '../../common/utils/translate';
import LinkToCreate from '../../components/LinkToCreate';
import { Link } from 'react-router-dom';
import { updateBeefProductBrands } from '../../actions/BeefProductBrandsActions';
import ListPage from '../../layouts/ListPage';

const BeefProductBrands = () => {
  const retailer = useSelector(state => state.retailer);
  const beefProductBrands = useSelector(state => state.beefProductBrands.data);
  const beefProductBrandsStore = useSelector(state => state.beefProductBrands);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const summaryValues = [
    {
        label: 'Total',
        value: beefProductBrandsStore?.meta?.total_count
        // value: beefCuts.length
    },
    {
        label: 'New',
        value: 0,
    },
  ];

  useEffect(() => {
    fetchBeefProductBrands();
  }, []);

  const fetchBeefProductBrands = async () => {
    try {
      const listBeefProductBrands = await makeGetRequest();

      dispatch(updateBeefProductBrands(listBeefProductBrands));
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const makeGetRequest = async () => {
    return await API.getListBeefProductBrands();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ListPage
      title='Beef Product Brands'
      summaries={summaryValues}
      breadcrumbsLinks={[
        {
          url: '/',
          content: 'Content Library',
        },
        {
          url: '/beef-cuts-menu',
          content: 'Beef Cuts Menu',
        }
      ]}
    >
      <Box sx={{ flexGrow: 1, my: 3 }}>
        <Grid sx={{ mt: 4 }} container spacing={2}>
          {beefProductBrands?.map((beefCut) => (
            <Grid key={beefCut.id} item xs={6} md={3} lg={2}>
              <ImageCard
                item={{
                  title: getTranslation(beefCut, { key: 'languages_code', code: 'en-US' }, 'translations')?.name || ' ',
                  url: beefCut.image ? `https://staging.beef.tanglemedia.net/assets/${beefCut.image}` : ''
                }} 
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default BeefProductBrands