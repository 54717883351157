import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { Grid, Stack } from '@mui/material';

import './index.scss';

const LabelAndDisplay = ({ label = '', direction='row', value, children, variant='default' }) => {
    const retailer = useSelector(state => state.retailer);
    const isRow = direction === 'row';

    let content = null;

    switch (variant) {
        case 'table':
            content = (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Box
                        sx={{
                            borderLeft: `4px solid ${retailer?.brand_color || 'rgb(51, 51, 51)'}`,
                            pl: 1
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                color: 'rgba(0, 0, 0, 0.64)'
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            overflowX: 'hidden',
                            textWrap: 'none',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                textWrap: 'none'
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>
                </Stack>
            );
            break;
        default:
            content = (
                <Grid container
                    spacing={1}
                >
                    <Grid item
                        xs={12}
                        md={isRow ? 4 : 12}
                        lg={isRow ? 3 : 12}
                    >
                        <Box
                            sx={{
                                borderLeft: `4px solid ${retailer?.brand_color || 'rgb(51, 51, 51)'}`,
                                pl: 1
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    color: 'rgba(0, 0, 0, 0.64)'
                                }}
                            >
                                {label}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item
                        xs={12}
                        md={isRow ? 8 : 12}
                        lg={isRow ? 9 : 12}
                        sx={{
                            textWrap: 'none'
                        }}
                    >
                        {
                            value &&
                            <Box
                                sx={{
                                    overflowX: 'hidden',
                                    textWrap: 'none'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        textWrap: 'none'
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Box>
                        }
                        {
                            children &&
                            <Stack
                                alignItems={isRow ? 'flex-start' : 'center'}
                                sx={{
                                    width: '100%',
                                }}
                            >
                                {children}
                            </Stack>
                        }
                    </Grid>
                </Grid>
            )
    }

    return (
        <Box
            className='section-title'
            sx={{
                my: 2
            }}
        >
            {content}
        </Box>
    )
}

export default LabelAndDisplay