import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import API from '../../api';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

import Wysiwyg from '../../components/shared/Wysiwyg';
import RecipeVideos from '../../components/shared/RecipeVideos';

import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translate';
import Nutrition from '../../components/Nutrition';

function BeefCutView() {
  useRefreshToken();

  const params = useParams();  
  const { t } = useTranslation();

  const language = {
    code: 'en-US',
  };

  const retailer = useSelector(state => state.retailer);
  
  const recipeId = params.beefCutId;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  
  const [fields, setFields] = useState([]);
  const [sidebarFields, setSidebarFields] = useState([]);

  const [loading, setLoading] = useState(false);

  const actionOptions = [
    {
      key: 'Add to Library',
      content: 'Add to Library ',
      onClick: async () => {
        await addRecipeToRecipeBook(recipeId);
        makeGetQrCodeRequest();

      },
    },
    {
      key: 'Remove from Library',
      content: 'Remove from Library ',
      onClick: async () => {
        await removeRecipeFromRecipeBook(recipeId);
        makeGetQrCodeRequest();
      },
    },
  ];

  useEffect(() => {
    if (!recipeId) return;

    makeGetQrCodeRequest();
  }, [recipeId]);

  const makeGetQrCodeRequest = async () => {
    try {
      setLoading(true);

      const recipeResponse = await API.getBeefProduct(recipeId);
      const nutrition = recipeResponse.Nutrients?.id ? await API.getNutrition(recipeResponse.Nutrients.id) : {};

      const translatedRecipe = getTranslation(recipeResponse, { key: 'languages_id', code: 'en-US' });
      const wholesaleSettings = getTranslation(recipeResponse, { key: 'languages_code', code: 'en-US' }, 'primal_configurations');

      const beefCutImage = recipeResponse.image ? `https://staging.beef.tanglemedia.net/assets/${recipeResponse.image}` : '';
      
      setTitle(translatedRecipe.name);

      setFields([
        {
          key: 'Name',
          value: translatedRecipe.name
        },
        {
          key: 'Description',
          children: (
            <Typography>
              <Wysiwyg>{translatedRecipe.description}</Wysiwyg>
            </Typography>
          )
        },
        {
          key: 'Specifications',
          children: (
            <Typography>
              <Wysiwyg>{translatedRecipe.specifications}</Wysiwyg>
            </Typography>
          )
        },
        ...(
          wholesaleSettings.key_information ?
          [
            {
              key: 'Key Information',
              children: (
                <Typography>
                  <Wysiwyg>{wholesaleSettings.key_information}</Wysiwyg>
                </Typography>
              )
            },
          ] : []
        ),
        ...(
          wholesaleSettings.purchasing_content ?
          [
            {
              key: 'Purchasing Content',
              children: (
                <Typography>
                  <Wysiwyg>{wholesaleSettings.purchasing_content}</Wysiwyg>
                </Typography>
              )
            },
          ] : []
        ),
        ...(
          wholesaleSettings.merchandising_content ?
          [
            {
              key: 'Merchandising Content',
              children: (
                <Typography>
                  <Wysiwyg>{wholesaleSettings.merchandising_content}</Wysiwyg>
                </Typography>
              )
            },
          ] : []
        )
      ]);

      setSidebarFields([
        {
          key: 'Image',
          children: (
            <ImageContainer
              url={beefCutImage}
              alt={translatedRecipe.name}
              sx={{
                height: '20rem',
              }}
            />
          )
        },
        ...(
          nutrition.id ? 
          [
              {
                key: 'Nutrition',
                children: (
                  <Nutrition nutrition={nutrition} recipe={recipeResponse} />
                )
              },
          ] : []
        )
      ])
    } catch (error) {
      console.log('error: ', error);
      notify('error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  const makeRetailerPatchRequest = async (payload) => {
    return await API.updateRetailer(payload);
  }

  const addRecipeToRecipeBook = async (recipeId) => {
    try {
      setLoading(true);

      // get list of recipes
      const payload = {
        recipes: retailer.recipes
      }

      // append
      payload.recipes.push({
        recipe_id: recipeId
      });

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const removeRecipeFromRecipeBook = async (recipeId) => {
    try {
      setLoading(true);

      // get list of recipes
      const payload = {
        recipes: retailer.recipes.filter(recipe => recipe.recipe_id !== recipeId)
      }

      // make PATCH request
      retailer = await makeRetailerPatchRequest(payload);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={ title }
        description={ description }
        actionOptions={ actionOptions }
        breadcrumbsLinks={[
          {
            url: '/recipes-menu',
            content: 'Content Library',
          },
          {
            url: `/beef-cuts`,
            content: 'Beef Cuts',
          }
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>            
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          { field.children }
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid> 
                </Grid> 
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          { field.children }
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid> 
                </Grid> 
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default BeefCutView