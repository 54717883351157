import { useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";

const FilterButton = ({
  handleClear,
  setPage,
  setFilterObject,
  showFullData,
  selectedRetailers,
  selectedProducts,
  selectedSources,
  selectedDate,
  selectedUsers,
  handleClose,
}) => {
  const retailer = useSelector((state) => state.retailer);
  const retailerId = retailer?.id;

  const handleFilter = (
    retailers,
    products,
    sources,
    selectedDate,
    selectedUsers,
    closeFilterMenu
  ) => {
    setPage(0);

    // sources
    if (sources?.length > 0) {
      setFilterObject((filterObject) => ({
        ...filterObject,
        data_source: {
          _in: sources,
        },
      }));
    } else {
      setFilterObject((filterObject) => {
        delete filterObject?.data_source;
        return {
          ...filterObject,
        };
      });
    }

    // retailers
    if (retailers?.length > 0) {
      // 0 = ANY
      if (!retailers?.find(({ id }) => id === 0)) {
        // 1 = NONE
        if (retailers?.find(({ id }) => id === 1)) {
          setFilterObject((filterObject) => ({
            ...filterObject,
            owner: {
              _null: true,
            },
          }));
        } else {
          setFilterObject((filterObject) => ({
            ...filterObject,
            owner: {
              id: {
                _in: retailers?.map(({ id }) => id),
              },
            },
          }));
        }
      } else {
        setFilterObject((filterObject) => {
          return {
            ...filterObject,
            owner: {
              _nempty: true,
            },
          };
        });
      }
    } else {
      setFilterObject((filterObject) => {
        showFullData && delete filterObject?.owner;
        if (!showFullData) {
          filterObject.owner = {
            id: {
              _eq: retailerId,
            },
          };
        }
        return {
          ...filterObject,
        };
      });
    }

    // Verify Date
    if (selectedDate) {
      setFilterObject((filterObject) => ({
        ...filterObject,
        _or: [
          {
            last_verified: {
              _lte: selectedDate,
            },
          },
          {
            last_verified: {
              _null: true,
            },
          },
        ],
      }));
    }

    // products
    if (products?.length > 0) {
      // 0 = ALL
      if (!products?.find(({ id }) => id === 0)) {
        // 1 = NONE
        if (products?.find(({ id }) => id === 1)) {
          setFilterObject((filterObject) => ({
            ...filterObject,
            beef_product_id: {
              _null: true,
            },
          }));
        } else {
          setFilterObject((filterObject) => ({
            ...filterObject,
            beef_product_id: {
              id: {
                _in: products?.map(({ id }) => id),
              },
            },
          }));
        }
      } else {
        setFilterObject((filterObject) => {
          delete filterObject?.beef_product_id;
          return {
            ...filterObject,
          };
        });
      }
    } else {
      setFilterObject((filterObject) => {
        delete filterObject?.beef_product_id;
        return {
          ...filterObject,
        };
      });
    }

    // Verified by USER
    if (selectedUsers?.length > 0) {
      // 0 = ANY
      if (!selectedUsers?.find(({ id }) => id === 0)) {
        setFilterObject((filterObject) => ({
          ...filterObject,
          last_verified_by: {
            id: {
              _in: selectedUsers?.map(({ id }) => id),
            },
          },
        }));
      } else {
        setFilterObject((filterObject) => {
          delete filterObject?.last_verified_by;
          return {
            ...filterObject,
            last_verified_by: {
              _nempty: true,
            },
          };
        });
      }
    } else {
      setFilterObject((filterObject) => {
        delete filterObject?.last_verified_by;
        return {
          ...filterObject,
        };
      });
    }

    closeFilterMenu();
  };

  const filterOnClick = () => {
    handleFilter(
      selectedRetailers,
      selectedProducts,
      selectedSources?.map(({ name }) => name),
      selectedDate,
      selectedUsers,
      handleClose
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing="4px"
      sx={{ mb: "4px", px: 2, pt: "4px" }}
    >
      <Button
        sx={{
          borderRadius: 0,
          backgroundColor: "#C31E39",
          fontFamily: "Oswald",
          height: "48px",
          textTransform: "uppercase",
          ":hover": {
            bgcolor: "#C31E39",
          },
        }}
        variant="contained"
        onClick={handleClear}
      >
        Clear
      </Button>
      <Button
        sx={{
          borderRadius: 0,
          backgroundColor: "rgb(191, 215, 48)",
          fontFamily: "Oswald",
          height: "48px",
          textTransform: "uppercase",
          ":hover": {
            bgcolor: "rgb(191, 215, 48)",
          },
        }}
        variant="contained"
        onClick={filterOnClick}
      >
        Filter
      </Button>
    </Stack>
  );
};

export default FilterButton;
