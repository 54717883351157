export const updateRecipes  = (payload) => {
    return {
        type: 'recipes/update',
        payload
    }
};

export const updateCookingMethods = (payload) => {
    return {
        type: 'cookingMethods/update',
        payload
    }
};

export const updateCategories = (payload) => {
    return {
        type: 'categories/update',
        payload
    }
};

export const updateBeefCuts = (payload) => {
    return {
        type: 'beefCuts/update',
        payload
    }
};