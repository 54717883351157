import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";

import api from "../../../api";
import { updateRetailer } from "../../../actions/Retailer";
import Loader from "../../Loader";
import notify from "../../Notification/helper";

const UserMenu = ({ sx = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const retailer = useSelector((state) => state.retailer);
  const isAdminUser = api.isAdmin(user);

  const open = Boolean(anchorEl);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    minWidth: "250px",
    maxWidth: "1000px",
    bgcolor: "white",
    p: 4,
    border: "none",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRetailer = () => {
    setModalOpen(true);
  };

  const getRetailers = async () => {
    setLoading(true);
    try {
      let res = await api.getListRetailer();
      if (res && res?.length > 0) {
        setOptions(res);
      }
      setLoading(false);
    } catch (err) {
      notify("error", err?.message || "Failed to update retailer");
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!selected) {
      return;
    } else {
      setLoading(true);
      try {
        await api.updateAdminRetailer({
          id: user?.id,
          retailer: selected,
        });
        const userResponse = await api.getUserAndRetailer();
        const newRetailer = userResponse.retailer;
        dispatch(updateRetailer(newRetailer));
        setLoading(false);
        setModalOpen(false);
      } catch (err) {
        notify("error", err?.message || "Failed to update retailer");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (retailer) {
      setSelected(retailer?.id);
    }
    isAdminUser && getRetailers();
  }, []);

  return (
    <>
      <Box sx={sx}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{
              p: 0,
            }}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                textTransform: "uppercase",
                background: retailer.button_background_color,
              }}
            >
              {user?.first_name?.slice(0, 1)}
            </Avatar>
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Typography>Logout</Typography>
            </Link>
          </MenuItem>
          {isAdminUser ? (
            <MenuItem onClick={handleChangeRetailer}>
              <ListItemIcon>
                <ChangeCircleOutlinedIcon fontSize="small" />
              </ListItemIcon>

              <Typography>Change Retailer</Typography>
            </MenuItem>
          ) : (
            ""
          )}
        </Menu>
      </Box>
      <Modal open={modalOen} onClose={() => setModalOpen(false)}>
        {loading ? (
          <Loader />
        ) : (
          <Box sx={style}>
            <>
              <Typography
                sx={{ fontWeight: "600", mb: 3 }}
                variant="h6"
                component="h2"
              >
                CHOOSE A RETAILER
              </Typography>

              <select
                value={selected}
                onChange={(event) => {
                  setSelected(event?.target?.value);
                }}
                style={{
                  width: "100%",
                  padding: "5px",
                  height: "40px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              >
                <option value="" disabled>
                  Select a retailer
                </option>
                {options.map((option) => (
                  <option
                    style={{
                      fontSize: "16px",
                    }}
                    key={option?.id}
                    value={option?.id}
                  >
                    {option?.translations?.[0]?.retailer_name || ""}
                  </option>
                ))}
              </select>
              <Button
                disabled={loading}
                onClick={handleSubmit}
                variant="contained"
                sx={{ mt: 5 }}
              >
                Save
              </Button>
            </>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default UserMenu;
