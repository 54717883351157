import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';


import DescriptionHolder from '../DescriptionHolder';

import './Nutrition.scss';
import { getTranslation } from '../../common/utils/translate';

const Nutrition = ({ nutrition, recipe = {} }) => {
  // TODO use state selector
  const language = {
    code: 'en-US',
    name: 'English'
  };

  const { t } = useTranslation();

  const translatedNutrition = getTranslation(nutrition, { key: 'languages_code', code: language.code });
  const frenchTranslatedNutrition = getTranslation(nutrition, { key: 'languages_code', code: 'fr-FR' });

  const bilingualTranslate = (key) => {
    return t(key) + ' / ' + t(key, { lng: 'fr-FR' });
  }

  const buildFatChildData = () => {
    // set defaults
    let saturated = {
      label: 'Saturated / saturés',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    let trans = {
      label: 'Trans / trans',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    // check if values exist
    const saturatedFats = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0012-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));
    const transFats = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0015-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));

    // build label
    if (!!saturatedFats?.length) {
      saturated = {
        ...saturatedFats[0],
        label: 'Saturated / saturés',
      };
    }

    if (!!transFats?.length) {
      trans = {
        ...transFats[0],
        label: '+ Trans / trans',
      };
    }

    const saturatedMass = parseFloat(saturated.value) || 0;
    const transMass = parseFloat(trans.value) || 0;

    // add %s
    return {
      label: (
        saturated.label + ' ' + saturated.value + ' ' + saturated.unit + "<br />" 
        + trans.label + ' ' + trans.value + ' ' + trans.unit
      ),
      value: 0,
      unit: '',
      percentage: (
        saturatedMass + transMass
      ) / 20 * 100
    }
  }

  const buildCarbohydratesChildData = () => {
    // set defaults
    let fibre = {
      label: 'Fibre / Fibres',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    let sugar = {
      label: 'Sugars / Sucres',
      value: 0,
      unit: 'g',
      percentage: 0
    };

    // check if values exist
    const fibres = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0003-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));
    const sugars = translatedNutrition?.macronutrients?.filter(nutrient => ['84a8709a-0006-0000-ebf9-90cea7d9d44f'].includes(nutrient.id));

    // build label
    if (!!fibres?.length) {
      fibre = fibres[0];
    }

    if (!!sugars?.length) {
      sugar = sugars[0];
    }

    // add %s
    return [
      {
        ...fibre,
        label: 'Fibre / Fibres',
      },
      {
        ...sugar,
        label: 'Sugars / Sucres',
      }
    ]
  }

  const proteinIds = ['84a8709a-0001-0000-ebf9-90cea7d9d44f'];
  const fatIds = ['84a8709a-0011-0000-ebf9-90cea7d9d44f'];
  const carbohydrateIds = ['84a8709a-0002-0000-ebf9-90cea7d9d44f'];
  const sodiumIds = ['84a8709a-003c-0000-ebf9-90cea7d9d44f'];
  const cholesterolIds = ['84a8709a-0016-0000-ebf9-90cea7d9d44f'];

  const proteinChildren = translatedNutrition?.macronutrients?.filter(nutrient => proteinIds.includes(nutrient.id)) || [];
  const fatChildren = [
    ...(translatedNutrition?.macronutrients?.filter(nutrient => fatIds.includes(nutrient.id)) || []),
    buildFatChildData()
  ];

  const carbohydratesChildren = [
    ...(translatedNutrition?.macronutrients?.filter(nutrient => carbohydrateIds.includes(nutrient.id)) || []),
    ...buildCarbohydratesChildData()
  ];

  const getMicronutrient = (id) => {
    const nutrients = frenchTranslatedNutrition?.micronutrients?.filter(nutrient => [id].includes(nutrient.id));

    if (!!nutrients?.length) {
      return nutrients[0];
    } else {
      return {};
    }
  }
  
  const sodiumChildren = translatedNutrition?.macronutrients?.filter(nutrient => sodiumIds.includes(nutrient.id));
  const cholesterolChildren = translatedNutrition?.macronutrients?.filter(nutrient => cholesterolIds.includes(nutrient.id));

  const protein = proteinChildren?.splice(0, 1)[0] || {};
  const fat = fatChildren?.splice(0, 1)[0] || {};
  const carbohydrates = carbohydratesChildren?.splice(0, 1)[0] || {};
  const sodium = sodiumChildren?.splice(0, 1)[0] || {};
  const cholesterol = cholesterolChildren?.splice(0, 1)[0] || {};

  const nutritionalInformation = {
    macro: [
      {
        label: bilingualTranslate('nutrition_fat'),
        mass: !isNaN(fat?.value) ? (fat?.value + ' ' + fat?.unit) : '0 g',
        percentage: !isNaN(fat?.percentage) ? `${Math.round(fat?.percentage)} %` : '0 %',
        children: fatChildren
      },
      {
        label: bilingualTranslate('nutrition_carbohydrates'),
        mass: !isNaN(carbohydrates?.value) ? (carbohydrates?.value + ' ' + carbohydrates?.unit) : '0 g',
        percentage: !isNaN(carbohydrates?.percentage) ? `${Math.round(carbohydrates?.percentage)} %` : '',
        children: carbohydratesChildren
      },
      {
        label: bilingualTranslate('nutrition_protein'),
        mass: !isNaN(protein?.value) ? (protein?.value + ' ' + protein?.unit) : '0 g',
        percentage: !isNaN(protein?.percentage) ? `${Math.round(protein?.percentage)} %` : '',
        children: proteinChildren
      },
      {
        label: bilingualTranslate('nutrition_cholesterol'),
        mass: !isNaN(cholesterol?.value) ? (cholesterol?.value + ' ' + cholesterol?.unit) : '0 mg',
        percentage: !isNaN(cholesterol?.percentage) ? `${Math.round(cholesterol?.percentage)} %` : '',
        children: cholesterolChildren
      },
      {
        label: t('nutrition_sodium', { lng: 'en-US'}),
        mass: !isNaN(sodium?.value) ? (sodium?.value + ' ' + sodium?.unit) : '0 mg',
        percentage: !isNaN(sodium?.percentage) ? `${Math.round(sodium?.percentage)} %` : '0 %',
        children: sodiumChildren
      },
    ],
    micro: [
      {
        id: '84a8709a-003a-0000-ebf9-90cea7d9d44f',
        label: 'Potassium'
      },
      {
        id: '84a8709a-002f-0000-ebf9-90cea7d9d44f',
        label: 'Calcium'
      },
      {
        id: '84a8709a-0035-0000-ebf9-90cea7d9d44f',
        label: 'Iron / Fer'
      }
    ],
  };

  return (
    <Box
      className='nutrition'
    >
      <Box>
        {
          !!translatedNutrition?.description &&
          <Typography 
              className='search-message'
              sx={{
                fontWeight: 300,
                fontSize: '18px'
              }}
              component="div">
              <DescriptionHolder
                content={translatedNutrition?.description}
                allowedTags={['img']}
              />
          </Typography>
        }

        <Stack>
          <Grid
            container
            justifyContent="center"
            sx={{
              mt: 3
            }}
          >
            <Grid item>
              <Box className="table">
                <Box className="table_title">{t('nutrition_facts')}</Box>
                <Box className="table_title">{t('nutrition_facts', { lng: 'fr-FR' })}</Box>
                <Box
                  className="table_serving-size border-btm-slim"
                >
                  <Box className="table_serving">
                    {!!recipe.id ? t('perServing') : t('per')}&nbsp;
                    {!!recipe.id ? '(' + (translatedNutrition.portion_size || nutrition.portion_size || '0 g') + ')' : translatedNutrition.portion_size || nutrition.portion_size}&nbsp;
                    { !!recipe.id ? '' : t('cooked') }
                  </Box>
                  <Box className="table_serving">
                    {!!recipe.id ? t('perServing', { lng: 'fr-FR' }) : t('per', { lng: 'fr-FR' }) }&nbsp;
                    {!!recipe.id ? '(' + (translatedNutrition.portion_size || nutrition.portion_size || '0 g') + ')' : translatedNutrition.portion_size || nutrition.portion_size }&nbsp;
                    { !!recipe.id ? '' : t('cooked', { lng: 'fr-FR' }) }
                  </Box>
                </Box>

                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  spacing={1}
                >
                  <Typography
                    className="table_block-calories"
                    sx={{
                      fontSize: {
                        xs: '1.1rem',
                        md: '1.35rem',
                        lg: '1.75rem',
                      },
                      fontWeight: 700
                    }}
                    variant={'span'}
                  >
                    {t('nutrition_calories')} {nutrition.calories}
                  </Typography>
                  <Stack
                    align="right"
                    sx={{
                      textAlign: 'right',
                      p: 0,
                      fontSize: '0.75rem',
                      lineHeight: 'unset',
                      borderBottom: 'none',
                      fontWeight: 'bold'
                    }}
                  >
                    <Box>{t('nutrition_dv*')}</Box>
                    <Box>{t('nutrition_dv*', { lng: 'fr-FR' })}</Box>
                  </Stack>
                </Stack>

                <Box>
                  <TableContainer>
                    <Table
                      sx={{
                        borderCollapse: 'unset'
                      }}
                      size="small"
                      aria-label="simple table"
                    >
                      <TableBody>
                        {
                          nutritionalInformation.macro.map((macroNutrient) => (
                              <React.Fragment
                                key={macroNutrient.label}
                              >
                                <TableRow
                                  className='border-btm-slim'
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      p: 0,
                                      borderBottom: !macroNutrient?.children?.length ? '3px solid black' : 'none'
                                    }}
                                  >
                                    <strong>
                                      {macroNutrient.label}
                                    </strong>
                                    &nbsp;{macroNutrient.mass}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      p: 0,
                                      borderBottom: !macroNutrient?.children?.length ? '3px solid black' : 'none'
                                    }}
                                  >
                                    {`${macroNutrient.percentage}`}
                                  </TableCell>
                                </TableRow>

                                {
                                  macroNutrient?.children?.map((row, index) => (
                                    <TableRow
                                      key={row.label}
                                      className=''
                                    >
                                      <TableCell
                                        sx={{
                                          p: 0,
                                          pl: 1,
                                          borderBottom: 
                                            (macroNutrient?.children?.length - 1) ===  index ?
                                            '3px solid black' :
                                            'none'
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        {
                                          !!row.value ?
                                          <DescriptionHolder content={(row.label + ` ${!isNaN(row.value) ? (row.value) : 0} ${row.unit || 'g'}`)} /> :
                                          <DescriptionHolder content={(row.label)} />
                                        }
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        sx={{
                                          p: 0,
                                          borderBottom:
                                            (macroNutrient?.children?.length - 1) === index ?
                                              '3px solid black' :
                                              'none'
                                        }}
                                      >
                                        { !isNaN(row.percentage) ? ` ${Math.round(row.percentage)} %` : '0 %' }
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              </React.Fragment>
                            )
                          )
                        }

                        {
                          nutritionalInformation.micro
                            .map((row) => (
                              <TableRow
                                className='border-btm-slim'
                                key={row.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    borderBottom: '3px solid black'
                                  }
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    p: 0,
                                    borderBottom: 'none'
                                  }}
                                >
                                  {row.label}
                                  &nbsp;
                                  {
                                    !isNaN(getMicronutrient(row.id)?.value) ?
                                    Math.round(getMicronutrient(row.id).value * 10) / 10 : 
                                    0
                                  }
                                  &nbsp;
                                  { getMicronutrient(row.id).unit || 'mg' }
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    p: 0,
                                    borderBottom: 'none'
                                  }}
                                >
                                  { !isNaN(getMicronutrient(row.id)?.percentage) ? `${Math.round(getMicronutrient(row.id)?.percentage)} %` : '0 %' }
                                </TableCell>
                              </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box className="table_block-footer">
                  <Box className="text">
                    <Trans>
                      {t('nutrition_dv')}
                    </Trans>
                  </Box>
                  <Box className="text">
                    <Trans>
                      {t('nutrition_dv', { lng: 'fr-FR' })}
                    </Trans>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  )
};

Nutrition.propTypes = {
  nutrition: PropTypes.object.isRequired,
};

export default Nutrition;
