import { useSelector } from 'react-redux';

export const useCheckVideoToolsEnabled = () => {
    const user = useSelector((state) => state.user);

    if (!user.enable_video_tools) {
        return false;
    }

    return true;
}