import { useState } from "react";

import useSWR from 'swr';

import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import Checkbox from '@mui/material/Checkbox';

import { videoToolkitFetcher } from "../../../common/utils/fetcher";

function RelationshipsDialog(props) {
  const { onClose, open, title, description, url, columns = [], selectedItems = [], idField = 'id' } = props;
  const [selected, setSelected] = useState(selectedItems.map(item => item.id));

  const { data: projects } = useSWR({
    url,
  }, videoToolkitFetcher);

  const checkboxDefaultAttributes = {
    inputProps: {
      'aria-label': 'Select Project Checkbox',
    },
    size: 'small',
    sx: {
      p: 0,
    }
  };

  const handleClose = () => {
    onClose(selected);
  };

  const handleCheckboxChange = (event, itemId) => {
    const rowId = itemId[idField];

    // TODO see if this logic can be improved
    if (selected.includes(rowId)) {
      if (event.target.checked) {
        // this should not happen. But do nothing
        console.warn('TODO: this should not happen. Investigate 1');
      } else {
        // remove from selected
        setSelected(selected.filter(item => item !== rowId));      
      }
    } else {
      if (event.target.checked) {
        // add to selected
        setSelected([...selected, rowId]);
      } else {
        // this should not happen. But do nothing
        console.warn('TODO: this should not happen. Investigate 2');
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      scroll={'paper'}
      sx={{
        height: '100%',
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        dividers
      >
        <TableContainer>
          <Table
            sx={{
              minWidth: 650
            }}
            aria-label="relationships table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                >
                  <Checkbox
                    {...checkboxDefaultAttributes}
                    disabled
                  />
                </TableCell>
                {
                  columns.map((column) => (
                    <TableCell
                      key={column.key}
                    >
                      {column.label}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>

            <TableBody>
              {projects?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <Checkbox
                      {...checkboxDefaultAttributes}
                      onChange={event => handleCheckboxChange(event, row)}
                      checked={selected.includes(row[idField])}
                    />
                  </TableCell>
                  {
                    columns.map((column) => (
                      <TableCell
                        key={column.key}
                        component="th"
                        scope="row"
                      >
                        {row[column.key]}
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={handleClose}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RelationshipsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddRelationships({ title, description = '', url, payload = {}, columns = [], idField = 'id', onClose, selectedItems = []}) {
  const [open, setOpen] = useState(false);

  const handleClose = (selectedRowIds) => {
    if (onClose) {
      onClose(selectedRowIds);
    }

    setOpen(false);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => { setOpen(true) }}
      >
        Manage
      </Button>

      <RelationshipsDialog
        open={open}
        onClose={handleClose}
        title={title}
        description={description}
        url={url}
        payload={payload}
        columns={columns}
        idField={idField}
        selectedItems={selectedItems}
      />
    </Box>
  )
}

export default AddRelationships