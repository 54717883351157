import { arrayOf, element, func, node, oneOf, shape, string } from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Search from '../../components/shared/Search';

import constants from '../../utils/constants';

import AlertBox from '../../components/AlertBox';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { Grid, Stack } from '@mui/material';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import { useState } from 'react';
import ImageCard from '../../components/ImageCard';
import Authenticated from '../Authenticated';



const ListPage = ({
    children,
    title,
    summaries = [],
    description,
    instructionMessage,
    triggerSearch = () => {},
    breadcrumbsLinks = [],
    searchOnly,
    handleSearchComplete,
    searchStorageKey,
}) => {

    const [searchResults, setSearchResults] = useState(null);

    const onSearchComplete = (searchResults) => {
        setSearchResults(searchResults);
        handleSearchComplete(searchResults);
    };

    return (
        <Authenticated>
            <Box
                sx={{
                    flexGrow: 1
                }}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Typography
                        className='form-title'
                        variant="h2"
                    >
                        {title }
                    </Typography>

                    {
                        searchStorageKey &&
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'block',
                                },
                            }}
                        >
                            <Search
                                searchOnly={searchOnly}
                                handleSearchComplete={onSearchComplete}
                                handleSearchCleared={() => setSearchResults(null)}
                                storageKey={searchStorageKey}
                                compact
                            />
                        </Box>
                    }
                </Stack>

                <Breadcrumbs
                    home
                    links={breadcrumbsLinks}
                />

                {
                    !!instructionMessage && 
                    <AlertBox
                        message={instructionMessage}
                    />
                }

                {
                    !!description &&
                    <Typography
                        sx={{
                            my: 2,
                            color: 'rgba(0, 0, 0, 0.74)',
                            textAlign: 'justify',
                        }}
                    >
                        {description}
                    </Typography>
                }

                <Box sx={{ flexGrow: 1, my: 3 }}>
                    {
                        summaries.map(field => (
                            <LabelAndDisplay
                                key={field.label}
                                label={field.label}
                                value={field.value}
                            >
                                {field.children}
                            </LabelAndDisplay>
                        ))
                    }
                </Box>

                {
                    searchStorageKey &&
                    <Box
                        sx={{
                            display: {
                                xs: 'block',
                                md: 'none',
                            },
                        }}
                    >
                        <Search
                            searchOnly={searchOnly}
                            handleSearchComplete={onSearchComplete}
                            handleSearchCleared={() => setSearchResults(null)}
                            storageKey={searchStorageKey}
                            compact
                        />
                    </Box>
                }

                {
                    searchResults !== null &&
                    <Box>
                        <Grid container
                            sx={{ mt: 4 }}
                            spacing={2}
                        >
                            {searchResults?.map((searchResult) => (
                                <Grid item
                                    key={searchResult.url}
                                    xs={12}
                                    md={3}
                                    lg={2}
                                >
                                    <ImageCard
                                        url={searchResult.url}
                                        item={{
                                            title: searchResult.title,
                                            description: searchResult.description,
                                            url: searchResult.image,
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                }

                {
                    searchResults === null &&
                    <>
                        { children }
                    </>
                }
            </Box>
        </Authenticated>
    )
}

ListPage.propTypes = {
    children: node.isRequired,
    title: string.isRequired,
    description: string,
    instructionMessage: string,
    searchOnly: arrayOf(
        oneOf(constants.searchOnlyEnums)
    ),
    searchStorageKey: string,
    handleSearchComplete: func,
    breadcrumbsLinks: arrayOf(
        shape({
            url: string,
            content: oneOf([
                string,
                element
            ]).isRequired
        })
    )
}

export default ListPage