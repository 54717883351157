import React from "react";
import { visuallyHidden } from "@mui/utils";
import { Launch } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  LinearProgress,
  TablePagination,
  Alert,
  ListItem,
  Chip,
  Box,
  TableSortLabel,
} from "@mui/material";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ fontWeight: 600 }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell?.disabled}
            >
              {headCell.name}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function BasicTable({
  page = 1,
  items = [],
  headers = [],
  rowsPerPage = 10,
  handleChangePage = () => {},
  isLoading = false,
  handleChangeRowsPerPage = () => {},
  totalCount = items?.length,
  hidePagination = false,
  handleSort = () => {},
}) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSort(`${isAsc ? "-" : ""}${property}`);
  };

  return (
    <>
      <Table
        style={{
          width: "100%",
          border: "1px solid #eaeaea",
          backgroundColor: "#fff",
        }}
      >
        <EnhancedTableHead
          headCells={headers}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        {isLoading ? (
          <TableCell colSpan={headers?.length}>
            <LinearProgress />
          </TableCell>
        ) : items?.length > 0 ? (
          <TableBody>
            {items.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  hover={row?.navigate}
                  sx={{ cursor: row?.navigate ? "pointer" : "default" }}
                  onClick={() => {
                    row?.navigate && navigate(`/barcodes/${row?.id}`);
                  }}
                >
                  {headers?.map((header) =>
                    header?.key === "barcodes" ? (
                      <TableCell key={header.key}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexWrap: "wrap",
                            listStyle: "none",
                            m: 0,
                            p: 0,
                          }}
                          component="ul"
                        >
                          {row[header?.key]?.map((data) => {
                            return (
                              <ListItem
                                sx={{ p: 0, width: "auto" }}
                                key={data.id}
                              >
                                <Chip
                                  deleteIcon={<Launch />}
                                  label={data.name}
                                  onDelete={() => {
                                    navigate(`/barcodes/${data?.id}`);
                                  }}
                                  variant="outlined"
                                  color="primary"
                                />
                              </ListItem>
                            );
                          })}
                        </Box>
                      </TableCell>
                    ) : header?.key === "needs_review" ? (
                      <TableCell
                        key={header.key}
                        sx={{
                          color: row[header?.key] === "Yes" ? "#f00" : "",
                        }}
                      >
                        {row[header?.key]}
                      </TableCell>
                    ) : (
                      <TableCell key={header.key}>{row[header?.key]}</TableCell>
                    )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <Alert severity="info">No data to display</Alert>
        )}
      </Table>

      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}

export default BasicTable;
