import PropTypes from "prop-types";

import { Box, ImageListItem, Typography } from "@mui/material";

import Wysiwyg from "../Wysiwyg";

import styles from "./index.module.scss";
import LabelAndDisplay from "../../LabelAndDisplay";

function TableCard({ title, description, action, items = [] }) {
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        pb: action ? "46px" : "inherit",
      }}
    >
      <ImageListItem
        sx={{
          width: "100%",
        }}
      >
        {!!title && (
          <Box sx={{ my: 1 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#090909",
                fontSize: "0.9rem",
                textTransform: "capitalize",
              }}
            >
              <Wysiwyg truncateAfterLine={2}>{title}</Wysiwyg>
            </Typography>
          </Box>
        )}

        {!!description && (
          <Box sx={{ my: 1 }}>
            <Typography
              sx={{
                fontWeight: 300,
                color: "#090909",
                fontSize: "0.8rem",
              }}
            >
              <Wysiwyg truncateAfterLine={2}>{description}</Wysiwyg>
            </Typography>
          </Box>
        )}

        {action && <>{action}</>}

        {!!items && (
          <Box sx={{ my: 2 }}>
            {items.map((item, index) => (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <LabelAndDisplay
                  key={item.key}
                  label={item.key}
                  value={item.value}
                  variant="table"
                ></LabelAndDisplay>
              </a>
            ))}
          </Box>
        )}
      </ImageListItem>
    </Box>
  );
}

// TableCard.propTypes = {
//     title: PropTypes.string.isRequired(),
//     description: PropTypes.string,
//     items: PropTypes.arrayOf(
//         PropTypes.shape({
//             key: PropTypes.string.isRequired(),
//             value: PropTypes.node,
//         })
//     ),
//     action: PropTypes.node,
// }

export default TableCard;
