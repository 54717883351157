import DOMPurify from 'dompurify';

function DescriptionHolder({ content = '', allowedTags = [], truncateText = false }) {
  const defaultAllowedTags = ['br', 'p', 'b', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'strong', 'span', 'div'];

  const config = {
    ALLOWED_TAGS: [
      ...(truncateText ? [] : defaultAllowedTags),
      ...allowedTags
    ],
  };

  return (
    <div sx= {{whiteSpace: 'pre-wrap'}}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content, config)?.substring(0, 99) }}
    ></div>
  )
}

export default DescriptionHolder