import React from "react";
import { useNavigate } from "react-router-dom";
import { LocalDining, Person, Source, Storefront } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

import { getAccessToken } from "../../../utils/user";

const BarcodeCardView = ({ barcodes, isLoading }) => {
  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {barcodes?.data
            ?.sort((a, b) => a.code.localeCompare(b.code))
            ?.map(
              (
                {
                  id,
                  code,
                  beef_product_id,
                  owner,
                  data_source,
                  last_verified_by,
                  product_image,
                  needs_review,
                },
                index
              ) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Card sx={{ maxWidth: 345 }}>
                    {product_image && (
                      <CardMedia
                        sx={{ height: 140 }}
                        image={`${process.env.REACT_APP_API_URL}/assets/${
                          product_image?.filename_disk
                        }?access_token=${getAccessToken()}`}
                        title="Prodcut Image"
                      />
                    )}
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {code}
                      </Typography>

                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <Tooltip placement="left" title="Beef Product">
                          <ListItem>
                            <ListItemIcon>
                              <LocalDining />
                            </ListItemIcon>
                            <ListItemText
                              id="label-product"
                              primary={beef_product_id?.translations[0]?.name}
                            />
                          </ListItem>
                        </Tooltip>

                        <Tooltip placement="left" title="Owner">
                          <ListItem>
                            <ListItemIcon>
                              <Storefront />
                            </ListItemIcon>
                            <ListItemText
                              id="label-owner"
                              primary={owner?.translations[0]?.retailer_name}
                            />
                          </ListItem>
                        </Tooltip>

                        <Tooltip placement="left" title="Source">
                          <ListItem>
                            <ListItemIcon>
                              <Source />
                            </ListItemIcon>
                            <ListItemText
                              id="label-source"
                              primary={data_source}
                            />
                          </ListItem>
                        </Tooltip>

                        <Tooltip placement="left" title="Verified By User">
                          <ListItem>
                            <ListItemIcon>
                              <Person />
                            </ListItemIcon>
                            <ListItemText
                              id="label-user"
                              primary={`${last_verified_by?.first_name ?? ""} ${
                                last_verified_by?.last_name ?? ""
                              }`}
                            />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </CardContent>

                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => {
                          navigate(`/barcodes/${id}?edit=true`);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          navigate(`/barcodes/${id}`);
                        }}
                      >
                        Learn More
                      </Button>
                      {needs_review && (
                        <Chip
                          color="error"
                          sx={{
                            ml: "auto",
                          }}
                          size="small"
                          label="Needs Review"
                        />
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              )
            )}
        </Grid>
      )}
    </>
  );
};

export default BarcodeCardView;
