import { Box, Divider, ListItemIcon, Stack, Typography } from "@mui/material";

const FilterItem = ({ title, Icon, children }) => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing="4px"
          sx={{ mb: "4px" }}
        >
          <ListItemIcon sx={{ minWidth: "auto" }}>
            <Icon fontSize="small" />
          </ListItemIcon>
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            {title}
          </Typography>
        </Stack>
        {children}
      </Box>
      <Divider />
    </>
  );
};

export default FilterItem;
