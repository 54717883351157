import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";

import api from "../../api";
import QRScans from "./QRScans";
import PageViews from "./PageViews";
import VisitByPage from "./VisitByPage";
import BarcodeList from "./Barcode/List";
import BarcodeDiagnosticsReport from "./Barcode/Diagnostics";
import DiagnosticsTable from "./Product/DiagnosticsTable";
import NoBarcodeProducts from "./Product/NoBarcodeProducts";
import BarcodeSourceList from "./Barcode/SourceList";
import BarcodeProductList from "./Barcode/ProductsList";
import OrphanProductsTable from "./Product/OrphanProductsTable";
import ProductsMostWidelyUsed from "./Product/ProductsMostWidelyUsed";
import MultipleBarcodesProducts from "./Product/MultipleBarcodesProducts";

const ReportsWithFilter = () => {
  const [selectedReport, setSelectedReport] = useState(14);
  const [checked, setChecked] = useState(false);
  const user = useSelector((state) => state.user);
  const isAdminUser = api.isAdmin(user);

  const options = [
    {
      id: 8,
      label: "QR Scans",
      component: <QRScans showFullData={checked} />,
    },
    {
      id: 9,
      label: "Page Views",
      component: <PageViews showFullData={checked} />,
    },
    {
      id: 10,
      label: "Visits By Page",
      component: <VisitByPage showFullData={checked} />,
    },
    {
      id: 1,
      label: "Products / Most widely used",
      component: <ProductsMostWidelyUsed />,
      hide: !isAdminUser,
    },
    {
      id: 2,
      label: "Products / Multiple barcodes",
      component: <MultipleBarcodesProducts showFullData={checked} />,
    },
    {
      id: 3,
      label: "Products / No barcode",
      component: <NoBarcodeProducts showFullData={checked} />,
    },
    {
      id: 4,
      label: "Products / Price trend",
      component: <h1>Products / Price trend</h1>,
    },
    {
      id: 5,
      label: "Product / Weight ",
      component: <h1>Products / Weight</h1>,
    },
    {
      id: 6,
      label: "Product / Orphan",
      component: <OrphanProductsTable />,
    },
    {
      id: 7,
      label: "Product / Diagnostics",
      component: <DiagnosticsTable />,
    },
    {
      id: 12,
      label: "Barcodes / List",
      component: <BarcodeList showFullData={checked} />,
    },
    {
      id: 13,
      label: "Barcodes / Product",
      component: <BarcodeProductList showFullData={checked} />,
    },
    {
      id: 14,
      label: "Barcodes / Source",
      component: <BarcodeSourceList showFullData={checked} />,
    },
    {
      id: 11,
      label: "Barcodes / Diagnostics",
      component: <BarcodeDiagnosticsReport showFullData={checked} />,
    },
  ];
  const ReportComponent = options?.find(
    ({ id, hide }) => id === selectedReport && !hide
  )?.component;

  return (
    <Box sx={{ pt: 4 }}>
      <Typography
        sx={{
          fontWeight: "600",
          color: "#090909",
          fontSize: "1.2rem",
          mb: 1,
        }}
      >
        REPORT FILTER
      </Typography>

      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <Autocomplete
          id="report-select"
          sx={{ width: 600, mb: 2 }}
          options={options
            ?.filter(({ hide }) => !hide)
            ?.map((option) => {
              const { component, ...rest } = option;
              return rest;
            })}
          onChange={(e) => {
            setSelectedReport(e.target.value);
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              value={option?.id}
              {...props}
            >
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a report"
              inputProps={{
                ...params.inputProps,
                value: options?.find(({ id }) => id === selectedReport)?.label,
                autoComplete: "off", // disable autocomplete and autofill
              }}
            />
          )}
        />
        {isAdminUser && (
          <label
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Checkbox
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
            />
            Show reports on all data
          </label>
        )}
      </Box>
      {ReportComponent}
    </Box>
  );
};

export default ReportsWithFilter;
