import * as React from "react";
import { Box } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, TextField, Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectSourceInput = ({
  sources,
  setSelectedSources,
  selectedSources,
}) => {
  return (
    <Autocomplete
      multiple
      id="checkboxes-source"
      options={sources}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.name}
      defaultValue={selectedSources}
      onChange={(_, newValue) => {
        setSelectedSources(newValue);
      }}
      renderOption={(props, option, { selected }) => (
        <Box {...props} value={option?.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option?.name}
        </Box>
      )}
      style={{ width: 500 }}
      renderInput={(params) => <TextField {...params} label="Select source" />}
    />
  );
};

export default SelectSourceInput;
