import axios from "axios";

import store from "../../app/store";
import { handleResponseError } from "../../utils/error";

const getToken = () => {
  return store.getState().user.access_token;
};

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetcher = (payload) => {
  const { url, ...args } = payload;

  if (url) {
    return axios
      .get(`${BASE_URL}/items/${url}`, {
        params: {
          access_token: getToken(),
          ...args,
        },
      })
      .then((res) => {
        if (res && res?.data && res?.data?.meta) {
          return res?.data;
        } else {
          return res.data.data;
        }
      })
      .catch((error) => {
        throw handleResponseError(error);
      });
  }
};

export const videoToolkitFetcher = (payload) => {
  const { url, ...args } = payload;

  if (url) {
    return axios
      .get(`${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/items/${url}`, {
        params: {
          access_token: process.env.REACT_APP_SHOTSTACK_ADMIN_API_KEY,
          ...args,
        },
      })
      .then((res) => {
        if (res && res?.data && res?.data?.meta) {
          return res?.data;
        } else {
          return res.data.data;
        }
      })
      .catch((error) => {
        throw handleResponseError(error);
      });
  }
};
