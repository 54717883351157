import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// utils
import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import { chooseInputComponent } from '../../common/utils/components';
import { getTranslation } from '../../common/utils/translate';
import constants from '../../common/utils/constants';
import { buildTranslationPayload, buildRelationshipPayload } from '../../common/utils/payloads';

import api from '../../api';

// components
// TODO lazy load these
import notify from '../../components/Notification/helper';
import Nutrition from '../../components/Nutrition';
import Loader from '../../components/Loader';

function RetailersCreate() {
  // TODO rename this to userRefreshAccessToken
  useRefreshToken();

  const params = useParams();
  const beefCutId = params.beefCutId;

  const recipesStore = useSelector(state => state.recipes);
  const cookingMethodsStore = useSelector(state => state.cookingMethods);
  const beefProductBrandsStore = useSelector(state => state.beefProductBrands);
  const beefCutsStore = useSelector(state => state.beefCuts);

  const [beefCut, setBeefCut] = useState({});

  // relationships
  const [linkedCookingMethods, setLinkedCookingMethods] = useState([]);
  const [linkedBeefProductBrands, setLinkedBeefProductBrands] = useState([]);
  const [linkedRecipes, setLinkedRecipes] = useState([]);
  const [linkedBeefCuts, setLinkedBeefCuts] = useState([]);

  // 
  const [translatedBeefCut, setTranslatedBeefCut] = useState({});
  const [frenchTranslatedBeefCut, setFrenchTranslatedBeefCut] = useState({});


  const [beefCutPayload, setBeefCutPayload] = useState({
    status: '',
    image: '',
    svg_icon: '',
    translations: {
      create: [],
      update: [],
      delete: [],
    }
  });


  const [apiCallStatus, setApiCallStatus] = useState(constants.idle);

  // if there's no beef cut id then it's a create operation
  const crudOperation = beefCutId ? 'update' : 'create';

  // TODO consider moving this into the relationship table component
  const headerForRecipes = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'summary',
      numeric: false,
      disablePadding: false,
      label: 'Summary',
    },
  ];

  // format data for relationship tables
  const beefCutRows = beefCutsStore.data.map(row => {
    const translation = getTranslation(row, { key: 'languages_id', code: 'en-US' }, 'translations');

    return {
      id: row.id,
      name: translation?.name,
      description: translation?.description,
    };
  });

  const recipeRows = recipesStore.data.map(row => {
    const translation = getTranslation(row, { key: 'languages_code', code: 'en-US' }, 'layout_translations');

    return {
      id: row.id,
      name: translation?.name,
      description: translation?.summary?.substring(0, 99) + '...',
    };
  });

  const cookingMethodRows = cookingMethodsStore.data.map(row => {
    const translation = getTranslation(row);

    return {
      id: row.id,
      name: translation?.Method,
      description: translation?.Description,
    };
  });

  const beefProductBrandRows = beefProductBrandsStore.data.map(row => {
    const translation = getTranslation(row);

    return {
      id: row.id,
      name: translation?.name,
      description: '...',
    };
  });

  const makeUpdateBeefCutRequest = async () => {
    try {
      setApiCallStatus(constants.fetching);

      await api.updateBeefCut(beefCutPayload);
      notify('success', 'Beef cut updated');
      setApiCallStatus(constants.fetched);
    } catch (error) {
      notify('error', 'Something went wrong');
      setApiCallStatus(constants.error);
    }
  }

  const addToLinkedList = (linkedRecipes, selectedItems) => {
    console.log('linkedRecipes: ', linkedRecipes);
    linkedRecipes.push({
      id: selectedItems[0],
      name: ''
    });
    console.log('linkedRecipes: ', linkedRecipes);

    // build the objects with that id
    // add the objects to linkedList
    // setLinkedRecipes(
    //   (
    //     current => {
    //       return current.push({
    //         id: selectedItems[0]
    //       })
    //     }
    //   )
    // );
  }

  // TODO consider making this a util function
  const handleInputChange = (value, field, languageCode, translationId) => {
    let keyValuePair = {};
    keyValuePair[field] = value;

    // TODO handle non-translated fields
    if (languageCode) {
      keyValuePair.languages_code = languageCode;

      const translationObject = beefCutPayload
        .translations[crudOperation]
        ?.find(translation => translation.id === translationId);

      translationObject[field] = value;
    }

    const updatedBeefCutPayload = {
      ...beefCutPayload,
      ...keyValuePair
    };

    setBeefCutPayload(updatedBeefCutPayload);
  };

  const formSections = [
    {
      title: 'Overview',
      fields: [
        {
          label: 'Name',
          name: 'name',
          type: 'text',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            values: {
              'en-US': {
                id: translatedBeefCut.id,
                value: translatedBeefCut.name
              },
              'fr-FR': {
                id: frenchTranslatedBeefCut.id,
                value: frenchTranslatedBeefCut.name
              },
            }
          },
        },
        {
          label: 'Description',
          name: 'description',
          type: 'textarea',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            values: {
              'en-US': {
                id: translatedBeefCut.id,
                value: translatedBeefCut.description
              },
              'fr-FR': {
                id: frenchTranslatedBeefCut.id,
                value: frenchTranslatedBeefCut.description
              },
            }
          },
        },
        {
          label: 'Tips',
          name: 'tips',
          type: 'textarea',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            values: {
              'en-US': {
                id: translatedBeefCut.id,
                value: translatedBeefCut.tips
              },
              'fr-FR': {
                id: frenchTranslatedBeefCut.id,
                value: frenchTranslatedBeefCut.tips
              },
            }
          },
        },
      ],
    },
    {
      title: 'Images',
      fields: [
        {
          label: 'Image',
          name: 'image',
          type: 'image',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            value: beefCutPayload.image
          },
        },
        {
          label: 'SVG Icon',
          name: 'svg_icon',
          type: 'image',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            value: beefCutPayload.svg_icon
          },
        },
      ],
    },
    {
      title: 'Collections',
      fields: [
        {
          label: 'Parent',
          name: 'parent_group',
          type: 'm2o',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            title: 'Beef Cuts',
            rows: beefCutRows,
            headCells: headerForRecipes,
            items: [{
              id: beefCut?.parent_group?.id,
              name: getTranslation(
                beefCut.parent_group,
                {
                    key: 'languages_id',
                    code: 'en-US'
                  },
                  'translations'
              )?.name
            }]
          },
        },
        {
          label: 'Recipes',
          name: 'recipes',
          type: 'm2m',
          handleInputChange: selectedItems => {
            addToLinkedList(linkedRecipes, selectedItems);
            handleInputChange(
              buildRelationshipPayload(
                beefCutPayload.recipes,
                'create',
                selectedItems.map(selectedItemId => ({
                  beef_product_id: beefCutId,
                  recipe_id: {
                    id: selectedItemId
                  }
                }))
              ),
              'recipes'
            )
          },
          config: {
            size: '12',
            translations: [],
            title: 'Recipes',
            rows: recipeRows,
            headCells: headerForRecipes,
            items: linkedRecipes
          },
        },
        {
          label: 'Cooking Methods',
          name: 'cooking_methods',
          type: 'm2m',
          handleInputChange: selectedItems => {
            handleInputChange(
              buildRelationshipPayload(
                beefCutPayload.cooking_methods,
                'create',
                selectedItems.map(selectedItemId => ({
                  beef_product: beefCutId,
                  cooking_method: {
                    id: selectedItemId
                  }
                }))
              ),
              'cooking_methods'
            )
          },
          config: {
            size: '12',
            translations: [],
            title: 'Cooking Methods',
            rows: cookingMethodRows,
            headCells: headerForRecipes,
            items: linkedCookingMethods
          },
        },
        {
          label: 'Beef Product Brands',
          name: 'beef_product_brands',
          type: 'm2m',
          handleInputChange: selectedItems => {
            handleInputChange(
              buildRelationshipPayload(
                beefCutPayload.cooking_methods,
                'create',
                selectedItems.map(selectedItemId => ({
                  beef_product: beefCutId,
                  beef_product_branding_id: {
                    id: selectedItemId
                  }
                }))
              ),
              'beef_product_brandings'
            )
          },
          config: {
            size: '12',
            translations: [],
            title: 'Beef Product Brands',
            rows: beefProductBrandRows,
            headCells: headerForRecipes,
            items: linkedBeefProductBrands
          },
        },
      ],
    },
    {
      title: 'Visibility',
      fields: [
        {
          label: 'Status',
          name: 'status',
          type: 'status',
          handleInputChange,
          config: {
            size: '12',
            translations: [],
            value: beefCutPayload.status
          },
        },
      ],
    },
  ]

  const getBeefProduct = async () => {
    return await api.getBeefProduct(beefCutId);
  }

  useEffect(() => {
    if (!beefCutId) return;

    const initialiseStates = async () => {
      try {
        setApiCallStatus(constants.fetching);

        const beefCut = await getBeefProduct();

        setBeefCut(beefCut);

        // TODO move to own function
        const requestPayload = buildTranslationPayload(beefCut.translations, 'languages_id');

        const linkedRecipes = beefCut.recipes?.map(recipeJunction => {
          return {
            id: recipeJunction.recipe_id.id,
            name: getTranslation(recipeJunction.recipe_id, {
              key: 'languages_code',
              code: 'en-US' },
              'layout_translations'
            )?.name
          }
        });

        const linkedCookingMethods = beefCut.cooking_methods?.map(cookingMethodJunction => {
          return {
            id: cookingMethodJunction.cooking_method.id,
            name: getTranslation(cookingMethodJunction.cooking_method)?.Method
          }
        });

        const linkedBeefProductBrands = beefCut.beef_product_brandings?.map(beefProductBrandJunction => {
          return {
            id: beefProductBrandJunction.beef_product_branding_id.id,
            name: getTranslation(beefProductBrandJunction.beef_product_branding_id)?.name
          }
        });

        setBeefCutPayload({
          id: beefCutId,
          status: beefCut.status,
          image: beefCut.image,
          svg_icon: beefCut.svg_icon,
          // recipes: linkedRecipes,
          // cooking_methods: linkedCookingMethods,
          // beef_product_brandings: linkedBeefProductBrands,
          ...requestPayload
        });

        setTranslatedBeefCut(
          getTranslation(
            requestPayload.translations,
            {
              key: 'languages_id',
              code: 'en-US' // TODO add default language code to state
            },
            'update'
          )
        );

        setFrenchTranslatedBeefCut(
          getTranslation(
            requestPayload.translations,
            {
              key: 'languages_id',
              code: 'fr-FR'
            },
            'update'
          )
        );

        setLinkedRecipes(linkedRecipes);
        setLinkedCookingMethods(linkedCookingMethods);
        setLinkedBeefProductBrands(linkedBeefProductBrands);

        setApiCallStatus(constants.fetched);
      } catch (error) {
        notify('error', 'Something went wrong');
        setApiCallStatus(constants.error);
      }
    }

    initialiseStates();
  }, [beefCutId]);


  // TODO figure out a smarter way of handling this
  if (apiCallStatus === constants.fetching) {
    return <Loader />;
  }

  return (
    <Box sx={{ flexGrow: 1, my: 3 }}>
      <Typography
        className='form-title'
        variant="h2"
      >
        { 'Create New Retailer'}
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={7} lg={6}>
          {formSections.map((section) => (
            <Box key={section.title}>
              {
                section.title &&
                <Typography
                  className='section-title'
                  sx={{
                    mt: 5,
                    mb: 1
                  }}
                  variant="h2"
                >
                  {section.title}
                </Typography>
              }

              <Grid container spacing={2}>
                {section.fields.map(field => (
                  <Fragment key={field.name}>
                    <Grid
                      item
                      key={field.name}
                      xs={['color', 'font', 'dropdown'].includes(field?.type) ? 4 : 12}
                    >
                      { chooseInputComponent(field) }
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          ))}
          
          <Button
            className='form-button'
            sx={{
              my: 5
            }}
            size="large"
            variant="contained"
            onClick={
              () => {
                beefCutId ?
                makeUpdateBeefCutRequest() :
                makeUpdateBeefCutRequest() 
              }
            }
          >
            {beefCutId ? 'Update' : 'Create a'} Beef Cut
          </Button>
        </Grid>
        {
          !!beefCut.Nutrients &&
          <Grid item xs={12} md={5} lg={6} >
              <Typography
                className='section-title'
                sx={{
                  mt: 5,
                  mb: 1
                }}
                variant="h2"
              >
                {'Nutrition'}
              </Typography>

            <Nutrition
              nutrition={beefCut.Nutrients}
            />
          </Grid>
        }
      </Grid>
    </Box>
  )
}

export default RetailersCreate