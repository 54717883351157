import React from "react";

import DonutChart from "./DonutChart";
import AcquisitionTable from "./AcquisitionTable";

const Index = ({ showFullData }) => {
  return (
    <>
      <DonutChart showFullData={showFullData} />
      <AcquisitionTable showFullData={showFullData} />
    </>
  );
};

export default Index;
