import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react";

import styles from './index.module.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{}}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
}

function a11yProps(index) {
    return {
        id: `popup-tab-${index}`,
        'aria-controls': `popup-tabpanel-${index}`,
    };
}

function TabsNavigation({ tabs=[] }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Stack>
                <Box
                    sx={{
                        mb: 2,
                    }}
                >
                    {
                        tabs.map((tab, index) => (
                            <Button
                                key={tab.label}
                                className={`${styles.tab_button} ${value === index ? styles.active : styles.inactive}`}
                                variant="contained"
                                onClick={() => setValue(index)}
                                sx={{
                                    mr: 1,
                                }}
                            >
                                {tab.label}
                            </Button>
                        ))
                    }
                </Box>

                <Box>
                    
                </Box>
            </Stack>

            {
                tabs.map((tab, index) => (
                    <TabPanel
                        key={tab.label}
                        value={value}
                        index={index}
                    >
                        {tab.children}
                    </TabPanel>
                ))
            }
        </Box>
    )
}

export default TabsNavigation