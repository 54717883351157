export const barcodeSources = [
  {
    id: 1,
    name: "Canada Beef Research",
  },
  {
    id: 2,
    name: "Nielson Spreadsheet",
  },
  {
    id: 3,
    name: "Community Submissions",
  },
  {
    id: 4,
    name: "Manual Entry",
  },
  {
    id: 5,
    name: "Street Team",
  },
];
