import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AddIconImage from '../../shared/svg/plus.svg';
import CompletedIconImage from '../../shared/svg/check.svg';

import ImageCard from '../../components/ImageCard';

import './index.scss';
import ListPage from '../../layouts/ListPage';

function Dashboard() {
  const retailer = useSelector(state => state.retailer);

  const filter = {
    _and: [
      {
        type: {
          _eq: 'CUISINE'
        }
      },
      {
        status: {
          _eq: 'published'
        }
      },
    ]
  };

  const payload = {
    fields: [
      '*',
      'translations.*',
    ],
    filter,
    limit: -1,
  };

  const profileCompletionChecks = [
      {
        title: 'Set up your brand',
        completed: !!retailer?.website_url,
        uri: '/my-brand'
      },
      {
        title: 'Select recipes',
        completed: !!retailer?.recipes?.filter(recipe => recipe.recipe_id).length,
        uri: '/recipe-book'
      },
      {
        title: 'Add Beef Cuts',
        completed: !!retailer?.beef_products?.filter(beefProduct => beefProduct.beef_product_id).length,
        uri: '/beef-cuts'
      },
      {
        title: 'QR Codes',
        completed: !!retailer?.cooking_methods?.length,
        uri: '/qr-codes'
      },
      {
        title: 'Add Cooking Methods',
        completed: !!retailer?.cooking_methods?.length,
        uri: '/cooking-methods'
      },
      // {
      //   title: 'Add Coupons',
      //   completed: !!retailer?.coupons?.length,
      //   uri: '/coupons'
      // },
      {
        title: 'Add Articles',
        completed: !!retailer?.pages?.length,
        uri: '/articles'
      },
      {
        title: 'Add Beef  Overrides',
        completed: !!retailer?.beef_product_overrides?.length,
        uri: '/beef-product-overrides'
      },
  ];

  return (
    <ListPage
      title={'Welcome to your beef information gateway'}
      description={'With the Gateway, shopping for cuts of beef has never been easier. Simply look for the QR code on packages of beef, scan the code, select the cut of beef and enjoy. You’ll find recipes, beef cut information, nutrition and more. Click here to learn more about this program.'}
      breadcrumbsLinks={[
        {
          url: '/',
          content: 'Content Library',
        },
      ]}
    >
      <Box className="check-icon" sx={{ flexGrow: 1, my: 3 }}>
        <Grid container spacing={2}>
          {profileCompletionChecks.map((check) => (
            <Grid key={check.title} item xs={6} md={3} lg={2}>
              <Link to={check.uri}>
                <ImageCard
                  item={{
                    title: check.title,
                    url: check.completed ? CompletedIconImage : AddIconImage
                  }}
                  icon={true}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default Dashboard;