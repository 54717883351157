import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Box, Typography } from "@mui/material";
import { CheckOutlined, ClearOutlined } from "@mui/icons-material";

import BasicTable from "../../shared/Table";

const OrphanProductsTable = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const headers = [
    { key: "name", name: "Name", disabled: true },
    { key: "retailers", name: "Retailers", disabled: true },
    { key: "cooking_method", name: "Cooking Method", disabled: true },
    { key: "recipes", name: "Recipes", disabled: true },
  ];
  const orphanTableDataPayload = {
    fields: ["id", "translations.*,cooking_methods.*,retailers,recipes"],
    filter: {
      _or: [
        {
          retailers: {
            _null: true,
          },
        },
        {
          cooking_methods: {
            _null: true,
          },
        },
        {
          recipes: {
            _null: true,
          },
        },
      ],
    },
    limit: -1,
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data: orphanTableData, isLoading } = useSWR({
    url: `beef_product?page=${page + 1}&meta=*`,
    ...orphanTableDataPayload,
    limit: rowsPerPage,
  });

  useEffect(() => {
    let data;
    if (orphanTableData) {
      data = orphanTableData?.data
        ?.map((orphanTableItem) => ({
          key: orphanTableItem?.id,
          name: orphanTableItem?.translations[0]?.name ?? "(Not Provided)",
          cooking_method:
            orphanTableItem?.cooking_methods?.length > 0 ? (
              <CheckOutlined sx={{ color: "#00ff00" }} />
            ) : (
              <ClearOutlined sx={{ color: "#ff0000" }} />
            ),
          retailers:
            orphanTableItem?.retailers?.length > 0 ? (
              <CheckOutlined sx={{ color: "#00ff00" }} />
            ) : (
              <ClearOutlined sx={{ color: "#ff0000" }} />
            ),
          recipes:
            orphanTableItem?.recipes?.length > 0 ? (
              <CheckOutlined sx={{ color: "#00ff00" }} />
            ) : (
              <ClearOutlined sx={{ color: "#ff0000" }} />
            ),
        }))
        .sort((a, b) => b.value - a.value);
    }
    setItems(data);
  }, [orphanTableData]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Unlinked Products
          </Typography>
        </Box>

        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            Identifies beef products missing linkages to any of the following :
            Retailer, Cooking Method, Recipe
          </Typography>
        </Box>
      </Box>

      <BasicTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        headers={headers}
        items={items?.sort((a, b) => a.name.localeCompare(b.name))}
        totalCount={orphanTableData?.meta?.filter_count}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default OrphanProductsTable;
