import { useSelector } from 'react-redux';

export const useCheckRetailerSet = () => {
    const retailer = useSelector((state) => state.retailer);

    if (!retailer?.id) {
        return false;
    }

    return true;
}