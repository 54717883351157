import { Box } from '@mui/material';

import useSWR from 'swr';
import Popup from '../../../shared/Popup';

const TagsRelationshipContainer = ({ name, description, count, columns = [], junctionTable, mainTable, mainTablePayload, payload = {}, mainTableResponseHandler, responseHandler = response => response }) => {
    const { data: junctionTableData } = useSWR({
        url: `/${junctionTable}`,
        ...payload,
    });

    const { data: mainTableData } = useSWR({
        url: `/${mainTable}`,
        ...mainTablePayload,
    });

    let junctionTableItems = [];
    let mainTableItems = [];

    if (junctionTableData) {
        junctionTableItems = responseHandler(junctionTableData);
    }

    if (mainTableData) {
        mainTableItems = mainTableResponseHandler(mainTableData);
    }

    return (
        <Popup
            label={name}
            description={description}
            count={count}
            junctionTableItems={junctionTableItems}
            mainTableItems={mainTableItems}
            columns={columns}
        />
    )
}

export default TagsRelationshipContainer;