import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import ViewPage from '../../layouts/ViewPage';
import LabelAndDisplay from '../../components/LabelAndDisplay';
import ImageContainer from '../../components/shared/ImageContainer';

import Wysiwyg from '../../components/shared/Wysiwyg';
import PlaceholderImage from '../../shared/image/placeholder.jpg';

import { getTranslation } from '../../utils/translate';

function CuisineView() {
  useRefreshToken();

  const params = useParams();

  const language = {
    code: 'en-US',
  };

  const retailer = useSelector(state => state.retailer);

  const cookingMethodId = params.cookingMethodId;

  let title = '';
  let description = '';

  let fields = [];
  let sidebarFields =[];

  const [loading, setLoading] = useState(false);

  const payload = {
    fields: [
      '*',
      'translations.*',
    ],
  };

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      renderCell: ({ value }) => (
        <img
          className='popup-image'
          src={value}
          alt='tag image'
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
    },
  ];

  let { data: cuisine } = useSWR({
    url: `collections/${params.id}`,
    ...payload,
  });

  if (cuisine) {
    const translatedItem = getTranslation(cuisine, { key: 'languages_code', code: 'en-US' });

    const image = translatedItem?.image ?
      `${process.env.REACT_APP_API_URL}/assets/${translatedItem.image}` : null;

    title = translatedItem.title;

    fields = [
      {
        key: 'Name',
        value: translatedItem.title,
      },
      {
        key: 'Content',
        children: (
          <Typography>
            <Wysiwyg>{translatedItem.content}</Wysiwyg>
          </Typography>
        )
      },
    ];

    sidebarFields = [
      {
        key: 'Image',
        children: (
          <ImageContainer
            url={image}
            alt={translatedItem.title}
            sx={{
              height: '20rem',
            }}
          />
        )
      },
    ];
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ViewPage
        title={title}
        description={description}
        addToLibrary={{
          junctionTable: 'retailer_cooking_method',
          filter: {
            _and: [
              { cooking_method_id: { _eq: cookingMethodId } },
              { retailer_id: { _eq: retailer.id } }
            ]
          },
          addPayload: {
            cooking_method_id: cookingMethodId,
            retailer_id: retailer.id
          },
          removePayload: {
            query: {
              filter: {
                retailer_id: {
                  _eq: retailer.id,
                },
                cooking_method_id: {
                  _eq: cookingMethodId,
                }
              }
            }
          },
        }}
        breadcrumbsLinks={[
          {
            url: '/collections-menu',
            content: 'Collections & Cuisine',
          },
          {
            url: `/cuisine`,
            content: 'World Cuisine',
          }
        ]}
        relationships={[
          {
            name: 'tags',
            description: 'Manage tags linked to this cuisine.',
            entity: 'tags',
            junctionTable: 'tags_collections',
            payload: {
              fields: ['*', 'tags_id.*', 'tags_id.translations.*'],
              // filter: {
              //   _and: [
              //     { collections_id: { _eq: params.id } },
              //   ]
              // },
              limit: -1,
            },
            count: 12,
            columns,
            mainTable: 'tags',
            mainTablePayload: {
              fields: ['*', 'translations.*'],
              limit: -1,
            },
            requestHandler: () => {},
            responseHandler: (junctionTableResults) => {
              return junctionTableResults.map(junctionTableResult => {
                return {
                  id: junctionTableResult.tags_id.id,
                  name: getTranslation(junctionTableResult.tags_id, { key: 'languages_code', code: 'en-US' }).name,
                  image: PlaceholderImage,
                }
              });
            },
            mainTableResponseHandler: (mainTableResults) => {
              return mainTableResults.map(mainTableResult => {
                return {
                  id: mainTableResult.id,
                  name: getTranslation(mainTableResult, { key: 'languages_code', code: 'en-US' }).name,
                  image: PlaceholderImage,
                }
              });
            },
          },
        ]}
      >
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      fields.map(field => (
                        <LabelAndDisplay
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={4}>
              <Box sx={{ flexGrow: 1, my: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {
                      sidebarFields.map(field => (
                        <LabelAndDisplay
                          direction='column'
                          key={field.key}
                          label={field.key}
                          value={field.value}
                        >
                          {field.children}
                        </LabelAndDisplay>
                      ))
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ViewPage>
    </>
  )
}

export default CuisineView