import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
    Box,
    Button,
    IconButton,
    Input,
    InputAdornment,
    Stack,
    Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import CloseIcon from '@mui/icons-material/Close';

import './index.scss';

const SearchNav = (props) => {
    const { searchString: searchStringProp, triggerSearch, handleClearSearch, loading, filterParams, compact=false } = props;

    const { t } = useTranslation();

    const retailer = useSelector(state => state.retailer);

    const [searchString, setSearchString] = useState(searchStringProp);

    const clearSearch = () => {
        setSearchString('');
        handleClearSearch();
    }

    const handleClick = () => {
        if (searchString) {
            triggerSearch(searchString, filterParams)
        }
    }

    useEffect(() => {
        if (searchString) {
            triggerSearch(searchString);
        }
    }, []);

    const compactSearchInput = (
        <Box className='search-nav'>
            <Stack
                direction={'row'}
                sx={{
                    my: compact ? 0 : 3
                }}
            >
                <Input
                    className="font-primary search_input"
                    sx={{
                        color: '#262626 !important',
                        height: '3rem',
                        p: 2,
                        width: '100%'
                    }}
                    value={searchString}
                    onChange={(event) => { setSearchString(event.target.value) }}
                    disabled={loading}
                    placeholder={t('search').toLocaleUpperCase()}
                    variant="filled"
                    endAdornment={
                        searchString &&
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search field"
                                onClick={clearSearch}
                                onMouseDown={clearSearch}
                                disabled={loading}
                            >
                                {<CloseIcon fontSize="small" />}
                            </IconButton>
                        </InputAdornment>
                    }
                />

                <Button
                    sx={{
                        borderRadius: 0,
                        backgroundColor: retailer.button_background_color,
                        fontFamily: retailer.button_font,
                        height: '3rem',
                        textTransform: 'uppercase',
                        ':hover': {
                            bgcolor: retailer.button_background_color,
                        },
                    }}
                    disabled={loading}
                    variant="contained"
                    onClick={handleClick}
                >
                    <Search />
                </Button>
            </Stack>
        </Box>
    )

    if (compact) {
        return compactSearchInput
    }

    return (
        <>
            <Box className='search-nav'>
                <Stack
                    spacing={1}
                    sx={{
                        my: compact ? 0 : 3
                    }}
                >
                    {
                        !!!compact &&
                        <Typography
                            variant='h3'
                            className='search-header'
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            {t('findARecipe')}
                        </Typography>
                    }

                    <Input
                        className="font-primary search_input"
                        sx={{
                            color: '#262626 !important',
                            height: '42px',
                            p: 2,
                            width: '100%'
                        }}
                        value={searchString}
                        onChange={(event) => { setSearchString(event.target.value) }}
                        disabled={loading}
                        placeholder={t('search').toLocaleUpperCase()}
                        variant="filled"
                        endAdornment={
                            searchString &&
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear search field"
                                    onClick={clearSearch}
                                    onMouseDown={clearSearch}
                                    disabled={loading}
                                >
                                    {<CloseIcon fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    {
                        !!!compact &&
                        <Button
                            sx={{
                                borderRadius: 0,
                                backgroundColor: retailer.button_background_color,
                                fontFamily: retailer.button_font,
                                height: '48px',
                                textTransform: 'uppercase',
                                ':hover': {
                                    bgcolor: retailer.button_background_color,
                                },
                            }}
                            disabled={loading}
                            variant="contained"
                            onClick={handleClick}
                        >
                            {t('search_filterYourResults')}
                        </Button>
                    }
                </Stack>
            </Box>
        </>
    )
};

SearchNav.defaultProps = {
    cooking_methods: [] ,
    nutrition: null,
    searchString: '',
    searchText: '',
    handleClearSearch: () => {},
    triggerSearch: () => {},
}

export default SearchNav;