import * as React from "react";
import { Box, Divider } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, TextField, Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectProductInput = ({
  products,
  setSelectedProducts,
  selectedProducts,
}) => {
  return (
    <Autocomplete
      multiple
      id="checkboxes-product"
      options={products}
      disableCloseOnSelect
      getOptionDisabled={(option) => {
        if (option?.id === 0 || option?.id === 1) return false; // ALL & NONE
        return selectedProducts?.find(({ id }) => id === 0) ||
          selectedProducts?.find(({ id }) => id === 1)
          ? true
          : false;
      }}
      getOptionLabel={(option) => option?.translations[0]?.name}
      defaultValue={selectedProducts}
      onChange={(_, newValue) => {
        const allChecked = newValue?.filter(({ id }) => id === 0);
        const noneChecked = newValue?.filter(({ id }) => id === 1);
        setSelectedProducts(
          noneChecked?.length > 0
            ? noneChecked
            : allChecked?.length > 0
            ? allChecked
            : newValue
        );
      }}
      renderOption={(props, option, { selected }) => (
        <>
          <Box
            {...props}
            value={option?.id}
            sx={{
              fontWeight: option?.id === 0 || option?.id === 1 ? 700 : "",
            }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option?.translations[0]?.name}
          </Box>
          {option?.id === 1 && <Divider />}
        </>
      )}
      style={{ width: 500 }}
      renderInput={(params) => <TextField {...params} label="Select prodcut" />}
    />
  );
};

export default SelectProductInput;
