import { useEffect, useState } from 'react';

import markerSDK from '@marker.io/browser';

import { Link, Outlet } from "react-router-dom";

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { useSelector } from 'react-redux';
import Notification from './components/Notification';

const drawerWidth = 336;

function App(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const user = useSelector(state => state.user);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const initialiseMarkerSDK = async () => {
      const widget = await markerSDK.loadWidget({
        project: '6626a050c91ffe6e0124a5bf',
      });
    };

    initialiseMarkerSDK();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Box
          sx={{}}
        >
          <Notification />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default App;
