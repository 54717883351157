import { Fragment, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import useSWR from 'swr';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import API from '../../api';

import { useRefreshToken } from '../../common/hooks/useRefreshToken';
import Loader from '../../components/Loader';
import notify from '../../components/Notification/helper';
import ViewPage from '../../layouts/ViewPage';
import api from '../../api';
import { videoToolkitFetcher } from '../../common/utils/fetcher';
import Wizard from '../../components/shared/Wizard';

function SmartVideosProjectCreate() {
  useRefreshToken();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [videoTemplates, setVideoTemplates] = useState([]);
  const [name, setName] = useState('');
  const [baseVideo, setBaseVideo] = useState('');

  const params = useParams();
  const id = params.id;

  let videoTemplatesOnProject = [];
  let projectName = '';
  let baseVideoId = '';

  const { data, isLoading } = useSWR({
    url: id ? `/project/${id}` : null,
    fields: '*,video_template.*',
  }, videoToolkitFetcher);

  if (data) {
    projectName = data.name;
    baseVideoId = data.base_video;

    const videoTemplates = [
      ...(data?.video_template ? [data.video_template] : [])
    ];

    videoTemplatesOnProject = videoTemplates.map(item => ({
      id: item.id,
      url: `video_templates/${item.id}`,
      responseHandler: response => ({
        url: `video_templates/${response.id}`,
        title: response.name,
      }),
    })) || [];
  }

  const handleCloseVideoTemplatesDialog = (selectedRowIds = []) => {
    const items = selectedRowIds.map(id => ({
      id,
      url: `video_templates/${id}`,
      responseHandler: response => ({
        url: `video_templates/${response.id}`,
        title: response.name,
      }),
    }));

    setVideoTemplates(items);
  };

  const videoTemplatesPayload = {
    sort: '-created',
    meta: '*',
  };

  const videoTemplateColumns = [
    {
      key: 'id',
      label: 'ID',
      align: 'left',
    },
    {
      key: 'name',
      label: 'Name',
      align: 'left',
    },
  ];

  const formSections = [
    {
      title: 'Details',
      fields: [
        {
          label: 'name',
          description: 'A name to identify this batch',
          name: 'name',
          type: 'text',
          value: name || projectName,
          handleInputChange: (field, value) => setName(value),
        },
        {
          label: 'Base Video',
          description: 'The video file to be used as the base for the smart video',
          name: 'base_video',
          type: 'video',
          value: baseVideo || `${process.env.REACT_APP_SHOTSTACK_ADMIN_API_URL}/assets/${baseVideoId}`,
          handleInputChange: (field, value) => setBaseVideo(value),
        },
      ]
    },
    {
      title: 'Video Template',
      fields: [
        {
          label: 'Template',
          description: 'Template applied to this asset',
          name: 'video_template',
          type: 'm2m',
          value: videoTemplates,
          handleInputChange: handleCloseVideoTemplatesDialog,
          config: {
            items: videoTemplates?.length ? videoTemplates : videoTemplatesOnProject,
            title: 'Select Video Template',
            url: 'video_templates',
            payload: videoTemplatesPayload,
            columns: videoTemplateColumns,
            idField: 'id',
          }
        },
      ]
    },
  ]

  const create = async () => {
    // TODO validate the form
    // build the payload
    const selectedVideoTemplates = videoTemplates?.length ? videoTemplates : videoTemplatesOnProject;
    const selectedTemplate = selectedVideoTemplates.find(item => item.id);

    const payload = {
      name: name || projectName,
      video_template: selectedTemplate?.id,
      base_video: baseVideo || baseVideoId,
    };

    try {
      await api.postToVideoToolkit('project', payload);
      notify('success', 'Project created');
      navigate('/smart-videos/projects');
    } catch (error) {
      error?.map((err) => notify('error', err.message));
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ViewPage
      title={`Create Smart Videos Asset`}
      breadcrumbsLinks={[
        {
          url: '/smart-videos',
          content: 'Smart Videos'
        },
        {
          url: '/smart-videos/projects',
          content: 'Smart Video Assets'
        }
      ]}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Wizard
          pages={formSections}
          finalStageAction={(
            <Button
              disabled={loading}
              size="large"
              onClick={() => create()}
            >
              Save Asset
            </Button>
          )}
        />
      </Box>
    </ViewPage>
  )
}

export default SmartVideosProjectCreate;