import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import API from '../../api';

import AddIconImage from '../../shared/svg/plus.svg';
import CompletedIconImage from '../../shared/svg/check.svg';

import ImageCard from '../../components/ImageCard';

import './index.scss';
import ListPage from '../../layouts/ListPage';

function CBAMenu() {
  const retailer = useSelector(state => state.retailer);

  const profileCompletionChecks = [
    {
      title: 'Articles',
      description: ``,
      completed: true,
      uri: '/articles'
    },
    {
      title: 'Categories',
      description: ``,
      completed: true,
      // uri: '/categories'
    },
    {
      title: 'Authors',
      description: ``,
      completed: true,
      uri: '/authors'
    },
  ];

  return (
    <ListPage
      title={'Canada Beef Advantage'}
      description={'With the Gateway, shopping for cuts of beef has never been easier. Simply look for the QR code on packages of beef, scan the code, select the cut of beef and enjoy. You’ll find recipes, beef cut information, nutrition and more. Click here to learn more about this program.'}
      breadcrumbsLinks={[
        {
          url: '/',
          content: 'Content Library',
        },
      ]}
    >
      <Box className="check-icon" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {profileCompletionChecks.map((check) => (
            <Grid key={check.title} item xs={6} md={3} lg={2}>
                <ImageCard
                  url={check.uri}
                  item={{
                    title: check.title,
                    description: check.description,
                    url: check.completed ? CompletedIconImage : AddIconImage
                  }}
                  icon={true}
                />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ListPage>
  )
}

export default CBAMenu;