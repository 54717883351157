import {Fragment, useState} from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { Container, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

const DeleteDialog = ({ onDelete }) => {
    const retailer = useSelector(state => state.retailer);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        onDelete();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button
                variant="outlined"
                color="error"
                onClick={handleClickOpen}
            >
                <Delete />
            </Button>
            <Dialog
                maxWidth={'xs'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        boxShadow: '0 16px 15px 0 rgba(0, 0, 0, 0.18)',
                        py: 2,
                    }}
                    component={'div'}
                >
                    <Typography
                        className='title'
                        variant="h4"
                        sx={{
                            color: retailer.brand_color,
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            mr: 1
                        }}
                    >
                        Confirm
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ pt: 2, px: 0 }}>
                    <Container
                        sx={{
                            mt: 4
                        }}
                    >
                        <Typography
                            className='title'
                            sx={{
                            }}
                        >
                            Are you sure you want to delete?
                        </Typography>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color={'success'}
                        onClick={handleDelete}
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default DeleteDialog