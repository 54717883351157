import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Stack } from "@mui/material";

import API from "../../../../api";

const Charte = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);
  const [loading, setLoading] = useState(false);
  const [barcodeCounts, setBarcodeCounts] = useState(null);
  const [donutTwoLoading, setdonutTwoLoading] = useState(false);
  const [duplicatedbarcodeCounts, setDuplicatedbarcodeCounts] = useState(null);
  const retailerId = retailer?.id;
  const plugin = {
    id: "customCanvasBackgroundColor",
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = options.color || "#99ffff";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  useEffect(() => {
    const params = showFullData
      ? {}
      : {
          retailerId,
        };
    const getBarcodeCount = async () => {
      setLoading(true);
      const data = await API.getBarcodeCountBySource(params);
      setLoading(false);
      setBarcodeCounts(data);
    };
    const getDuplicatedBarcodeCount = async () => {
      setdonutTwoLoading(true);
      const data = await API.getDuplicatedBarcodeCountBySource(params);
      setDuplicatedbarcodeCounts(data);
      setdonutTwoLoading(false);
    };
    getBarcodeCount();
    getDuplicatedBarcodeCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFullData]);

  useEffect(() => {
    if (barcodeCounts) {
      // Donut One
      const data = {
        labels: barcodeCounts?.map(({ data_source }) => data_source),
        datasets: [
          {
            label: "Total Barcodes",
            data: barcodeCounts?.map(({ count }) => count),
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(0, 255, 0)",
              "rgb(0, 0, 6)",
              "rgb(255, 0, 0)",
            ],
            hoverOffset: 4,
          },
        ],
      };

      var donutOne = new Chart(document.getElementById("donut-one"), {
        type: "doughnut",
        data: data,
        plugins: [plugin],
        options: {
          plugins: {
            customCanvasBackgroundColor: {
              color: "#d7e8fa",
            },
            title: {
              display: true,
              text: "Percentage of barcodes by source",
            },
          },
        },
      });
    }
    // when component unmounts
    return () => {
      donutOne?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barcodeCounts]);

  useEffect(() => {
    if (duplicatedbarcodeCounts) {
      //   Donut two
      const data = {
        labels: duplicatedbarcodeCounts?.map(({ data_source }) => data_source),
        datasets: [
          {
            label: "Duplicated Barcodes",
            data: duplicatedbarcodeCounts?.map(({ count }) => count),
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(0, 255, 0)",
              "rgb(0, 0, 6)",
              "rgb(255, 0, 0)",
            ],
            hoverOffset: 4,
          },
        ],
      };
      var donutTwo = new Chart(document.getElementById("donut-two"), {
        type: "doughnut",
        data: data,
        plugins: [plugin],
        options: {
          plugins: {
            customCanvasBackgroundColor: {
              color: "#f3d7fa",
            },
            title: {
              display: true,
              text: "Percentages duplicating between sources",
            },
          },
        },
      });
    }
    // when component unmounts
    return () => {
      donutTwo?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicatedbarcodeCounts]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box
        sx={{
          width: "50%",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <canvas id="donut-one"></canvas>
        )}
      </Box>
      <Box
        sx={{
          width: "50%",
        }}
      >
        {donutTwoLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <canvas id="donut-two"></canvas>
        )}
      </Box>
    </Stack>
  );
};

export default Charte;
