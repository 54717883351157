import React, { useEffect, useState } from "react";
import useSWR from "swr";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IconButton, Menu, Tooltip } from "@mui/material";
import {
  Event,
  FilterAlt,
  LocalDining,
  Person,
  Source,
  Storefront,
} from "@mui/icons-material";

import API from "../../../../api";
import FilterItem from "./FilterItem";
import FilterButtons from "./FilterButtons";
import SelectUserInput from "./SelectUserInput";
import SelectOwnerInput from "./SelectOwnerInput";
import SelectSourceInput from "./SelectSourceInput";
import SelectProductInput from "./SelectProductInput";
import SelectVerifyDateInput from "./SelectVerifyDateInput";
import { barcodeSources } from "../../../../data/barcode";

const BarcodeFilter = ({
  handleFilterClear,
  setFilterObject,
  setPage,
  showFullData,
}) => {
  const user = useSelector((state) => state.user);
  const [retailers, setRetailers] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs().add(1, "day"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isAdminUser = API.isAdmin(user);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    setSelectedRetailers([]);
    setSelectedProducts([]);
    setSelectedSources([]);
    setSelectedUsers([]);
    setSelectedDate(dayjs().add(1, "day"));
    handleFilterClear();
    handleClose();
  };

  const { data: productsData } = useSWR({
    url: `beef_product`,
    fields: ["id", "translations.name"],
    limit: -1,
  });

  useEffect(() => {
    if (productsData) {
      setProducts([
        {
          id: 0,
          translations: [
            {
              name: "All",
            },
          ],
        },
        {
          id: 1,
          translations: [
            {
              name: "None",
            },
          ],
        },
        ...productsData,
      ]);
    }
  }, [productsData]);
  useEffect(() => {
    const getUsers = async () => {
      const users = await API.getUsersList();
      setUsers([
        {
          id: 0,
          first_name: "Any",
        },
        ...users,
      ]);
    };
    const getRetailers = async () => {
      const retailers = await API.getListRetailer(
        {},
        "id,translations.retailer_name"
      );
      setRetailers([
        {
          id: 0,
          translations: [
            {
              retailer_name: "Any",
            },
          ],
        },
        {
          id: 1,
          translations: [
            {
              retailer_name: "None",
            },
          ],
        },
        ...retailers,
      ]);
    };

    getUsers();
    isAdminUser && getRetailers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleFilterClick}
          color={
            selectedRetailers?.length > 0 ||
            selectedSources?.length > 0 ||
            selectedProducts?.length > 0 ||
            selectedUsers?.length > 0
              ? "primary"
              : "default"
          }
          aria-label="filter"
          size="large"
          aria-controls={open ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <FilterAlt fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <FilterItem title="Source/Origin" Icon={Source}>
          <SelectSourceInput
            sources={barcodeSources}
            setSelectedSources={setSelectedSources}
            selectedSources={selectedSources}
          />
        </FilterItem>

        {isAdminUser && (
          <FilterItem title="Owner/Retailer" Icon={Storefront}>
            <SelectOwnerInput
              retailers={retailers}
              setSelectedRetailers={setSelectedRetailers}
              selectedRetailers={selectedRetailers}
            />
          </FilterItem>
        )}

        <FilterItem title="LAST VERIFIED DATE" Icon={Event}>
          <SelectVerifyDateInput
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </FilterItem>

        <FilterItem title="BEEF PRODUCT" Icon={LocalDining}>
          <SelectProductInput
            products={products}
            setSelectedProducts={setSelectedProducts}
            selectedProducts={selectedProducts}
          />
        </FilterItem>

        <FilterItem title="Verified By User" Icon={Person}>
          <SelectUserInput
            users={users}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
          />
        </FilterItem>

        <FilterButtons
          handleClear={handleClear}
          setPage={setPage}
          setFilterObject={setFilterObject}
          showFullData={showFullData}
          selectedRetailers={selectedRetailers}
          selectedProducts={selectedProducts}
          selectedSources={selectedSources}
          selectedDate={selectedDate}
          selectedUsers={selectedUsers}
          handleClose={handleClose}
        />
      </Menu>
    </>
  );
};

export default BarcodeFilter;
