
import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";
const monthLabelsFormat = "D";
const yearLabelsFormat = "MMM";
const groupByMonth = [`year(date_created)`, "month(date_created)"];
const groupByDay = [
  `year(date_created)`,
  "month(date_created)",
  "day(date_created)",
];

const periods = [
  {
    label: "This Month",
    startDate: dayjs().startOf("month").format(dateFormat),
    endDate: "$NOW",
    groupBy: groupByDay,
    chartLabels: Array.from({ length: dayjs().daysInMonth() }, (_, i) =>
      dayjs().startOf("month").add(i, "day").format(monthLabelsFormat)
    ),
    chartLabelsDateComponents: Array.from(
      { length: dayjs().daysInMonth() },
      (_, i) => {
        const chartLabelDate = dayjs().startOf("month").add(i, "day");

        return {
          date_created_year: chartLabelDate.format("YYYY"),
          date_created_month: chartLabelDate.format("M"),
          date_created_day: chartLabelDate.format("D"),
        };
      }
    ),
  },
  {
    label: "Last Month",
    startDate: dayjs().subtract(1, "month").startOf("month").format(dateFormat),
    endDate: dayjs().subtract(1, "month").endOf("month").format(dateFormat),
    groupBy: groupByDay,
    chartLabels: Array.from({ length: dayjs().daysInMonth() }, (_, i) =>
      dayjs().startOf("month").add(i, "day").format(monthLabelsFormat)
    ),
    chartLabelsDateComponents: Array.from(
      { length: dayjs().daysInMonth() },
      (_, i) => {
        const chartLabelDate = dayjs()
          .subtract(1, "month")
          .startOf("month")
          .add(i, "day");

        return {
          date_created_year: chartLabelDate.format("YYYY"),
          date_created_month: chartLabelDate.format("M"),
          date_created_day: chartLabelDate.format("D"),
        };
      }
    ),
  },
  {
    label: "This Year",
    startDate: dayjs().startOf("year").format(dateFormat),
    endDate: dayjs().endOf("year").format(dateFormat),
    groupBy: groupByMonth,
    chartLabels: Array.from({ length: 12 }, (_, i) =>
      dayjs().startOf("year").add(i, "month").format(yearLabelsFormat)
    ),
    chartLabelsDateComponents: Array.from({ length: 12 }, (_, i) => {
      const chartLabelDate = dayjs().startOf("year").add(i, "month");

      return {
        date_created_year: chartLabelDate.format("YYYY"),
        date_created_month: chartLabelDate.format("M"),
      };
    }),
  },
];

export default periods;
