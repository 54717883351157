import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GridOn, TableChart } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Filter from "./Filter";
import BarcodeView from "../BarcodeView";

const BarcodeProductList = ({ showFullData }) => {
  const retailer = useSelector((state) => state.retailer);
  const retailerId = retailer?.id;
  const [page, setPage] = useState(0);
  const [view, setView] = useState("table");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filterObject, setFilterObject] = useState(
    showFullData
      ? {}
      : {
          owner: {
            id: {
              _eq: retailerId,
            },
          },
        }
  );

  const filterOnClick = () => {
    setPage(0);
    selectedProducts?.length > 0 &&
    !selectedProducts?.find(({ id }) => id === 0) // 0 = ALL
      ? selectedProducts?.find(({ id }) => id === 1) // 1 = NONE
        ? setFilterObject((filterObject) => ({
            ...filterObject,
            beef_product_id: {
              _null: true,
            },
          }))
        : setFilterObject((filterObject) => ({
            ...filterObject,
            beef_product_id: {
              id: {
                _in: selectedProducts?.map(({ id }) => id),
              },
            },
          }))
      : setFilterObject(
          showFullData
            ? {}
            : {
                owner: {
                  id: {
                    _eq: retailerId,
                  },
                },
              }
        );
  };

  const handleFilterClear = () => {
    setSelectedProducts([]);
    setPage(0);
    setFilterObject(
      showFullData
        ? {}
        : {
            owner: {
              id: {
                _eq: retailerId,
              },
            },
          }
    );
  };

  useEffect(() => {
    if (!showFullData) {
      setFilterObject({
        ...filterObject,
        owner: {
          id: {
            _eq: retailerId,
          },
        },
      });
    } else {
      setFilterObject((filterObject) => {
        delete filterObject?.owner;
        return {
          ...filterObject,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFullData]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
        padding: "16px",
        backgroundColor: "#efeeee",
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#090909",
              fontSize: "0.9rem",
              textTransform: "capitalize",
            }}
          >
            Barcode/Product List
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ my: 1 }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              color: "#090909",
              fontSize: "0.8rem",
            }}
          >
            Barcodes by beef product
          </Typography>

          <Box>
            <Filter
              filterOnClick={filterOnClick}
              handleFilterClear={handleFilterClear}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />

            <Tooltip title={view === "table" ? "Card View" : "Table View"}>
              <IconButton
                onClick={() => {
                  setView(view === "table" ? "card" : "table");
                }}
                aria-label="view"
                size="large"
              >
                {view === "table" ? (
                  <GridOn fontSize="inherit" />
                ) : (
                  <TableChart fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Box>

      <BarcodeView
        view={view}
        setPage={setPage}
        page={page}
        filterObject={filterObject}
      />
    </Box>
  );
};

export default BarcodeProductList;
