import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField } from '@mui/material';
import RichTextEditor from '../RichTextEditor';
import { useState } from 'react';

function TranslationInputField(props) {
    const { translation, label, field, value, description, name, handleInputChange, type, config } = props;
    const [translationValue, setTranslationValue] = useState(value);

    // TODO use lazy loading
    // TODO move languages to state
    const languages = [{ "code": "en-US" }, { "code": "fr-FR" }];

    const onChange = (field, value) => {
        setTranslationValue(value);

        handleInputChange(field, value);
    }

    return (
        <Stack>
            <Typography className="form-label" variant='label'>{label}</Typography>

            <Input
                inputProps={{
                    className: 'form-input'
                }}
                sx={{
                    color: '#262626 !important',
                    py: 0,
                    width: '100%'
                }}
                type="text"
                value={translationValue}
                onChange={(event) => { onChange(field, event.target.value) }}
                placeholder=""
                variant="standard"
            />
            <Typography
                className="form-input-description"
                variant='p'
            >
                {description}
            </Typography>

            {/* <Accordion
                defaultExpanded={false}
            >
                <AccordionSummary
                    sx={{
                        my: 0, 
                        '& .MuiAccordionSummary-content': { 
                            my: 0
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                            my: 0
                        }
                    }}
                    className='form-input'
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    &nbsp;
                </AccordionSummary>
                <AccordionDetails>
                    {languages.map(language => (
                        <Stack key={language.code}>
                            <Typography
                                className="form-label"
                                htmlFor={`input-my-brand-${name}-${language.code}`}
                                variant='label'
                            >
                                {language.code}
                            </Typography>

                            {
                                type !== 'textarea' && 
                                <TextField
                                    id={`input-my-brand-${name}-${language.code}`}
                                    multiline={type === 'textarea'}
                                    inputProps={{
                                        className: 'form-input'
                                    }}
                                    sx={{
                                        color: '#262626 !important',
                                        py: 0,
                                        width: '100%'
                                    }}
                                    value={config?.values[language.code]?.value}
                                    onChange={
                                        (event) => {
                                            handleInputChange(event.target.value,
                                                name,
                                                language.code,
                                                config?.values[language.code]?.id
                                            )
                                        }
                                    }
                                    placeholder=""
                                    variant="standard"
                                />
                            }
                            
                            {
                                type === 'textarea' && 
                                <RichTextEditor
                                    value={config?.values[language.code]?.value}
                                    onChange={
                                        (value) => {
                                            handleInputChange(value,
                                                name,
                                                language.code,
                                                config?.values[language.code]?.id
                                            )
                                        }
                                    }
                                />
                                
                            }
                        </Stack>
                    ))}
                </AccordionDetails>
            </Accordion> */}
        </Stack>
    )
}

export default TranslationInputField