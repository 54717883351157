import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    Box,
    Button,
    IconButton,
    Input,
    InputAdornment,
    Stack,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { updateFilters } from '../../actions/Filters'

import './index.scss';

const SearchNav = (props) => {
    const retailer = useSelector(state => state.retailer);
    const filters = useSelector(state => state.filters)
    const dispatch = useDispatch();

    const [searchString, setSearchString] = useState(filters.searchString);
    const [params, setParams] = useState(props.filterParams);

    const setFilters = (filterParams) => {
        setParams(filterParams);
    };

    return (
        <Box className='search-nav' sx={{ my: 5 }}>
            <Stack spacing={1} sx={{ mt: 5, mb: 2 }}>
                <Input
                    className="font-primary search_input"
                    sx={{ 
                        color: '#262626 !important',
                        height: '42px',
                        p: 2,
                        width: '100%'
                    }}
                    value={filters.searchString}
                    onChange={(event) => { dispatch(updateFilters({ searchString: event.target.value })) }}
                    disabled={props.loading}
                    placeholder={'search'.toLocaleUpperCase()}
                    variant="filled"
                    endAdornment={
                        searchString && 
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search field"
                                onClick={(event) => { dispatch(updateFilters({ searchString: '' })) }}
                                onMouseDown={(event) => { dispatch(updateFilters({ searchString: '' })) }}
                            >
                                {<CloseIcon fontSize="small" />}
                            </IconButton>
                        </InputAdornment>
                    }
                />

                <Button
                    sx={{ 
                        borderRadius: 0,
                        backgroundColor: retailer.button_background_color,
                        fontFamily: retailer.button_font,
                        height: '48px',
                        textTransform: 'uppercase',
                        ':hover': {
                            bgcolor: retailer.button_background_color,
                        },
                    }}
                    disabled={props.loading}
                    variant="contained"
                    onClick={() => { props.triggerSearch(searchString, params)}}
                >Perform Search</Button>
            </Stack>
        </Box>
    )
};

SearchNav.defaultProps = {
    cooking_methods: [] ,
    nutrition: null,
    searchString: '',
    searchText: '',
    triggerSearch: (searchString = '', filterParams = {}) => { console.log('searchString: ', searchString) }
}

export default SearchNav;