import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Upload from "@mui/icons-material/Upload";
import Stack from "@mui/material/Stack";

import API from "../../api";
import { Typography } from "@mui/material";
import constants from "../../common/utils/constants";

import { CloseOutlined } from "@mui/icons-material";
import { useState } from "react";

import styles from "./index.module.scss";

function InputUpload({
  field,
  label,
  description,
  value,
  handleInputChange,
  removeImageOnDelete,
  config
}) {
  const [imageUrl, setImageUrl] = useState(
    value
      ? value?.includes('http')
        ? value
        : `${process.env.REACT_APP_API_URL}/assets/${value}?key=full-image-thumbnail`
      : null
  );

  const uploadFile = async (file) => {
    const formData = new FormData();

    formData.append("file", file, file.name);

    let fileUploadResponse;

    if (config.imageApiUrl) {
      fileUploadResponse = await API.uploadToVideoToolkit('files', formData);
    } else {
      fileUploadResponse = await API.uploadFile(formData);
    }

    setImageUrl(
      `${config.imageApiUrl || process.env.REACT_APP_API_URL}/assets/${fileUploadResponse.id}`
    );
    handleInputChange(field, fileUploadResponse.id);
  };

  return (
    <Stack>
      <Typography className="form-label" variant="label">
        {label}
      </Typography>

      {imageUrl && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            pb: "25%",
            background: "#000",
          }}
        >
          <img
            className={styles.card_image}
            src={imageUrl}
            srcSet={imageUrl}
            alt={"alternative"}
            loading="lazy"
          />

          <IconButton
            sx={{
              color: "#fff",
            }}
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setImageUrl(null);
              removeImageOnDelete && handleInputChange(field, null);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>
      )}

      {!imageUrl && (
        <Stack
          direction={"row"}
          alignItems={"stretch"}
          justifyContent={"stretch"}
        >
          <Input
            id={`input-my-brand-${field}`}
            accept="image/*"
            onChange={(event) => {
              uploadFile(event.target.files[0]);
            }}
            type="file"
            inputProps={{
              className: "form-input",
            }}
            sx={{
              color: "#262626 !important",
              py: 0,
              width: "100%",
              overflow: "hidden",
            }}
            placeholder=""
            variant="standard"
          />
          <Box>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "100%", background: "#000" }}
            >
              <label className="form-icon" htmlFor={`input-my-brand-${field}`}>
                <IconButton
                  sx={{ color: "#fff" }}
                  aria-label="upload picture"
                  component="span"
                >
                  <Upload />
                </IconButton>
              </label>
            </Stack>
          </Box>
        </Stack>
      )}
      <Typography className="form-input-description" variant="p">
        {description}
      </Typography>
    </Stack>
  );
}

export default InputUpload;
