import * as React from "react";
import { Box, Divider } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, TextField, Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectUserInput = ({ users, setSelectedUsers, selectedUsers }) => {
  return (
    <Autocomplete
      multiple
      id="checkboxes-user"
      options={users}
      disableCloseOnSelect
      getOptionLabel={(option) =>
        `${option?.first_name ?? ""} ${option?.last_name ?? ""}`
      }
      getOptionDisabled={(option) => {
        if (option?.id === 0) return false; // ANY
        return selectedUsers?.find(({ id }) => id === 0) ? true : false;
      }}
      defaultValue={selectedUsers}
      onChange={(_, newValue) => {
        const allChecked = newValue?.filter(({ id }) => id === 0);
        setSelectedUsers(allChecked?.length > 0 ? allChecked : newValue);
      }}
      renderOption={(props, option, { selected }) => (
        <>
          <Box
            {...props}
            value={option?.id}
            sx={{
              fontWeight: option?.id === 0 ? 700 : "",
            }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option?.first_name} {option?.last_name}
          </Box>
          {option?.id === 0 && <Divider />}
        </>
      )}
      style={{ width: 500 }}
      renderInput={(params) => <TextField {...params} label="Select users" />}
    />
  );
};

export default SelectUserInput;
